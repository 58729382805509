// @flow
import React, {useCallback, useEffect, useState} from "react";
import {useTablePaginator} from "../Pay/useTablePaginator";
import Modal from "react-modal";
import {Link} from "react-router-dom";
import '../Pay/pay_table.css';
import InView from "../../components/InView";
import {
    deactivateAllFilters,
    deleteCustomFilter, getAllEmployees, getAllTenants,
    getCustomFilters,
    postCustomFilters,
    putCustomFilters
} from "../../services/API/customFilters.service";
import {CustomFilterForm} from "../../components/customFilters/CustomFilterForm";
import useApi from "../../hooks/useApi";
import toast, {Toaster} from "react-hot-toast";
import {BsPencilSquare, BsTrashFill} from "react-icons/bs";
import useRefreshOnTabFocus from "../../hooks/useRefreshOnTabFocus";

const initState = {
    id: 0,
    title: '',
    mandnr: [],
    amount_from: '',
    amount_to: '',
    blocked_by_me: false,
    transactions_by_employee: [],
    is_active: false,
}

function CustomFiltersPage() {
    const [, hasError, filters, hasMore, total, refresh , nextPage] = useTablePaginator(getCustomFilters);
    const [isOpen, setIsOpen] = useState(false);
    const permissions = JSON.parse(localStorage.getItem('user-permissions'));
    const {request: addCustomFilterRequest, error: addCustomFilterError, data: addCustomFilterData, canceled: canceledAddCustomFilter} = useApi(postCustomFilters);
    const {request: updateCustomFilterRequest, error: updateCustomFilterError, data: updateCustomFilterData, canceled: canceledUpdateCustomFilter} = useApi(putCustomFilters);
    const {data: deleteCustomFilterData, request: deleteCustomFilterRequest, error: deleteCustomFilterError} = useApi(deleteCustomFilter);
    const {data: deactivateFilterData, request: deactivateFilterRequest, error: deactivateFilterError} = useApi(deactivateAllFilters);
    const {data: getEmployeesData, request: getEmployeesRequest, error: getEmployeesError} = useApi(getAllEmployees);
    const {data: getTenantsData, request: getTenantsRequest, error: getTenantsError} = useApi(getAllTenants);
    const [filtersState, setFiltersState] = useState(initState);
    const [tenants, setTenants] = useState();
    const [employees, setEmployees] = useState();

    useRefreshOnTabFocus(refresh);

    useEffect(() => {
        getTenantsData && setTenants(getTenantsData.data);
        getEmployeesData && setEmployees(getEmployeesData.data);
    }, [getTenantsData, getEmployeesData]);

    useEffect(() => {
        permissions && ((permissions["web-for-damage-bill-management"] && permissions["web-for-damage-bill-management"].includes("getAllEmployees")) || permissions.isAdmin) && getEmployeesRequest();
        permissions && ((permissions["web-for-car-dealer-management"] && permissions["web-for-car-dealer-management"].includes("getAllTenantsCurrencies")) || permissions.isAdmin) && getTenantsRequest();
    }, []);

    useEffect(() => {
        if (canceledAddCustomFilter || canceledUpdateCustomFilter) toast.error('Request to service damagebills was canceled, time exceeded', 'damagebills');
    }, [canceledAddCustomFilter, canceledUpdateCustomFilter]);

    const onInView = useCallback(() => {
        if (hasMore && !hasError) nextPage()
    }, [hasMore, hasError, nextPage]);

    useEffect(() => {
        if (!updateCustomFilterData) return;
        if (updateCustomFilterData && (updateCustomFilterData.code === 200 || updateCustomFilterData.code === 204)) {
            toast.success(updateCustomFilterData.message || 'Data is saved successfully');
            refresh();
            toggleModal();
        } else {
            updateCustomFilterError && toast.error(updateCustomFilterError);
            toast.dismiss(updateCustomFilterError);
        }
    }, [updateCustomFilterError, updateCustomFilterData]);

    useEffect(() => {
        if (!addCustomFilterData) return;
        if (addCustomFilterData && (addCustomFilterData.code === 200 || addCustomFilterData.code === 204)) {
            toast.success(addCustomFilterData.message || 'Data is saved successfully');
            refresh();
            toggleModal();
        } else {
            addCustomFilterError && toast.error(addCustomFilterError);
            toast.dismiss(addCustomFilterError);
        }
    }, [addCustomFilterError, addCustomFilterData]);

    useEffect(() => {
        if (!deleteCustomFilterData) return;
        if (deleteCustomFilterData && (deleteCustomFilterData.code === 200 || deleteCustomFilterData.code === 204)) {
            toast.success(deleteCustomFilterData.message);
            refresh();
        } else {
            deleteCustomFilterError && toast.error(deleteCustomFilterError);
            toast.dismiss(deleteCustomFilterError);
        }
    }, [deleteCustomFilterError, deleteCustomFilterData]);

    useEffect(() => {
        if (!deactivateFilterData) return;
        if (deactivateFilterData && (deactivateFilterData.code === 200 || deactivateFilterData.code === 204)) {
            toast.success(deactivateFilterData.message || 'Data is saved successfully');
            refresh();
        } else {
            deactivateFilterError && toast.error(deactivateFilterError);
            toast.dismiss(deactivateFilterError);
        }
    }, [deactivateFilterError, deactivateFilterData]);

    useEffect(() => {
        getTenantsError && toast.error(getTenantsError);
        toast.dismiss(getTenantsError);
    }, [getTenantsError]);

    useEffect(() => {
        getEmployeesError && toast.error(getEmployeesError);
        toast.dismiss(getEmployeesError);
    }, [getEmployeesError]);

    const toggleModal = useCallback(() => {
        if (!isOpen) {
            setFiltersState({...initState});
        }
        setIsOpen(isOpen => !isOpen);
    }, [isOpen]);

    const loadForEdit = useCallback(({...rest}) => {
        setFiltersState({...rest, transactions_by_employee: rest.transactions_by_employee ? rest.transactions_by_employee.split(',') : [], mandnr: rest.mandnr ? rest.mandnr.split(',') : []});
        setIsOpen(true);
    }, []);

    const onCustomFilterFormSubmit = useCallback(async (filter) => {
        if (filter.id > 0) {
            await updateCustomFilterRequest({filter, id: filter.id});
        } else {
            await addCustomFilterRequest({filter});
        }
    }, [updateCustomFilterRequest, addCustomFilterRequest]);

    const deleteFilter = useCallback((item) => {
        deleteCustomFilterRequest({id: item.id});
    }, [deleteCustomFilterRequest]);

    const deactivateFilters = useCallback(() => {
        deactivateFilterRequest();
    }, [deactivateFilterRequest]);

    return (<>
        <div className={"payment-page"}>
            <Toaster />
            <h1>Custom filters</h1>
            <div>
                <Link to="/bills">Back to Home</Link>
            </div>
            <div style={{display: 'flex', justifyContent: 'flex-end', gap: '20px', width: '100%', paddingInline: '1rem'}}>
                {permissions && ((permissions["web-for-damage-bill-management"] && permissions["web-for-damage-bill-management"].includes("insertNewCustomFilter")) || permissions.isAdmin) &&
                    <button className={"btn btn-primary"} onClick={toggleModal}>Add new custom filter</button>}
                {permissions && ((permissions["web-for-damage-bill-management"] && permissions["web-for-damage-bill-management"].includes("deActivateAllCustomFilter")) || permissions.isAdmin) &&
                    <button className={"btn btn-secondary"} onClick={deactivateFilters}>Deactivate all custom filters</button>}
            </div>
            {permissions && ((permissions["web-for-damage-bill-management"] && permissions["web-for-damage-bill-management"].includes("getAllCustomFilters")) || permissions.isAdmin) &&
                <div style={{
                width: 'calc(100% - 2rem)',
                display: 'flex',
                flexDirection: 'column',
                margin: '1rem',
                maxHeight: '80vh',
                border: '1px solid rgba(0,0,0,.12)',
                borderRadius: '11px',
                overflow: 'auto',
            }}>

                <table>
                    <thead>
                    <tr>
                        <th className={'pr-3 pl-2'}>#</th>
                        <th className={'pr-3'}>Title</th>
                        {permissions && ((permissions["web-for-car-dealer-management"] && permissions["web-for-car-dealer-management"].includes("getAllTenantsCurrencies")) || permissions.isAdmin) &&
                            <th className={'pr-3'}>Tenants</th>}
                        <th className={'pr-3'}>Amount from</th>
                        <th className={'pr-3'}>Amount to</th>
                        <th className={'pr-3'}>Is blocked by me</th>
                        <th className={'pr-3'}>Is active</th>
                        {permissions && ((permissions["web-for-damage-bill-management"] && permissions["web-for-damage-bill-management"].includes("getAllEmployees")) || permissions.isAdmin) &&
                            <th className={'pr-3'}>Employees</th>}
                        <th className={'text-right pr-3'}>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {filters.length === 0 ? <tr style={{padding: '1rem'}}>
                            <td colSpan={7} className={'pl-2'}>No results</td>
                        </tr> :
                        filters.map((filter, index) => {
                            return index !== Math.round(filters.length / 1.2) ? <tr key={filter.id}>
                                <td className={'pl-2'}>{index + 1}</td>
                                <td>{filter.title}</td>
                                {permissions && ((permissions["web-for-car-dealer-management"] && permissions["web-for-car-dealer-management"].includes("getAllTenantsCurrencies")) || permissions.isAdmin) &&
                                <td>{tenants && !!filter.mandnr ? tenants.filter(item =>
                                    filter.mandnr.split(',').includes(`${item.tenant_id}`)).map(item => item.tenant_name_description).join(', ') : ''}</td>}
                                <td>{filter.amount_from}</td>
                                <td>{filter.amount_to}</td>
                                <td>{filter.blocked_by_me ? 'Yes' : 'No'}</td>
                                <td>{filter.is_active ? 'Yes' : 'No'}</td>
                                {permissions && ((permissions["web-for-damage-bill-management"] && permissions["web-for-damage-bill-management"].includes("getAllEmployees")) || permissions.isAdmin) &&
                                <td>{employees && !!filter.transactions_by_employee ? employees.filter(item =>
                                    filter.transactions_by_employee.split(',').includes(`${item.user_id}`)).map(item => `${item.first_name} ${item.last_name}`).join(', ') : ''}</td>}
                                <td>
                                    {permissions && ((permissions["web-for-damage-bill-management"] && permissions["web-for-damage-bill-management"].includes("deleteCustomFilter")) || permissions.isAdmin) &&
                                        <button style={{border: "none", background: "unset", float: "right" }} data-itemid={filter.id}
                                                title={"Delete"}
                                                onClick={deleteFilter.bind(this, filter)}>
                                            <BsTrashFill
                                                style={{
                                                    color: "red",
                                                    background: "unset",
                                                    margin: "0 0 0 0px"
                                                }}
                                                className={"Icon-Decline"}/>
                                        </button>}
                                    {permissions && ((permissions["web-for-damage-bill-management"] && permissions["web-for-damage-bill-management"].includes("updateCustomFilter")) || permissions.isAdmin) &&
                                        <button style={{border: "none", background: "unset", float: "right"}} data-itemid={filter.id}
                                                title={"Edit"}
                                                onClick={loadForEdit.bind(this, filter)}>
                                            <BsPencilSquare
                                                style={{
                                                    color: "gray",
                                                    background: "unset",
                                                    margin: "0 0 0 0px"
                                                }}
                                                className={"Icon-Decline"}/>
                                        </button>}
                                </td>
                            </tr> : <InView as={'tr'} key={filter.id} onceOnIntersect={onInView}>
                                <td className={'pl-2'}>{index + 1}</td>
                                <td>{filter.title}</td>
                                {permissions && ((permissions["web-for-car-dealer-management"] && permissions["web-for-car-dealer-management"].includes("getAllTenantsCurrencies")) || permissions.isAdmin) &&
                                <td>{tenants && !!filter.mandnr ? tenants.filter(item =>
                                    filter.mandnr.split(',').includes(`${item.tenant_id}`)).map(item => item.tenant_name_description).join(', ') : ''}</td>}
                                <td>{filter.amount_from}</td>
                                <td>{filter.amount_to}</td>
                                <td>{filter.blocked_by_me ? 'Yes' : 'No'}</td>
                                <td>{filter.is_active ? 'Yes' : 'No'}</td>
                                {permissions && ((permissions["web-for-damage-bill-management"] && permissions["web-for-damage-bill-management"].includes("getAllEmployees")) || permissions.isAdmin) &&
                                <td>{getEmployeesData && filter.transactions_by_employee ? getEmployeesData.data.filter(item =>
                                    filter.transactions_by_employee.split(',').includes(`${item.user_id}`)).map(item => `${item.first_name} ${item.last_name}`).join(',') : ''}</td>}
                                <td>
                                    {permissions && ((permissions["web-for-damage-bill-management"] && permissions["web-for-damage-bill-management"].includes("deleteCustomFilter")) || permissions.isAdmin) &&
                                        <button style={{border: "none", background: "unset", float: "right" }} data-itemid={filter.id}
                                                title={"Delete"}
                                                onClick={deleteFilter.bind(this, filter)}>
                                            <BsTrashFill
                                                style={{
                                                    color: "red",
                                                    background: "unset",
                                                    margin: "0 0 0 0px"
                                                }}
                                                className={"Icon-Decline"}/>
                                        </button>}
                                    {permissions && ((permissions["web-for-damage-bill-management"] && permissions["web-for-damage-bill-management"].includes("updateCustomFilter")) || permissions.isAdmin) &&
                                        <button style={{border: "none", background: "unset", float: "right"}} data-itemid={filter.id}
                                            title={"Edit"}
                                            onClick={loadForEdit.bind(this, filter)}>
                                        <BsPencilSquare
                                            style={{
                                                color: "gray",
                                                background: "unset",
                                                margin: "0 0 0 0px"
                                            }}
                                            className={"Icon-Decline"}/>
                                        </button>}
                                </td>
                            </InView>
                        })}
                    </tbody>
                </table>
                <div className={'table-footer'}>
                    <div className={'table-footer-inner'}>Results 1 - {filters.length} of {total}</div>
                </div>
            </div>}
            <Modal isOpen={isOpen}
                   onRequestClose={toggleModal}
                   overlayClassName={"modal-overlay"}
                   className={"modal-wide_no_overflow"}
                   appElement={document.getElementsByTagName('body')}
                   contentLabel="Custom filters">
                <CustomFilterForm toggleModal={toggleModal} formSubmit={onCustomFilterFormSubmit} filterData={filtersState}
                employees={getEmployeesData ? getEmployeesData.data : null} tenants={getTenantsData ? getTenantsData.data : null}/>
            </Modal>
        </div>
    </>)
}

export default CustomFiltersPage;