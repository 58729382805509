//@flow
import {createAction, createSlice} from "@reduxjs/toolkit";
import type {Bill, CarDealer} from "../../components/BillContext";
import {BILL_PROCESSING_STATUSES} from "../../components/StatusContext";

export interface CarDealersSearchParams {
    full_name: string;
    hdnr: string;
    zip: string;
    default_email_address: string;
    filial: string;
}
export interface AdvancedBillSearchParams {
    company_name: string;
    postcode: string;
    partner_number: number;
    city: string;
    IBAN: string;
    chassis_number: number;
    registration_number: number;
    customer_name: string;
    contract_number: number;
    processing_status: number;
    email_address: string;
}

export interface SearchPaginationState {
    keyword: string;
    statuses: [number];
    pageNumber: number;
    billsPaginated: Bill[];
    hasError: boolean;
    hasMore: boolean;
    loading: false;
    billsIdsApprovedForOCR: number[];
    all_selected: boolean;
    sortedColumn: string;
    sortOrder: 'asc' | 'desc';
    bills_versions_uncheck: number[];
    select_all_button: boolean;
    totalItems: string | number;
    countFilteredBills: string | number;
    advanceSearchParams: AdvancedBillSearchParams;
    showSidebar: boolean;
    advanceSearchCarDealersParams: CarDealersSearchParams,
    hasErrorCarDealers: boolean;
    hasMoreCarDealers: boolean;
    loadingCarDealers: false;
    carDealers: CarDealer[];
    pageNumberCarDealers: number;
    totalItemsCarDealers: string | number;
    carDealersIdsForEmails: number[],
    all_selected_car_dealers: boolean,
    carDealers_uncheck: number[],
    select_all_button_carDealers: boolean,
    countFilteredCarDealers: string | number,
    errorUpdateEmail: boolean;
    modalOpen: boolean;
    infoMessage: string;
    hdnr: string | number;
    default_email_address: string;
    zip: string | number;
    full_name: string;
    email_changed: number;
    chosenFilter: string | number;
}

export const initialState: SearchPaginationState = {
    keyword: '',
    statuses: [],
    pageNumber: 1,
    billsPaginated: [],
    hasMore: false,
    hasError: false,
    loading: false,
    billsIdsApprovedForOCR: {},
    all_selected: false,
    sortedColumn: 'date_created',
    sortOrder: 'desc',
    bills_versions_uncheck: [],
    select_all_button: false,
    totalItems: '',
    countFilteredBills: 0,
    advanceSearchParams: null,
    showSidebar: true,
    advanceSearchCarDealersParams: null,
    pageNumberCarDealers: 1,
    hasMoreCarDealers: false,
    hasErrorCarDealers: false,
    loadingCarDealers: false,
    carDealers: [],
    totalItemsCarDealers: '',
    carDealersIdsForEmails: {},
    all_selected_car_dealers: false,
    carDealers_uncheck: [],
    select_all_button_carDealers: false,
    countFilteredCarDealers: 0,
    errors: {
        formValidation: undefined,
    },
    errorUpdateEmail: false,
    modalOpen: false,
    infoMessage: '',
    hdnr: '',
    default_email_address: '',
    zip: '',
    full_name: '',
    email_changed: 0,
    chosenFilter: ''
}

/*
    For fetching paginated bills
 */
export const fetchBills = createAction('searchPagination/fetchBills');
export const fetchBillsFullFilled = createAction('searchPagination/fetchBills/fulfilled');
export const fetchBillsFailed = createAction('searchPagination/fetchBills/failed');

export const advanceFetchBills = createAction('searchPagination/advanceFetchBills');
export const advanceFetchBillsFullFilled = createAction('searchPagination/advanceFetchBills/fulfilled');
export const advanceFetchBillsFailed = createAction('searchPagination/advanceFetchBills/failed');

/*
    For requesting next page
 */
export const nextPage = createAction('searchPagination/pageUpdated')
export const nextPageCarDealers = createAction('searchPagination/pageUpdatedCarDealers')

/*
    For requesting bills with updated query
 */
export const updateFilters = createAction('searchPagination/filtersUpdated');

export const updateFiltersCarDealers = createAction('searchPagination/filtersUpdatedCarDealers');

export const toggleSortOrder = createAction('searchPagination/toggleSortOrder');
export const fetchCarDealers = createAction(`searchPagination/fetchCarDealers`);
export const fetchCarDealersFullFilled = createAction(`searchPagination/fetchCarDealers/fulfilled`);
export const fetchCarDealersFailed = createAction(`searchPagination/fetchCarDealers/failed`);
export const advanceFetchCarDealers = createAction('searchPagination/advanceFetchCarDealers');
export const advanceFetchCarDealersFullFilled = createAction('searchPagination/advanceFetchCarDealers/fulfilled');
export const advanceFetchCarDealersFailed = createAction('searchPagination/advanceFetchCarDealers/failed');

export const updateEmailsCarDealers = createAction(`searchPagination/updateEmailsCarDealers`);
export const updateEmailsCarDealersFullFilled = createAction(`searchPagination/updateEmailsCarDealers/fulfilled`);
export const updateEmailsCarDealersFailed = createAction(`searchPagination/updateEmailsCarDealers/failed`);

const searchPaginationSlice = createSlice({
    name: 'searchPagination',
    initialState,
    reducers: {
        resetBillsApprovedForOCR(state: SearchPaginationState) {
            state.billsIdsApprovedForOCR = {}
            state.bills_versions_uncheck = []
        },
        resetCarDealersForEmails(state: SearchPaginationState) {
            state.carDealersIdsForEmails = {}
            state.carDealers_uncheck = []
            state.infoMessage=''
        },
        markAsSentToOCS(state: SearchPaginationState, {payload}) {
            state.billsPaginated = state.billsPaginated.map<Bill>((bill: Bill) =>
                payload.includes(bill.id.toString()) ? {
                    ...bill,
                    processing_status: BILL_PROCESSING_STATUSES.DOCUMENT_SENT_TO_OCR
                } : bill)

            state.billsIdsApprovedForOCR = {}
            state.bills_versions_uncheck = []
            state.all_selected = false;

        },
        toggleBillForOCR(state: SearchPaginationState, {payload}) {
            state.billsIdsApprovedForOCR[payload] = !state.billsIdsApprovedForOCR[payload]
        },
        toggleCarDealerForEmails(state: SearchPaginationState, {payload}) {
            state.carDealersIdsForEmails[payload] = !state.carDealersIdsForEmails[payload]
            state.all_selected_car_dealers=false;
            const ids=Object.keys(state.carDealersIdsForEmails).map(Number);
            const values=Object.values(state.carDealersIdsForEmails).map(Boolean);
            (ids.length !==0 && values.includes(true)) ? state.infoMessage='Klicken Sie auf eine markierte Zeile, um ein Modal zu öffnen ' : state.infoMessage='';


        },
        toggleAllBillsForOCR(state: SearchPaginationState, {payload}) {
            state.billsIdsApprovedForOCR = payload;
            state.all_selected = !state.all_selected;
            state.bills_versions_uncheck = []
        },
        toggleAllCareDealersForEmails(state: SearchPaginationState, {payload}) {
            state.carDealersIdsForEmails = payload;
            state.all_selected_car_dealers = !state.all_selected_car_dealers;
            state.carDealers_uncheck = []
        },
        updateArrayUncheck(state: SearchPaginationState, {payload}) {
            state.bills_versions_uncheck = payload;
        },
        updateArrayUncheckCarDealers(state: SearchPaginationState, {payload}) {
            state.carDealers_uncheck = payload;
        },
        updateStatusOfPaginatedPill(state: SearchPaginationState, {payload}) {
            if (state.statuses.includes(payload.status)) {
                state.billsPaginated = state.billsPaginated.map((bill) => {
                    if (bill.id !== payload.id) return bill;
                    return {
                        ...bill,
                        processing_status: payload.status
                    }
                })
            } else if (state.statuses.length > 0) {
                state.billsPaginated = state.billsPaginated.filter((bill) => (bill.id !== payload.id))
            }

        },
        toggleSelectAllButton(state: SearchPaginationState, {payload}) {
            state.select_all_button = payload;
        },
        toggleSelectAllButtonCarDealers(state: SearchPaginationState, {payload}) {
            state.select_all_button_carDealers = payload;
            state.select_all_button_carDealers===true ? state.infoMessage='Klicken Sie auf eine markierte Zeile, um ein Modal zu öffnen' : state.infoMessage=''
        },
        saveCountedFilteredBills(state: SearchPaginationState, {payload}) {
            state.countFilteredBills = payload;
        },
        saveCountedFilteredCarDealers(state: SearchPaginationState, {payload}) {
            state.countFilteredCarDealers = payload;
        },
        toggleSidebar(state: SearchPaginationState){
            state.showSidebar=!state.showSidebar;
        },
        toggleOpenModal(state: SearchPaginationState) {
            const ids=Object.keys(state.carDealersIdsForEmails).map(Number);
            const values=Object.values(state.carDealersIdsForEmails).map(Boolean);
            (ids.length !==0 && values.includes(true)) ? state.modalOpen=true : state.modalOpen= false;
            },
        toggleCloseModal(state: SearchPaginationState, {payload}){
            state.modalOpen=payload;
        },
        markAsUpdatedEmails(state: SearchPaginationState, action) {
            const ids=Object.values(action.payload.carDealersIdsForEmails);
            state.carDealers = state.carDealers.map<CarDealer>((carDealer: CarDealer) => {
                   return ids.includes(carDealer.id) ?
                        {
                            ...carDealer,
                            default_email_address: action.payload.default_email_address
                        }
                        : carDealer
                }
            )

            state.carDealersIdsForEmails = {}
            state.carDealers_uncheck = []
            state.all_selected_car_dealers = false;

        },
        updateEmailsCarDealers(state: SearchPaginationState){
            state.email_changed+=1;
        },
        setChosenFilter(state: SearchPaginationState, {payload}){
            state.chosenFilter = payload;
        },
        setPageNumber(state: SearchPaginationState, {payload}){
            state.pageNumber = payload;
        }
    },

    extraReducers: {
        [fetchBills]: (state) => {
            state.hasMore = false;
        },
        [fetchBillsFullFilled]: (state, action) => {
            if (action.payload.page === 1) {
                state.billsPaginated = [
                    ...action.payload.billsPaginated
                ];
            } else {
                state.billsPaginated = [
                    ...state.billsPaginated,
                    ...action.payload.billsPaginated
                ];
            }
            state.hasMore = action.payload.hasMore;
            state.loading = false;
            state.totalItems = action.payload.totalItems;
        },
        [fetchBillsFailed]: (state) => {
            state.hasError = true;
            state.loading = false;
            state.billsPaginated = [];
        },
        [advanceFetchBills]: (state) => {
            state.hasMore = false;
            state.keyword = '';
            state.statuses = [];
        },
        [advanceFetchBillsFullFilled]: (state, action) => {
            state.billsPaginated = [
                ...action.payload.billsPaginated
            ];
            state.hasMore = action.payload.hasMore;
            state.loading = false;
            state.totalItems = action.payload.totalItems;
        },
        [advanceFetchBillsFailed]: (state) => {
            state.hasError = true;
            state.loading = false;
            state.billsPaginated = [];
        },
        [fetchCarDealers]: (state) => {
            state.hasMoreCarDealers = false;
        },
        [fetchCarDealersFullFilled]: (state, action) => {
            state.carDealers = [
                ...state.carDealers,
                ...action.payload.carDealers
            ];
            state.hasMoreCarDealers = action.payload.hasMoreCarDealers;
            state.loadingCarDealers = false;
            state.totalItemsCarDealers = action.payload.totalItemsCarDealers;

        },
        [fetchCarDealersFailed]: (state) => {
            state.hasErrorCarDealers = true;
            state.loadingCarDealers = false;
            state.carDealers = [];
        },
        [advanceFetchCarDealers]: (state) => {
            state.hasMoreCarDealers = false;
        },
        [advanceFetchCarDealersFullFilled]: (state, action) => {
            state.carDealers = [
                ...action.payload.carDealers
            ];
            state.hasMoreCarDealers = action.payload.hasMoreCarDealers;
            state.loadingCarDealers = false;
            state.totalItemsCarDealers = action.payload.totalItemsCarDealers;
        },
        [advanceFetchCarDealersFailed]: (state) => {
            state.hasErrorCarDealers = true;
            state.loadingCarDealers  = false;
            state.carDealers = [];
        },
        [updateFilters]: (state, {payload}) => {
            if (typeof payload.keyword !== "undefined") state.keyword = payload.keyword;
            if (typeof payload.statuses !== "undefined") state.statuses = payload.statuses;
            // if (typeof payload.sortOrder !== "undefined") state.sortOrder = payload.sortOrder;
            state.billsPaginated = []
            state.loading = true;
            state.pageNumber = 1;
        },
        [updateFiltersCarDealers]: (state, {payload}) => {
            if (typeof payload.keywordCarDealers !== "undefined") state.keywordCarDealers = payload.keywordCarDealers;
            if (typeof payload.hdnr !== "undefined") state.hdnr = payload.hdnr;
            if (typeof payload.default_email_address !== "undefined") state.default_email_address = payload.default_email_address;
            if (typeof payload.zip !== "undefined") state.zip = payload.zip;
            if (typeof payload.full_name !== "undefined") state.full_name = payload.full_name;
            if (typeof payload.filial !== "undefined") state.filial = payload.filial;

            state.carDealers = []
            state.loadingCarDealers = true;
            state.pageNumberCarDealers = 1;
        },
        [toggleSortOrder]: (state) => {
            state.billsPaginated = []
            state.loading = true;
            state.pageNumber = 1;
            state.sortOrder = state.sortOrder === 'asc' ? 'desc' : 'asc';
        },
        // [updateKeyword]: (state, action) => {
        //     state.billsPaginated = []
        //     state.loading = true;
        //     state.pageNumber = 1;
        //     state.keyword = action.payload
        // },
        // [updateStatuses]: (state, action) => {
        //     state.billsPaginated = []
        //     state.loading = true;
        //     state.pageNumber = 1;
        //     state.statuses = action.payload
        // },
        [nextPage]: (state, action) => {
            state.pageNumber += (action.payload || 1)
        },
        [nextPageCarDealers]: (state, action) => {
            state.pageNumberCarDealers += (action.payload || 1)
        },
        [updateEmailsCarDealersFullFilled]: (state) => {
           // state.carDealers=[];
           //  state.pageNumberCarDealers=1;
            state.carDealers_uncheck=[];
        },
        [updateEmailsCarDealersFailed]: (state) => {
            state.errorUpdateEmail=true;
        },
    }
})

const {reducer} = searchPaginationSlice;
export default reducer;

export const {
    updateStatusOfPaginatedPill,
    markAsSentToOCS,
    toggleBillForOCR,
    toggleAllBillsForOCR,
    resetBillsApprovedForOCR,
    updateArrayUncheck,
    toggleSelectAllButton,
    saveCountedFilteredBills,
    toggleSidebar,
    toggleCarDealerForEmails,
    toggleAllCareDealersForEmails,
    updateArrayUncheckCarDealers,
    toggleSelectAllButtonCarDealers,
    resetCarDealersForEmails,
    toggleOpenModal,
    toggleCloseModal,
    markAsUpdatedEmails,
    setChosenFilter,
    setPageNumber
} = searchPaginationSlice.actions;
