// @flow
import React, {useEffect, useState} from 'react'
import toast, {Toaster} from "react-hot-toast";
import './pay_table.css'
import Modal from "react-bootstrap/Modal";
import {Button} from "react-bootstrap";
import {GeneralTranslations} from "../../translations/General/GeneralTranslations";
import Form from "react-bootstrap/Form";
import {BarStyling} from "../../components/bills/List/SearchBar";
import {useForm} from "react-hook-form";
import {
    fetchCarDealers, markAsUpdatedEmails,
    nextPageCarDealers,
    toggleCarDealerForEmails, toggleCloseModal, toggleOpenModal,
    updateEmailsCarDealers, updateFiltersCarDealers
} from "../../features/SearchPagination/searchPagination.slice";
import {useDispatch, useSelector} from "react-redux";
import {LoadingSpinner} from "../../components/general/LoadingSpinner";
import InView from "../../components/InView";
import {CarDealersRow} from "./CarDealersRow";
import SendAllCarDealersForUpdatingEmails from "./SendAllCarDealersForUpdatingEmails";


function EmailPage() {
    const [newEmailValue, setNewEmailValue] = useState('');
    const dispatch = useDispatch();
    const [data, setData] = useState({});
    const permissions = JSON.parse(localStorage.getItem('user-permissions'));

    const {register, handleSubmit, reset} = useForm({
        mode: "onSubmit"
    });
    const {
        carDealers,
        loadingCarDealers,
        hasMoreCarDealers,
        hasErrorCarDealers,
        totalItemsCarDealers,
        carDealersIdsForEmails,
        all_selected_car_dealers,
        errorUpdateEmail,
        modalOpen,
        infoMessage
    } = useSelector(state => state.searchPagination);

    const advanceSearchCarDealersHandler = async (data) => {
        dispatch(updateFiltersCarDealers({
            hdnr: data.hdnr,
            default_email_address: data.default_email_address,
            zip: data.zip,
            full_name: data.full_name,
            filial: data.filial
        }));
        fetchCarDealers();
    };
    const requestNextPageCarDealers = () => {
        if (hasMoreCarDealers && !hasErrorCarDealers) {
            dispatch(nextPageCarDealers())
        }
    }

    const resetFilters = () => {
        reset();
        dispatch(updateFiltersCarDealers({hdnr: '', default_email_address: '', zip: '', full_name: '', filial: ''}));
        dispatch(fetchCarDealers());
        setData({});
    };
    useEffect(() => {
        dispatch(fetchCarDealers({hdnr: '', default_email_address: '', zip: '', full_name: '', filial: ''}));
    }, []);

    const handleOpenModal = () => {
        setTimeout(() => {
            dispatch(toggleOpenModal());
        }, [1000])
    }
    const handleCloseModal = () => {
        dispatch(toggleCloseModal(false))
    }
    const handleRowClick = () => {
        handleOpenModal();
    }

    const updateEmailForCarDealersHandler = (carDealersIdsForEmails, all_selected_car_dealers, newEmailValue, data) => {
        const ids = Object.keys(carDealersIdsForEmails).map(Number);
        dispatch(
            updateEmailsCarDealers({
                ids: ids,
                all_selected: all_selected_car_dealers,
                default_email_address: newEmailValue,
                searchParams: data
            }));

        if (errorUpdateEmail === false) {
            setTimeout(() => {
                toast.success('E-Mails erfolgreich aktualisiert');
            }, [1000])
            setNewEmailValue('');
            handleCloseModal();
            ids.map(item => {
                dispatch(toggleCarDealerForEmails(item));
            })
            setTimeout(() => {
                dispatch(markAsUpdatedEmails({carDealersIdsForEmails: ids, default_email_address: newEmailValue}));
            }, [1000])


            reset();

        } else {
            toast.error('Die E-Mails wurden nicht aktualisiert')

        }


    }
    const onSubmit = async (data) => {
        await advanceSearchCarDealersHandler(data);
        setData(data);
    }


    return (<div className={"payment-page"}>
            <div style={{display: 'flex', flexDirection: 'column', paddingInline: '20px', width: '100%'}}>
                <Form id={'advancedSearchCarDealers'} onSubmit={handleSubmit(onSubmit)}
                      className={'advanced_search_form'}>

                    <div className={'advanced_search_row'}>
                        <div className={'advanced_search_input'}>
                            <Form.Label>Partnernummer</Form.Label>
                            <Form.Control type={"text"}
                                          {...register('hdnr')}
                                          style={BarStyling}
                                          className={"searchBar"}/>
                        </div>
                        <div className={'advanced_search_input'}>
                            <Form.Label>Filial</Form.Label>
                            <Form.Control type={"text"}
                                          {...register('filial')}
                                          style={BarStyling}
                                          className={"searchBar"}/>
                        </div>
                        <div className={'advanced_search_input'}>
                            <Form.Label>Autohausname</Form.Label>
                            <Form.Control type={"text"}
                                          {...register('full_name')}
                                          style={BarStyling}
                                          className={"searchBar"}/>
                        </div>
                        <div className={'advanced_search_input'}>
                            <Form.Label>Postleitzahl</Form.Label>
                            <Form.Control type={"text"}
                                          {...register('zip')}
                                          style={BarStyling}
                                          className={"searchBar"}/>
                        </div>
                        <div className={'advanced_search_input'}>
                            <Form.Label>Aktuelle Email-Adresse</Form.Label>
                            <Form.Control type={"text"}
                                          {...register('default_email_address')}
                                          style={BarStyling}
                                          className={"searchBar"}/>

                        </div>

                    </div>
                    <div className={'advanced_search_row'}>
                        <div className={'advanced_search_button_wrapper'}
                             style={{justifyContent: 'flex-start', gap: '10px'}}>
                            <Button variant={'secondary'} onClick={resetFilters}>Filter zurücksetzen</Button>
                            <Button variant="primary" type={"submit"}>Suchen</Button>
                        </div>
                    </div>
                </Form>
                <p style={{margin: 0, alignSelf: 'flex-start', paddingTop: '16px'}}>{infoMessage}</p>
            </div>
            <Toaster/>
            <div style={{
                width: 'calc(100% - 2rem)',
                display: 'flex',
                flexDirection: 'column',
                margin: '1rem',
                maxHeight: '53.5vh',
                border: '1px solid rgba(0,0,0,.12)',
                borderRadius: '11px',
                overflow: 'auto'
            }}>

                <table style={{tableLayout: 'fixed'}}>
                    <thead>
                    <tr>
                        {permissions && ((permissions["web-for-car-dealer-management"] && permissions["web-for-car-dealer-management"].includes("updateCarDealerEmail")) || permissions.isAdmin)
                            && <th style={{width: '8%'}}><SendAllCarDealersForUpdatingEmails/></th>}
                        <th style={{width: '8%'}}>Partnernummer</th>
                        <th style={{width: '7%'}}>Filial</th>
                        <th style={{width: '38%'}}>Autohausname</th>
                        <th style={{width: '10%'}}>Postleitzahl</th>
                        <th style={{width: '28%'}}>Aktuelle Email-Adresse</th>
                        {permissions && ((permissions["web-for-car-dealer-management"] && permissions["web-for-car-dealer-management"].includes("getCarDealerLogs")) || permissions.isAdmin)
                            && <th style={{width: '5%'}}>Historie</th>}
                    </tr>
                    </thead>
                    <tbody>
                    {carDealers.map((item) => {
                        return <CarDealersRow item={item} handleRowClick={handleRowClick}/>
                    })
                    }
                    {!loadingCarDealers && carDealers?.length === 0 && <p style={{marginBlock: 0}}>
                        Keine eregbnisse
                    </p>}
                    {loadingCarDealers &&
                        <div style={{width: '100%', display: 'flex', justifyContent: 'center', padding: '1rem'}}>
                            <LoadingSpinner isSpinning={loadingCarDealers}/>
                        </div>}
                    {!loadingCarDealers && hasMoreCarDealers && <InView as={'div'}
                                                                        style={{
                                                                            width: '100%',
                                                                            display: 'flex',
                                                                            justifyContent: 'center',
                                                                            padding: '1rem'
                                                                        }}
                                                                        onceOnIntersect={requestNextPageCarDealers}>
                        <LoadingSpinner isSpinning={true}/>
                    </InView>}

                    </tbody>
                </table>
                <div className={'table-footer'} style={{bottom: '-1px'}}>
                    <div className={'table-footer-inner'}>Eregbnisse 1
                        - {carDealers.length} of {totalItemsCarDealers}</div>
                </div>
                {modalOpen &&
                    (<Modal className={'wide-modal'} show={handleOpenModal} onHide={() => {
                        handleCloseModal()
                    }}>
                        <Modal.Header closeButton>
                            <Modal.Title>E-Mail-Adresse für Zahlbriefe ändern </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Group controlId={'formEmails'} style={{width: '88%'}}>
                                <div style={{display: 'flex', flexDirection: 'column', gap: '0rem'}}>
                                    <Form.Label><span style={{
                                        textTransform: 'capitalize', fontSize: '1.2rem'
                                    }}>E-Mail-Adresse</span></Form.Label>

                                </div>
                                <Form.Control className={'form-control'} type="text" value={newEmailValue}
                                              onChange={(e) => {
                                                  setNewEmailValue(e.target.value)
                                              }}/>


                            </Form.Group>
                            <Button
                                variant="primary"
                                onClick={() => updateEmailForCarDealersHandler(carDealersIdsForEmails, all_selected_car_dealers, newEmailValue, data)}
                            >
                                E-Mails ändern

                            </Button>


                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" style={{
                                textTransform: 'capitalize'
                            }} onClick={() => {
                                handleCloseModal();
                            }}>{GeneralTranslations.de.label_cancel}</Button>
                        </Modal.Footer>
                    </Modal>)}
            </div>
        </div>
    )
}


export default EmailPage;