import {useDispatch, useSelector} from "react-redux";
import {Form} from "react-bootstrap";
import React from "react";
import {updateBillEmail} from "../../../../features/DamageBill/bill.slice";
import BillDocumentTranslations from "../../../../translations/BillDocument";


const BillEmailField = () => {
    const dispatch = useDispatch();
    const {billEmail, isReadOnly, carDealerSource, isEmailFieldDisabled  } = useSelector(state => state.damageBill);


    return (
        <div className={"Field-Input-Drop"} style={{flex: '2 1 300px'}}>
            <Form.Group>
                <Form.Label className={"Input-label"}>{BillDocumentTranslations.de.default_email_label}</Form.Label>
                <div className={"Field-Input-Text"}>
                    <Form.Control className={"Input-text"} type={'text'} value={billEmail===''? BillDocumentTranslations.de.email_default_value: billEmail }
                                  name="default_email_address"
                                  onChange= {(e) => {
                                      dispatch(updateBillEmail(e.target.value))
                                  }}
                                  disabled={isReadOnly || (isEmailFieldDisabled && carDealerSource===0) }
                                  style={{textAlign: 'left'}}
                    />
                </div>


            </Form.Group>
        </div>

    );
}

export default BillEmailField;
