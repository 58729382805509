import React, {useEffect, useState} from 'react';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form'
import useApi from "../../../hooks/useApi";
import dateFormat from "dateformat";
import toast from 'react-hot-toast';
import {useDispatch, useSelector} from "react-redux";
import {getDamages, bindClaimToBill} from "../../../services/API/damageBills.service.js";
import BillDocumentTranslations from "../../../translations/BillDocument";
import {GeneralTranslations} from "../../../translations/General/GeneralTranslations";
import {useForm} from "react-hook-form";
import {fetchDamageBill} from "../../../features/DamageBill/bill.actions";
import {resetBill} from "../../../features/DamageBill/bill.slice";

const BindToClaimModal = ({show, onClose}: { show: boolean, onClose: () => void }) => {
    const {bill, isLoading} = useSelector(state => state.damageBill)
    const [claims, setClaims] = useState([]);
    const [selectedClaimId, setSelectedClaimId] = useState();

    const {data, request, error, reset, loading, canceled} = useApi(getDamages, 'damages');
    const {data: d, request: r, error: e, loading: loadingChangeId, canceled: c} = useApi(bindClaimToBill, 'damagebills');
    const {register, handleSubmit, formState: {errors}, watch, setValue, clearErrors} = useForm();
    const watchedValueDamageNumber = watch('damageNumber');
    const watchedValueContractNumber = watch('contractNumber');
    const watchedValueVinNumber = watch('vinNumber');
    const dispatch = useDispatch();
    const permissions = JSON.parse(localStorage.getItem('user-permissions'));

    useEffect(() => {
        if (canceled) toast.error('Request to service damages was canceled, time exceeded', 'damages');
        else if (c) toast.error('Request to service damagebills was canceled, time exceeded', 'damagebills');
    }, [canceled, c]);

    useEffect(() => {
        if (c) toast.error('Request has been canceled');
    }, [c]);

    useEffect(() => {
        if (!d) return
        if (d) {
            if (d.code === 200) {
                dispatch(resetBill());
                /* We need to fetch bill again after updating damage for this damageBill*/
                dispatch(fetchDamageBill(bill?.damage_bill?.id));
                const toastMessage = toast.success(d.message, {duration: 2000});
                onClose();
                setTimeout(() => {
                    toast.dismiss(toastMessage);
                }, 1800);
            } else {
                e && toast.error(e)
                toast.dismiss(e);
            }
        }
    }, [d, onClose, e]);

    useEffect(() => {
        if (!show) {
            setClaims([])
            setSelectedClaimId('')
            setValue('damageNumber', '');
            setValue('contractNumber', '');
            setValue('vinNumber', '');
            clearErrors();
            reset();
        }
    }, [show, reset])


    useEffect(() => {
        if (data?._embedded?.records === undefined) setClaims([]);
        setClaims(data?._embedded?.records || [])
    }, [data, error])


    const search = () => {
        reset();
        request({
            damageNumber: watchedValueDamageNumber,
            contractNumber: watchedValueContractNumber,
            chassisNumber: watchedValueVinNumber
        })
    }

    const bindToBill = (e) => {
        e.preventDefault();
        const fd = new FormData();
        fd.append('damage_id', selectedClaimId);
        r({id: bill?.damage_bill_id, fd});
    }

    return (<Modal className={"wide-modal"} show={show} onHide={onClose}>
        <Modal.Header closeButton>
            <Modal.Title><span style={{
                textTransform: 'capitalize'
            }}>{BillDocumentTranslations.de.claims_modal_search_claims}</span></Modal.Title>
        </Modal.Header>
        <Modal.Body>

            <div className={"SelectDocument"}
                 style={{
                     display: 'flex', alignItems: 'flex-start', gap: '1em'
                 }}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label><span style={{
                        textTransform: 'capitalize'
                    }}>{BillDocumentTranslations.de.damage_number}</span></Form.Label>
                    <Form.Control type="text" dafaultValue={watchedValueDamageNumber}
                                  {...register("damageNumber", {required: false, pattern: /^[0-9]+$/})}
                                  onKeyDown={(e: KeyboardEvent) => {
                                      if (e.key === 'Enter') {
                                          handleSubmit(search)();
                                      }
                                  }}
                                  isInvalid={errors.damageNumber}
                    />
                    {errors?.damageNumber && <Form.Control.Feedback type="invalid">
                        Please enter numeric values.
                    </Form.Control.Feedback>}
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label><span style={{
                        textTransform: 'capitalize'
                    }}>{BillDocumentTranslations.de.contract_number}</span></Form.Label>
                    <Form.Control type="text" dafaultValue={watchedValueContractNumber}
                                  {...register("contractNumber",
                                      {required: false, pattern: /^[0-9]+$/, maxLength: 30})}
                                  onKeyDown={(e: KeyboardEvent) => {
                                      if (e.key === 'Enter') {
                                          handleSubmit(search)();
                                      }
                                  }}
                                  isInvalid={errors.contractNumber}
                    />
                    {errors?.contractNumber && <Form.Control.Feedback type="invalid">
                        Please enter numeric values.
                    </Form.Control.Feedback>}
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label><span style={{
                        textTransform: 'capitalize'
                    }}>{BillDocumentTranslations.de.chassis_number}</span></Form.Label>
                    <Form.Control type="text" dafaultValue={watchedValueVinNumber}
                                  {...register("vinNumber",
                                      {
                                          required: false,
                                          pattern: /^([A-Za-z0-9]*)$/,
                                          maxLength: 17,
                                      })}
                                  onKeyDown={(e: KeyboardEvent) => {
                                      if (e.key === 'Enter') {
                                          handleSubmit(search)();
                                      }
                                  }}
                                  isInvalid={errors.vinNumber}
                    />
                    {errors?.vinNumber && <Form.Control.Feedback type="invalid">
                        Please provide input in a valid format without any special characters.
                    </Form.Control.Feedback>}
                </Form.Group>

                <div style={{
                    marginTop: '2rem',
                }}>
                    <Button
                        variant="primary"
                        disabled={loading}
                        style={{
                            textTransform: 'capitalize'
                        }}
                        onClick={handleSubmit(search)}
                    >{!loading ? GeneralTranslations.de.label_search : 'Fetching...'}</Button>
                </div>
            </div>
            {error && <span style={{color: 'red'}}>{error}</span>}
            {data && claims.length === 0 && <p>Es wurden keine Ergebnisse gefunden</p>}
            {claims.length !== 0 && <div style={{
                display: 'flex', flexDirection: 'column', alignItems: 'center'
            }}>
                <div className={'payment-page'} style={{
                    width: 'calc(100% - 2rem)',
                    display: 'flex',
                    flexDirection: 'column',
                    margin: '1rem',
                    maxHeight: '70vh',
                    border: '1px solid rgba(0,0,0,.12)',
                    borderRadius: '11px',
                    overflow: 'auto'
                }}>

                    <table>
                        <thead>
                        <tr>
                            {/*<th>#</th>*/}
                            <th>{''}</th>
                            <th style={{
                                textTransform: 'capitalize'
                            }}>{BillDocumentTranslations.de.damage_number}</th>
                            <th>Handler</th>
                            <th>Vertragsprogramm</th>
                            <th>Laufzeit Begin - Ende</th>
                            <th>Farhzeugart</th>
                            <th>Farhzeugidentnummer</th>
                        </tr>
                        </thead>
                        <tbody>
                        {claims.map(({
                                         car_dealer_name,
                                         warranty_program,
                                         id,
                                         start_date,
                                         end_date,
                                         vehicle_brand,
                                         chassis_number,
                                         damage_number
                                     }) => {
                            return <tr key={id}>
                                <td>
                                    <input type={'radio'} name={'claim'} checked={id === selectedClaimId}
                                           onChange={() => setSelectedClaimId(id)}/>
                                </td>
                                <td>{damage_number}</td>
                                <td>{car_dealer_name || '-'}</td>
                                <td>{warranty_program}</td>
                                <td>
                                    {start_date?.date ? dateFormat(start_date.date, 'dd.mm.yyyy') : '-'}
                                    {' - '}
                                    {end_date?.date ? dateFormat(end_date.date, 'dd.mm.yyyy') : '-'}
                                </td>
                                <td>{vehicle_brand}</td>
                                <td>{chassis_number}</td>
                            </tr>
                        })}
                        </tbody>
                    </table>

                </div>
                {permissions && ((permissions["web-for-damage-bill-management"] && permissions["web-for-damage-bill-management"].includes("updateBillDamageNumber")) || permissions.isAdmin) &&
                    <Button variant="primary" disabled={!selectedClaimId || loadingChangeId || isLoading} style={{
                        textTransform: 'capitalize'
                    }}
                            onClick={(event) => {
                                bindToBill(event);
                                e && d && d.code !== 200 && !d.message && toast.error(e);

                            }}
                    >
                        {loadingChangeId ? 'Fetching...' : GeneralTranslations.de.label_select}
                    </Button>}
            </div>}
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" style={{
                textTransform: 'capitalize'
            }} onClick={onClose}>{GeneralTranslations.de.label_cancel}</Button>
        </Modal.Footer>
    </Modal>)
}


export default BindToClaimModal;