import * as React from "react";
import {roleApplication} from "../roles/List";

export const ListRowCheck = React.forwardRef((props, ref) => {
    let index = props['index'];
    const application = roleApplication();
    function handleEditItem(e) {
        props.stateChanger(props.item.id, e.target.checked);
    }

    return (
        <tr ref={ref} className={"Table-Row someClass"} id={"damage-bill-items-" + index} style={{height: '50px'}}>
            {!props.readOnly && <td className={"hash"}><input type={props.flag === 'role' ? "radio" : "checkbox"} disabled={!props.permission} name={"permission_ids[]"}
                                          onChange={handleEditItem}
                                          defaultChecked={props.isSelected}/></td>}
            <td style={{textAlign: 'center', width: '5%'}}>{props.item.id}</td>
            <td style={{width: '40%'}}>{props.item.name}</td>
            <td style={{width: '33%'}}>{props.item.description}</td>
            <td style={{width: '20%'}}>{application[props.item.applications]}</td>
        </tr>
    );

});