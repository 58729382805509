import {useCallback, useEffect, useRef, useState} from "react";
import Modal from "react-modal"
import {Form} from "react-bootstrap";
import * as React from "react"
import InView from ".././InView";
import {ListRow} from "./ListRow";
import {ListRowCheck} from "../permissions/ListRowCheck";
import "../users/Users.css";
import {
    getRoles,
    createRole,
    updateRole,
    getRolePermissions,
    updateRolePermission, getPermissionsFilter
} from '../../services/API/roles.service';
import useApi from "../../hooks/useApi";
import {useSearchPaginator} from "../contract/BillsList/useSearchPaginator";
import {BarStyling} from "../bills/List/SearchBar";
import toast, {Toaster} from "react-hot-toast";
import {LoadingSpinner} from "../general/LoadingSpinner";
import {Link} from "react-router-dom";

const initState = {name: '', description: '', id: 0, applications: '0'}

export const roleApplication = () => {
    return {
        0: "Claim invoices and Car dealer portal",
        1: "Claim invoices",
        2: "Car dealer portal"
    }
}

export const SelectStyling = {
    background: "white",
    padding: "0.5rem",
    border: "1px solid hsl(0, 0%, 80%)",
    borderRadius: "5px",
};

export function List() {
    let [roleState, setRoleState] = useState(initState);
    let [checkedIds, setCheckedIds] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenPermissionsModal, setIsOpenPermissionsModal] = useState(false);
    const [isOpenRolePermissionsModal, setIsOpenRolePermissionsModal] = useState(false);
    const roleFormRef = useRef(null);
    const rolePermissionsFormRef = useRef(null);
    const permissions = JSON.parse(localStorage.getItem('user-permissions'));
    const application = roleApplication();
    const {data: addRoleData, request: addRoleRequest, error: addRoleError, canceled: addRoleCanceled, reset: addRoleReset} = useApi(createRole, 'roles');
    const {data: updateRoleData, request: updateRoleRequest, error: updateRoleError, canceled: updateRoleCanceled, reset: updateRoleReset} = useApi(updateRole, 'roles');
    const [keyword, setKeyword] = useState('');
    const [keywordRP, setKeywordRP] = useState('');
    const [statuses] = useState([]);
    const [statusesR, setStatusesR] = useState('');
    const [statusesP, setStatusesP] = useState('');
    const [statusesRP, setStatusesRP] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const [RPId, setRPId] = useState('');
    const [, hasError, allRoles, hasMore, nextPage, refresh] = useSearchPaginator(keyword, statuses, getRoles, 'roles', permissions && ((permissions["web-for-user-management"] && permissions["web-for-user-management"].includes("getAllRoles")) || permissions.isAdmin), true, statusesR);
    const [loadingPermissions, hasErrorPermissions, allPermissions, hasMorePermissions, nextPagePermissions] = useSearchPaginator(searchValue, statuses, getPermissionsFilter, 'permissions', permissions && ((permissions["web-for-user-management"] && permissions["web-for-user-management"].includes("getAllPermissions")) || permissions.isAdmin), true, statusesP);
    const [showButton, setShowButton] = useState(true);

    const {
        data: rolePermissionsData,
        request: getRolePermissionsRequest,
        error: rolePermissionsError,
        loading: loadingRolePermission
    } = useApi(getRolePermissions, 'roles');

    const {
        data: rolePermissionsDataReadOnly,
        request: getRolePermissionsRequestReadOnly,
        error: rolePermissionsErrorReadOnly,
        loading: loadingRolePermissionReadOnly
    } = useApi(getRolePermissions, 'roles');

    const {
        data: updateRolePermissionData,
        request: updateRolePermissionsRequest,
        error: updateRolePermissionsError,
        canceled: updateRolePermissionsCanceled,
        reset: updateRolePermissionsReset
    } = useApi(updateRolePermission, 'roles');

    useEffect(() => {
        if (addRoleCanceled || updateRoleCanceled || updateRolePermissionsCanceled) toast.error('Request to service roles was canceled, time exceeded', 'roles');
    }, [addRoleCanceled, updateRoleCanceled, updateRolePermissionsCanceled]);

    const requestNextPage = useCallback(() => {
        if (hasMore && !hasError) {
            nextPage()
        }
    }, [hasMore, hasError, nextPage]);

    const requestNextPageForPermissions = useCallback(() => {
        if (hasMorePermissions && !hasErrorPermissions) {
            nextPagePermissions()
        }
    }, [hasMorePermissions, hasErrorPermissions, nextPagePermissions]);

    const toggleModal = useCallback(() => {
        setRoleState(initState);
        setIsOpen(prevOpen => !prevOpen);
    }, [])

    const toggleModalPermissions = useCallback(() => {
        setSearchValue('');
        setStatusesP('');
        setIsOpenPermissionsModal(prevOpen => !prevOpen);
    }, [])

    const toggleModalRolePermissions = useCallback(() => {
        if (isOpenRolePermissionsModal) setRPId('');
        setIsOpenRolePermissionsModal(prevOpen => !prevOpen);
    }, [isOpenRolePermissionsModal]);


    const updateItem = useCallback((event) => {
        setRoleState(prevState => ({
            ...prevState,
            [event.target.name]: event.target.value
        }));
    }, []);


    const onRoleFormSubmit = useCallback((e) => {
        e.preventDefault();
        const data = new FormData(roleFormRef.current);//izmenaaaa

        const role = {
            name: data.get('name'),
            description: data.get('description'),
            applications: data.get('applications')
        }

        if (data.get('id') > 0) {
            const id = data.get('id');
            updateRoleRequest({id: id, role: role});
        } else {
            addRoleRequest({role: role});
        }

    }, [updateRoleRequest, addRoleRequest]);

    const onRolePermissionsFormSubmit = useCallback((e) => {
        e.preventDefault();
        const data = new FormData(rolePermissionsFormRef.current);
        data.set("permission_ids", checkedIds.join());
        updateRolePermissionsRequest({data, id: data.get("id")});

    }, [updateRolePermissionsRequest, checkedIds]);


    const loadItemForEdit = useCallback((item) => {
        setRoleState({...item})
        setIsOpen(true);
    }, [])

    const loadItemForEditPermission = useCallback((item) => {
        setCheckedIds([]);
        setRoleState(prevState => ({
            ...prevState,
            id: item.id,
            name: item.name,
            description: item.description,
        }));
        getRolePermissionsRequest({id: item.id, name: '', application: ''});
    }, [getRolePermissionsRequest])

    const loadRolePermissions = useCallback((item) => {
        getRolePermissionsRequestReadOnly({id: item.id, name: '', application: ''});
        setRPId(item.id);
    }, [getRolePermissionsRequestReadOnly]);

    useEffect(() => {
        !!RPId && getRolePermissionsRequestReadOnly({id: RPId, name: keywordRP, application: statusesRP});
    }, [RPId, keywordRP, statusesRP]);

    const handleOnCheckChange = (id, isChecked) => {
        let tmpCheckedIds = checkedIds;
        if (isChecked) {
            tmpCheckedIds.push(id);
        } else {
            var index = tmpCheckedIds.indexOf(id);
            if (index !== -1) {
                tmpCheckedIds.splice(index, 1);
            }
        }
        if (tmpCheckedIds.length === 0) setShowButton(false);
        else setShowButton(true);
        setCheckedIds(tmpCheckedIds);
    }

    useEffect(() => {
        if (checkedIds.length === 0) setShowButton(false);
        else setShowButton(true);
    }, []);

    useEffect(() => {
        if (!addRoleData) return;
        if (addRoleError) {
            toast.error(addRoleError);
        }
        else {
            if(addRoleData && addRoleData.code && (addRoleData.code < 200 || addRoleData.code > 299)) return;
            if (addRoleData.message) toast.success(addRoleData.message);
            addRoleReset();
            refresh();
            toggleModal();
        }
    }, [addRoleData, toggleModal, addRoleError, refresh]);

    useEffect(() => {
        if (!updateRoleData) return;
        if (updateRoleError) {
            toast.error(updateRoleError);
        } else {
            if(updateRoleData && updateRoleData.code && (updateRoleData.code < 200 || updateRoleData.code > 299)) return;
            if (updateRoleData.message) toast.success(updateRoleData.message);
            updateRoleReset();
            refresh();
            toggleModal();
        }
    }, [updateRoleData, toggleModal, updateRoleError, refresh]);

    useEffect(() => {
        if (!rolePermissionsData || !rolePermissionsData?.data) return;
        if (rolePermissionsError) {
            toast.error(rolePermissionsError);
        } else {
            if(rolePermissionsData && rolePermissionsData.code && (rolePermissionsData.code < 200 || rolePermissionsData.code > 299)) return;
            setCheckedIds(rolePermissionsData.data.map(({id}) => id));
            toggleModalPermissions();
        }

    }, [rolePermissionsData, rolePermissionsError]);

    useEffect(() => {
        if (!rolePermissionsDataReadOnly || !rolePermissionsDataReadOnly?.data) return;
        if (rolePermissionsErrorReadOnly) {
            toast.error(rolePermissionsErrorReadOnly);
        } else {
            if(rolePermissionsDataReadOnly && rolePermissionsDataReadOnly.code && (rolePermissionsDataReadOnly.code < 200 || rolePermissionsDataReadOnly.code > 299)) return;
            setIsOpenRolePermissionsModal(true);
        }

    }, [rolePermissionsDataReadOnly, rolePermissionsErrorReadOnly]);

    useEffect(() => {
        if (!updateRolePermissionData) return;
        if (updateRolePermissionsError) {
            if (updateRolePermissionData?.errors?.permission_ids?.isEmpty) toast.error('Before saving at least one permission must be chosen');
            else toast.error(updateRolePermissionsError);
            updateRolePermissionsReset();
        } else {
            if(updateRolePermissionData && updateRolePermissionData.code && (updateRolePermissionData.code < 200 || updateRolePermissionData.code > 299)) return;
            if (updateRolePermissionData.message) toast.success(updateRolePermissionData.message);
            updateRolePermissionsReset();
            refresh();
            toggleModalPermissions();
        }

    }, [updateRolePermissionData, updateRolePermissionsError, toggleModalPermissions, refresh]);

    const onChangeHandler = (keyword) => {
        setSearchValue(keyword);
    }

    const onChangeRoleHandler = (keyword) => {
        setKeyword(keyword);
    }

    const handleOnChangeApplicationRoles = (application) => {
        setStatusesR(application);
    }
    
    const handleOnChangeApplicationPermissions = (application) => {
        setStatusesP(application);
    }

    const handleOnChangeApplicationRolePermissions = (application) => {
        setStatusesRP(application);
    }

    const handleChangeRolePermissions = (keyword) => {
        setKeywordRP(keyword);
    }

    return permissions && ((permissions["web-for-user-management"] && permissions["web-for-user-management"].includes("getAllRoles")) || permissions.isAdmin) ?
        <div className={"list-container"}>
            <div className={"toolbox"} style={{display: 'flex', justifyContent: 'space-between'}}>
            {permissions && ((permissions["web-for-user-management"] && permissions["web-for-user-management"].includes("insertNewRole")) || permissions.isAdmin) &&
                    <button type="button" className={"Button-Add btn btn-primary"} onClick={toggleModal}>Rolle
                        hinzufügen
                    </button>}
            {permissions && ((permissions["web-for-user-management"] && permissions["web-for-user-management"].includes("getAllPermissions")) || permissions.isAdmin) &&
                <Link to="/permissions" target={'_blank'}>Alle Berechtigungen</Link>}
                </div>
                <div className={"toolbox"} style={{display: 'flex', gap: '20px'}}>
                   <input
                            type={"text"}
                            style={{...BarStyling, borderRadius: '5px'}}
                            key="random2wq"
                            value={keyword}
                            onChange={e => onChangeRoleHandler(e.target.value)}
                            placeholder={"Rollen suchen"}
                        />
                    <select style={SelectStyling} onChange={(e) => handleOnChangeApplicationRoles(e.target.value)}>
                        <option value={''}>All roles</option>
                        <option value={0}>{application[0]}</option>
                        <option value={1}>{application[1]}</option>
                        <option value={2}>{application[2]}</option>
                    </select>
                </div>
            <Toaster />
            <div>
                <table>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Rollenbezeichnung</th>
                        <th>Beschreibung</th>
                        <th>Application</th>
                        <th>Aktionen</th>
                    </tr>
                    </thead>
                    <tbody>
                    {allRoles !== null && allRoles.map((item, index) => {
                                return (
                                    (index !== Math.round(allRoles.length - 1)) ?
                                        <ListRow key={"role_item_" + index} item={item} index={index}
                                                 onEdit={loadItemForEdit.bind(this, item)}
                                                 onEditPermissions={loadItemForEditPermission.bind(this, item)}
                                                 onLoadRolePermissions={loadRolePermissions.bind(this, item)}/>
                                        : <InView as={ListRow} key={"role_item_" + index}
                                                  item={item} index={index}
                                                  onEdit={loadItemForEdit.bind(this, item)}
                                                  onEditPermissions={loadItemForEditPermission.bind(this, item)}
                                                  onceOnIntersect={requestNextPage}
                                                  onLoadRolePermissions={loadRolePermissions.bind(this, item)}/>
                                )
                            }
                        )
                    }
                    </tbody>
                </table>
            </div>
            <Modal isOpen={isOpen}
                   onRequestClose={toggleModal}
                   overlayClassName={"modal-overlay"}
                   className={"modal-narrow"}
                   appElement={document.getElementsByTagName('body')}
                   contentLabel="Rolle"
            >
                <Form id="roleForm" ref={roleFormRef} onSubmit={onRoleFormSubmit}>
                    <input type={"hidden"} name={"id"} value={roleState.id}/>
                    <div className={"form-group"}>
                        Name: <input type={"text"} className={"form-control"} name={"name"}
                                     onChange={updateItem} value={roleState.name}/>
                    </div>
                    <div className={"form-group"}>
                        Beschreibung: <textarea className={"form-control"} name={"description"}
                                                onChange={updateItem} value={roleState.description}/>
                    </div>
                    <div className={"form-group"}>
                        Application: <select style={SelectStyling} className={"form-control"} name={"applications"}
                                             onChange={updateItem} value={roleState.applications}>
                        <option value={0}>{application[0]}</option>
                        <option value={1}>{application[1]}</option>
                        <option value={2}>{application[2]}</option>
                    </select>
                    </div>
                    <div className={"form-buttons-wrapper"}>
                        <button className={"btn btn-primary form-buttons"}>Speichern</button>
                        <button onClick={toggleModal} className={"btn btn-danger form-buttons"}>Abbrechen</button>
                    </div>
                </Form>
            </Modal>
            <Modal isOpen={isOpenPermissionsModal}
                   className={"modal-fullscreen"}
                   onRequestClose={toggleModalPermissions}
                   overlayClassName={"modal-overlay"}
                   appElement={document.getElementsByTagName('body')}
                   contentLabel="Rolle berechtigungen">
                <div>
                    <div className={"toolbox"} style={{display: 'flex', justifyContent: 'space-between', gap: '20px'}}>
                <input
                    type={"text"}
                    style={{...BarStyling, borderRadius: '5px'}}
                    key="random1wq"
                    value={searchValue}
                    onChange={e => onChangeHandler(e.target.value)}
                    placeholder={"Berechtigungen suchen"}
                />
                <select style={SelectStyling} onChange={(e) => handleOnChangeApplicationPermissions(e.target.value)}>
                    <option value={''}>All permissions</option>
                    <option value={0}>{application[0]}</option>
                    <option value={1}>{application[1]}</option>
                    <option value={2}>{application[2]}</option>
                </select>
                    </div>
                </div>
                <Form id={"rolePermissionsForm"} ref={rolePermissionsFormRef} onSubmit={onRolePermissionsFormSubmit}
                      className={"role-form"}>
                    <input type={"hidden"} name={"id"} value={roleState.id}/>
                    <div className={"form-group role-form-table"}>
                        <table style={{width: '100%'}}>
                            <thead>
                            <tr>
                                <th></th>
                                <th style={{textAlign: 'center'}}>#</th>
                                <th>Berechtigungsbezeichnung</th>
                                <th>Beschreibung</th>
                                <th>Application</th>
                            </tr>
                            </thead>
                            <tbody>
                            {allPermissions !== null &&
                                    allPermissions.filter((item) => item.is_visible).map((item, index) => {
                                        return (
                                            (index !== Math.round(allPermissions.filter((item) => item.is_visible).length - 1)) ?
                                                <ListRowCheck key={item.id}
                                                              item={item}
                                                              permission={permissions && ((permissions["web-for-user-management"] && permissions["web-for-user-management"].includes("insertNewRolePermission")) || permissions.isAdmin)}
                                                              index={index}
                                                              stateChanger={handleOnCheckChange}
                                                              isSelected={checkedIds.includes(item.id)}/>
                                                : <InView as={ListRowCheck} key={item.id}
                                                          item={item}
                                                          permission={permissions && ((permissions["web-for-user-management"] && permissions["web-for-user-management"].includes("insertNewRolePermission")) || permissions.isAdmin)}
                                                          index={index}
                                                          stateChanger={handleOnCheckChange}
                                                          isSelected={checkedIds.includes(item.id)}
                                                          onceOnIntersect={requestNextPageForPermissions}/>

                                        )
                                    })
                            }
                            </tbody>
                        </table>
                        {loadingPermissions && <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '95%', width: '100%'}}><LoadingSpinner isSpinning={loadingPermissions}/></div>}
                    </div>
                    <div className={"form-buttons-wrapper"}>
                        {permissions && ((permissions["web-for-user-management"] && permissions["web-for-user-management"].includes("insertNewRolePermission")) || permissions.isAdmin) &&
                           showButton && <button className={"btn btn-primary form-buttons"}>Speichern</button>}
                        <button onClick={toggleModalPermissions} className={"btn btn-danger form-buttons"}>Abbrechen
                        </button>
                    </div>
                </Form>
            </Modal>
            <Modal isOpen={(loadingRolePermission || loadingRolePermissionReadOnly) && !isOpenPermissionsModal && !isOpenRolePermissionsModal} overlayClassName={"modal-overlay"} className={"modal-fullscreen"} appElement={document.getElementsByTagName('body')}>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}><LoadingSpinner isSpinning={loadingRolePermission || loadingRolePermissionReadOnly} />
                </div>
                </Modal>
            <Modal isOpen={isOpenRolePermissionsModal}
                   onRequestClose={toggleModalRolePermissions}
                   overlayClassName={"modal-overlay"}
                   className={"modal-fullscreen"}
                   appElement={document.getElementsByTagName('body')}
                   contentLabel="Rolle berechtigungen"
            >
                <div className={"form-group role-form-table"}>
                    <div className={"toolbox"} style={{display: 'flex', gap: '20px'}}>
                        <input
                            type={"text"}
                            style={{...BarStyling, borderRadius: '5px'}}
                            key="random5wq"
                            // value={keywordReadOnly}
                            onChange={e => handleChangeRolePermissions(e.target.value)}
                            placeholder={"Berechtigung suchen"}
                        />
                        <select style={SelectStyling} onChange={(e) => handleOnChangeApplicationRolePermissions(e.target.value)}>
                            <option value={''}>All permissions</option>
                            <option value={0}>{application[0]}</option>
                            <option value={1}>{application[1]}</option>
                            <option value={2}>{application[2]}</option>
                        </select>
                    </div>
                    {!rolePermissionsDataReadOnly ? <LoadingSpinner isSpinning={!rolePermissionsDataReadOnly}/> : <table style={{width: '100%'}}>
                        <thead>
                        <tr>
                            <th style={{textAlign: 'center'}}>#</th>
                            <th>Berechtigungsbezeichnung</th>
                            <th>Beschreibung</th>
                            <th>Application</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            rolePermissionsDataReadOnly?.data.map((item, index) => {
                                return (<ListRowCheck key={item.id} item={item} index={index} readOnly={true}/>)
                            })
                        }
                        </tbody>
                    </table>}
                </div>
                <div className={"form-buttons-wrapper"}>
                    <button onClick={toggleModalRolePermissions} className={"btn btn-danger form-buttons"}>Abbrechen</button>
                </div>
            </Modal>
        </div> : <div>Sie dürfen diesen Inhalt nicht sehen</div>
}