import {createAction} from "@reduxjs/toolkit";

const billSliceName = 'damageBill';
/*
    For fetching damage bill
 */
export const fetchDamageBill = createAction(`${billSliceName}/fetchDamageBill`)
export const fetchDamageBillFullFilled = createAction(`${billSliceName}/fetchDamageBill/fulfilled`);
export const fetchDamageBillFailed = createAction(`${billSliceName}/fetchDamageBill/failed`);

/*
    For re fetching damage bill from id that exists on store
 */
export const reFetchDamageBill = createAction(`${billSliceName}/reFetchDamageBill`)

/**
 * For fetching a damage of a damage bill
 */
export const fetchDamage = createAction(`${billSliceName}/fetchDamage`);
export const fetchDamageFullFilled = createAction(`${billSliceName}/fetchDamage/fulfilled`);
export const fetchDamageFailed = createAction(`${billSliceName}/fetchDamage/failed`);


/**
 * For fetching list of bill based on damage number
 */
export const fetchBillsLinkedToDamage = createAction(`${billSliceName}/fetchBillsLinkedToDamage`);
export const fetchBillsLinkedToDamageFullFilled = createAction(`${billSliceName}/fetchBillsLinkedToDamage/fulfilled`);
export const fetchBillsLinkedToDamageFailed = createAction(`${billSliceName}/fetchBillsLinkedToDamage/failed`);

export const fetchBillsLinkedToSchadensnummer = createAction(`${billSliceName}/fetchBillsLinkedToDamageSchadensnummer`);
export const fetchBillsLinkedToSchadensnummerFullFilled = createAction(`${billSliceName}/fetchBillsLinkedToDamageSchadensnummer/fulfilled`);
export const fetchBillsLinkedToSchadensnummerFailed = createAction(`${billSliceName}/fetchBillsLinkedToDamageSchadensnummer/failed`);

export const fetchBillsLinkedToVertragsvereinbarung = createAction(`${billSliceName}/fetchBillsLinkedToDamageVertragsvereinbarung`);
export const fetchBillsLinkedToVertragsvereinbarungFullFilled = createAction(`${billSliceName}/fetchBillsLinkedToDamageVertragsvereinbarung/fulfilled`);
export const fetchBillsLinkedToVertragsvereinbarungFailed = createAction(`${billSliceName}/fetchBillsLinkedToDamageVertragsvereinbarung/failed`);

export const fetchBillsLinkedToFahrzeugidentnummer = createAction(`${billSliceName}/fetchBillsLinkedToFahrzeugidentnummer`);
export const fetchBillsLinkedToFahrzeugidentnummerFullFilled = createAction(`${billSliceName}/fetchBillsLinkedToFahrzeugidentnummer/fulfilled`);
export const fetchBillsLinkedToFahrzeugidentnummerFailed = createAction(`${billSliceName}/fetchBillsLinkedToFahrzeugidentnummer/failed`);

/**
 * For fetching damage of damage bill
 */
export const fetchWarrantyContract = createAction(`${billSliceName}/fetchWarrantyContract`);
export const fetchWarrantyContractFullFilled = createAction(`${billSliceName}/fetchWarrantyContract/fulfilled`);
export const fetchWarrantyContractFailed = createAction(`${billSliceName}/fetchWarrantyContract/failed`);


/**
 * For fetching spent amount of a contract
 */
export const fetchContractSpentAmount = createAction(`${billSliceName}/fetchContractSpentAmount`);
export const fetchContractSpentAmountFullFilled = createAction(`${billSliceName}/fetchContractSpentAmount/fulfilled`);
export const fetchContractSpentAmountFailed = createAction(`${billSliceName}/fetchContractSpentAmount/failed`);


/**
 * For fetching reimbursements
 */
export const fetchMaterialWage = createAction(`${billSliceName}/fetchMaterialWage`);
export const fetchMaterialWageFullFilled = createAction(`${billSliceName}/fetchMaterialWage/fulfilled`);
export const fetchMaterialWageFailed = createAction(`${billSliceName}/fetchMaterialWage/failed`);

/**
 * For fetching previous damage
 */
export const fetchPreviousDamage = createAction(`${billSliceName}/fetchPreviousDamage`);
export const fetchPreviousDamageFullFilled = createAction(`${billSliceName}/fetchPreviousDamage/fulfilled`);
export const fetchPreviousDamageFailed = createAction(`${billSliceName}/fetchPreviousDamage/failed`);


/**
 * For fetching car dealer who sold/sent bill
 */
export const fetchCarDealerSoldBill = createAction(`${billSliceName}/fetchCarDealerSoldBill`);
export const fetchCarDealerSoldBillFullFilled = createAction(`${billSliceName}/fetchCarDealerSoldBill/fulfilled`);
export const fetchCarDealerSoldBillFailed = createAction(`${billSliceName}/fetchCarDealerSoldBill/failed`);

/**
 * For fetching external provider for bill
 */
export const fetchExternalProvider = createAction(`${billSliceName}/fetchExternalProvider`);
export const fetchExternalProviderFullFilled = createAction(`${billSliceName}/fetchExternalProvider/fulfilled`);
export const fetchExternalProviderFailed = createAction(`${billSliceName}/fetchExternalProvider/failed`);


/**
 * For fetching car dealer who sold the policy
 */
export const fetchCarDealerSoldPolicy = createAction(`${billSliceName}/fetchCarDealerSoldPolicy`);
export const fetchCarDealerSoldPolicyFullFilled = createAction(`${billSliceName}/fetchCarDealerSoldPolicy/fulfilled`);
export const fetchCarDealerSoldPolicyFailed = createAction(`${billSliceName}/fetchCarDealerSoldPolicy/failed`);

/**
 * For fetching car dealer who sold the policy
 */
export const updateBill = createAction(`${billSliceName}/updateBill`);
export const updateBillFullFilled = createAction(`${billSliceName}/updateBill/fulfilled`);
export const updateBillFailed = createAction(`${billSliceName}/updateBill/failed`);

export const changePaymentType = createAction(`${billSliceName}/changePaymentType`);
export const changePaymentTypeFullFilled = createAction(`${billSliceName}/changePaymentType/fulfilled`);
export const changePaymentTypeFailed = createAction(`${billSliceName}/changePaymentType/failed`);
/**
 * For fetching car dealer who sold the policy
 */
export const updateBillManualSelection = createAction(`${billSliceName}/updateBillManualSelection`);
export const updateBillManualSelectionFullFilled = createAction(`${billSliceName}/updateBillManualSelection/fulfilled`);
export const updateBillManualSelectionFailed = createAction(`${billSliceName}/updateBillManualSelection/failed`);


/**
 * For fetching car dealer who sold the policy
 */
export const updateBillForPayment = createAction(`${billSliceName}/updateBillForPayment`);
export const updateBillForPaymentFullFilled = createAction(`${billSliceName}/updateBillForPayment/fulfilled`);
export const updateBillForPaymentFailed = createAction(`${billSliceName}/updateBillForPayment/failed`);


/**
 * For fetching car dealer who sold the policy
 */
export const updateBillBackToPayment = createAction(`${billSliceName}/updateBillBackToPayment`);
export const updateBillBackToPaymentFullFilled = createAction(`${billSliceName}/updateBillBackToPayment/fulfilled`);
export const updateBillBackToPaymentFailed = createAction(`${billSliceName}/updateBillBackToPayment/failed`);


/**
 * For fetching car dealer who sold the policy
 */
export const markBillAuthorizedForPayment = createAction(`${billSliceName}/markBillAuthorizedForPayment`);
export const markBillAuthorizedForPaymentFullFilled = createAction(`${billSliceName}/markBillAuthorizedForPayment/fulfilled`);
export const markBillAuthorizedForPaymentFailed = createAction(`${billSliceName}/markBillAuthorizedForPayment/failed`);


/**
 * For fetching car dealer who sold the policy
 */
export const toggleDelete = createAction(`${billSliceName}/toggleDelete`);
export const toggleDeleteFullFilled = createAction(`${billSliceName}/toggleDelete/fulfilled`);
export const toggleDeleteFailed = createAction(`${billSliceName}/toggleDelete/failed`);


/**
 * For fetching car dealer who sold the policy
 */
export const resolveBill = createAction(`${billSliceName}/resolveBill`);
export const resolveBillFullFilled = createAction(`${billSliceName}/resolveBill/fulfilled`);
export const resolveBillFailed = createAction(`${billSliceName}/resolveBill/failed`);

/**
 * For fetching currency rates of bill
 */
export const fetchCurrencyRateEUR = createAction(`${billSliceName}/fetchCurrencyRateEUR`);
export const fetchCurrencyRateEURFullFilled = createAction(`${billSliceName}/fetchCurrencyRateEUR/fulfilled`);
export const fetchCurrencyRateEURFailed = createAction(`${billSliceName}/fetchCurrencyRateEUR/failed`);
export const fetchCurrencyRates = createAction(`${billSliceName}/fetchCurrencyRates`);
export const fetchCurrencyRatesFullFilled = createAction(`${billSliceName}/fetchCurrencyRates/fulfilled`);
export const fetchCurrencyRatesFailed = createAction(`${billSliceName}/fetchCurrencyRates/failed`);

export const updateCurrency = createAction(`${billSliceName}/updateCurrency`);
export const updateCurrencyFullFilled = createAction(`${billSliceName}/updateCurrency/fulfilled`);
export const updateCurrencyFailed = createAction(`${billSliceName}/updateCurrency/failed`);

export const fetchValidationJsonData = createAction(`${billSliceName}/fetchValidationJsonData`)
export const fetchValidationJsonDataFullFilled = createAction(`${billSliceName}/fetchValidationJsonData/fulfilled`);
export const fetchValidationJsonDataFailed = createAction(`${billSliceName}/fetchValidationJsonData/failed`);
