import React from 'react';
import {BILL_PROCESSING_STATUSES} from "../../StatusContext";
import {useDispatch, useSelector} from "react-redux";
import {toggleAllBillsForOCR, toggleSelectAllButton} from "../../../features/SearchPagination/searchPagination.slice";

function SendAllBillsToOCRCheckbox() {
    const {billsPaginated, select_all_button} = useSelector(state => state.searchPagination);
    const dispatch = useDispatch();
    // const [isChecked, setIsChecked] = useState(false);

    const handleClickedCheckbox = (e) => {
        // setIsChecked(e.target.checked);
        dispatch(toggleSelectAllButton(e.target.checked));

        const billIds = billsPaginated.filter(item => item.processing_status === BILL_PROCESSING_STATUSES.DOCUMENT_CREATED).map(bill => [bill.id, e.target.checked]);
        const billsForOCR = Object.fromEntries(billIds);
        dispatch(toggleAllBillsForOCR(billsForOCR));
    }

    return (
        <label className="select_all"
               title="Send all possible bills to OCR"
               htmlFor={"bill_selectAll_checkbox"}
        >
            <input type="checkbox"
                   className="link_checkbox"
                   id={"bill_selectAll_checkbox"}
                   onChange={(e) => handleClickedCheckbox(e)}
                   checked={select_all_button}
            />
            <span className="checkmark"/>
            <p>Wählen Sie alle möglichen Rechnungen für OCR aus.</p>
        </label>
    );
}

export default React.memo(SendAllBillsToOCRCheckbox)
