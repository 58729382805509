import {BarStyling} from "./SearchBar";
import React from "react";
import Form from "react-bootstrap/Form";
import {advanceFetchBills} from "../../../features/SearchPagination/searchPagination.slice";
import {useDispatch} from "react-redux";
import Button from "react-bootstrap/Button";
import {BILL_PROCESSING_STATUS_LABELS} from "../../StatusContext";
import {useForm} from "react-hook-form";
import {MdOutlineCancel} from "react-icons/md";

const AdvancedBillsSearch = ({showSearch, setShow}) => {
    const dispatch = useDispatch();
    const {register, handleSubmit, reset} = useForm({
        mode: "onSubmit"
    });
    const advanceSearchBills = async (data) => {
        dispatch(
            advanceFetchBills(data)
        )
    };

    const resetFilters = () => {
        reset();
        dispatch(
            advanceFetchBills({})
        )
    };

    return (
        <div style={{display: showSearch ? 'flex' : 'none'}} className={'advanced_search_wrapper'}>
            <div className={'advanced_search_button_wrapper'}>
                <button className={'advanced_search_button'} type={"button"} onClick={() => setShow(false)}><MdOutlineCancel /></button>
            </div>
            <Form id={'advancedSearch'} onSubmit={handleSubmit(advanceSearchBills)} className={'advanced_search_form'}>
                <div className={'advanced_search_form'}>
                    <div className={'advanced_search_row'}>
                        <div className={'advanced_search_input'}>
                            <Form.Label>Firmenname</Form.Label>
                            <Form.Control type={"text"}
                                          {...register('company_name')}
                                          style={BarStyling}
                                          className={"searchBar"}/>
                        </div>
                        <div className={'advanced_search_input'}>
                            <Form.Label>Kundenname</Form.Label>
                            <Form.Control type={"text"}
                                          {...register('customer_name')}
                                          style={BarStyling}
                                          className={"searchBar"}/>
                        </div>
                    </div>
                    <div className={'advanced_search_row'}>
                        <div className={'advanced_search_input'}>
                            <Form.Label>Postleitzahl</Form.Label>
                            <Form.Control type={"text"}
                                          {...register('postcode')}
                                          style={BarStyling}
                                          className={"searchBar"}/>
                        </div>
                        <div className={'advanced_search_input'}>
                            <Form.Label>Partnernummer</Form.Label>
                            <Form.Control type={"text"}
                                          {...register('partner_number')}
                                          style={BarStyling}
                                          className={"searchBar"}/>
                        </div>
                    </div>
                    <div className={'advanced_search_row'}>
                        <div className={'advanced_search_input'}>
                            <Form.Label>Ort</Form.Label>
                            <Form.Control type={"text"}
                                          {...register('city')}
                                          style={BarStyling}
                                          className={"searchBar"}/>
                        </div>
                        <div className={'advanced_search_input'}>
                            <Form.Label>E-Mail-Adresse</Form.Label>
                            <Form.Control type={"text"}
                                          {...register('email_address')}
                                          style={BarStyling}
                                          className={"searchBar"}/>
                        </div>
                    </div>
                    <div className={'advanced_search_row'}>
                        <div className={'advanced_search_input'}>
                            <Form.Label>IBAN</Form.Label>
                            <Form.Control type={"text"}
                                          {...register('IBAN')}
                                          style={BarStyling}
                                          className={"searchBar"}/>
                        </div>
                        <div className={'advanced_search_input'}>
                            <Form.Label>Vertragsnummer</Form.Label>
                            <Form.Control type={"text"}
                                          {...register('contract_number')}
                                          style={BarStyling}
                                          className={"searchBar"}/>
                        </div>
                    </div>
                    <div className={'advanced_search_row'}>
                        <div className={'advanced_search_input'}>
                            <Form.Label>Fahrgestellnummer</Form.Label>
                            <Form.Control type={"text"}
                                          {...register('chassis_number')}
                                          style={BarStyling}
                                          className={"searchBar"}/>
                        </div>
                        <div className={'advanced_search_input'}>
                            <Form.Label>Amtliches Kennzeichen</Form.Label>
                            <Form.Control type={"text"}
                                          {...register('registration_number')}
                                          style={BarStyling}
                                          className={"searchBar"}/>
                        </div>
                    </div>
                    <div className={'advanced_search_input'}>
                        <Form.Label>Bearbeitungsstatus</Form.Label>
                        <Form.Control {...register('processing_status')}
                                      as={'select'}
                                      className={"searchBar"}>
                            <option value={0}>Status auswählen</option>
                            <option value={-2}>{BILL_PROCESSING_STATUS_LABELS["-2"]}</option>
                            <option value={-1}>{BILL_PROCESSING_STATUS_LABELS["-1"]}</option>
                            <option value={1}>{BILL_PROCESSING_STATUS_LABELS["1"]}</option>
                            <option value={2}>{BILL_PROCESSING_STATUS_LABELS["2"]}</option>
                            <option value={3}>{BILL_PROCESSING_STATUS_LABELS["3"]}</option>
                            <option value={4}>{BILL_PROCESSING_STATUS_LABELS["4"]}</option>
                            <option value={5}>{BILL_PROCESSING_STATUS_LABELS["5"]}</option>
                            <option value={6}>{BILL_PROCESSING_STATUS_LABELS["6"]}</option>
                            <option value={7}>{BILL_PROCESSING_STATUS_LABELS["7"]}</option>
                            <option value={8}>{BILL_PROCESSING_STATUS_LABELS["8"]}</option>
                        </Form.Control>
                    </div>
                </div>
                <div className={'advanced_search_button_wrapper'} style={{justifyContent: 'space-between'}}>
                    <Button variant={'secondary'} onClick={resetFilters}>Filter zurücksetzen</Button>
                    <Button variant="primary" type={"submit"}>Suchen</Button>
                </div>
            </Form>
        </div>
    )
};

export default AdvancedBillsSearch;