import {BsPencilSquare, BsLockFill, BsLock} from "react-icons/bs";
import * as React from "react";
import {roleApplication} from "./List";

export const ListRow = React.forwardRef(({index, item, onEdit, onEditPermissions, onLoadRolePermissions}, ref) => {
    const permissions = JSON.parse(localStorage.getItem('user-permissions'));
    const application = roleApplication();
    return (
        <tr ref={ref} className={"Table-Row someClass"} id={"damage-bill-items-" + index} style={{height: '50px'}}>
            <td className={"hash"}>{item.id}</td>
            <td>{item.name}</td>
            <td>{item.description}</td>
            <td>{application[item.applications]}</td>
            <td className={"Last-Table-Cell"} style={{width: '100px'}}>
                {(permissions && (((permissions["web-for-user-management"] && permissions["web-for-user-management"].includes("getAllPermissions")) && (permissions["web-for-user-management"] && permissions["web-for-user-management"].includes("permissionsPerRole"))) || permissions.isAdmin)) && !item.isSuperAdmin &&
                    <button style={{border: "none", background: "unset", float: "right"}} data-itemid={item.id}
                            onClick={onEditPermissions}>
                        <BsLockFill
                            style={{
                                color: "gray",
                                background: "unset",
                                margin: "0 0 0 0px"
                            }}
                            className={"Icon-Decline"}/>
                    </button>}
                {(permissions && ((permissions["web-for-user-management"] && permissions["web-for-user-management"].includes("permissionsPerRole")) || permissions.isAdmin)) && !item.isSuperAdmin &&
                    <button style={{border: "none", background: "unset", float: "right"}} data-itemid={item.id}
                            onClick={onLoadRolePermissions}>
                        <BsLock
                            style={{
                                color: "gray",
                                background: "unset",
                                margin: "0 0 0 0px"
                            }}
                            className={"Icon-Decline"}/>
                    </button>}
                {permissions && ((permissions["web-for-user-management"] && permissions["web-for-user-management"].includes("updateRole")) || permissions.isAdmin) &&
                    <button style={{border: "none", background: "unset", float: "right"}} data-itemid={item.id}
                            onClick={onEdit}>
                        <BsPencilSquare
                            style={{
                                color: "gray",
                                background: "unset",
                                margin: "0 0 0 0px"
                            }}
                            className={"Icon-Decline"}/>
                    </button>}
            </td>
        </tr>
    );
})