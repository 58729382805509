// @flow
import * as React from 'react';
import {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {makeStyles} from '@mui/styles';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from "axios";
import AllPagesPDFViewer from "../components/AllPagesPDFViewer";
import useApi from "../hooks/useApi";
import "../components/Main.css"
import {Spinner} from "react-bootstrap";
import {getBillDocData, getBillDocs} from "../services/API/damageBill.service";
import {DocumentsTranslations} from "../translations/Documents/DocumentsTranslations";
import {Button} from "react-bootstrap";
import {MdFileDownload} from "react-icons/md";
import DocumentService from "../services/DocumentService";
import {TransformWrapper, TransformComponent, useControls} from "react-zoom-pan-pinch";
import zoomInSvg from '../assets/images/ZoomIn.svg';
import zoomOutSvg from '../assets/images/ZoomOut.svg';
import zoomToFitSvg from '../assets/images/ZoomToFit.svg';
import toast from "react-hot-toast";


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        // flexBasis: '60%',
        flexShrink: 0,
        textAlign: "left"
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    zoomButtons: {
        background: '#e5ebf4',
        border: '1px solid #9da7b7',
        borderRadius: '10px',
        padding: '4px 7px',
        '&:hover': {
            background: '#ccd2dc',
        },
    },
    zoomWrapper: {
        position: 'absolute',
        right: 16,
        zIndex: 1,
        display: 'flex',
        gap: '3px',
        padding: '5px'
    }
}));

const Controls = () => {
    const {zoomIn, zoomOut, resetTransform} = useControls();
    const classes = useStyles();
    return (
        <div className={classes.zoomWrapper}>
            <button className={classes.zoomButtons} onClick={() => zoomIn()}>
                <img src={zoomInSvg} width={20} height={22} alt={'Zoom In'}/>
            </button>
            <button className={classes.zoomButtons} onClick={() => zoomOut()}>
                <img src={zoomOutSvg} width={20} height={22} alt={'Zoom Out'}/>
            </button>
            <button className={classes.zoomButtons} onClick={() => resetTransform()}>
                <img src={zoomToFitSvg} width={20} height={22} alt={'Reset'}/>
            </button>
        </div>
    );
};


export function Documents() {
    const {billId: billIdParam} = useParams();
    const [documentsList, setDocumentsList] = useState([]);
    const {data, request, error} = useApi(getBillDocs, 'damagebills')
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);
    const [loadingFiles, setLoadingFiles] = useState(false);
    const [scale, setScale] = useState(1);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    useEffect(() => {
        if (documentsList.length > 0) setExpanded('0')
    }, [documentsList])

    useEffect(() => {
        if (error || !data || !data._embedded) return;
        const docs = data._embedded.records;
        const l = docs.map(r => {
            try {
                return getBillDocData(r.hash);
            } catch (e) {
                console.log(e);
            }});
        setLoadingFiles(true);
        axios.all(l).then(responses => {
            setLoadingFiles(false);
            const base64Files = responses.map(({data}) => data.file)
            setDocumentsList(base64Files.map((file, index) => {
                return {
                    ...docs[index],
                    file
                }
            }))
        });
    }, [error, data])

    useEffect(() => {
        request({billIdParam});
    }, [billIdParam, request]);

    return (

        <div className={"Documents"}>
            <div className="row">
                <div className="col-md-12">
                    <h1>{DocumentsTranslations.de.documents_page_title}: {billIdParam}</h1>

                    {loadingFiles && <Spinner animation="border" role="status">
                        <span style={{visibility: 'hidden'}}>Loading...</span>
                    </Spinner>}
                    {
                        documentsList.map((item, index) => {
                                console.log(item);
                                return (
                                    <Accordion expanded={expanded === `${index}`} onChange={handleChange(`${index}`)}
                                               key={item.id.toString()} style={{backgroundColor: "transparent"}}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon/>}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                            style={{backgroundColor: "white"}}
                                        >
                                            <div style={{display: 'flex', width: '100%', alignItems: 'center', gap: 20}}>
                                                <Typography
                                                    className={classes.heading}>{DocumentsTranslations.de.label_document_name}: &nbsp;
                                                    <strong>{item.document_name}</strong></Typography>

                                                <Button variant="outline-primary"
                                                        size={'sm'}
                                                        style={{
                                                            textTransform: 'capitalize'
                                                        }
                                                        }
                                                        onClick={async (e) => {
                                                            e.stopPropagation();
                                                            try {
                                                                const {data} = await getBillDocData(item.hash, true);
                                                                if (data) DocumentService.requestDownload(data?.file, item.document_name);
                                                            } catch (e) {
                                                                toast.error(e?.response?.data?.message || e?.message);
                                                            }
                                                        }}>
                                                    <MdFileDownload
                                                        style={{
                                                            cursor: 'pointer',
                                                            width: "20.2px",
                                                            height: "20.2px",
                                                            objectFit: "contain",
                                                        }}/>
                                                    Download
                                                </Button>
                                            </div>

                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <TransformWrapper
                                                centerOnInit
                                                initialScale={scale}
                                                panning={{
                                                    disabled: scale === 1,
                                                    velocityDisabled: true
                                                }} // Don't pan when the user didn't zoom into the child
                                                alignmentAnimation={{sizeX: 20, sizeY: 20, velocityAlignmentTime: 50}}
                                                onZoom={(ref) => setScale(ref.state.scale)}
                                                wheel={{activationKeys: ['Control']}}>
                                                <Controls/>
                                                <TransformComponent>
                                                    {/*<Typography component={'span'}>*/}
                                                    {item.extension === 'png' || item.extension === 'jpg' || item.extension === 'jpeg'
                                                        ?
                                                        <img src={item.file} style={{"maxWidth": "100%"}} alt={'document'}/>
                                                        :
                                                        item.extension === 'pdf' ?
                                                            // <PDFPreview file={item.file}/>
                                                            <AllPagesPDFViewer pdfFile={item.file}/>
                                                            :
                                                            ''
                                                    }
                                                    {/*</Typography>*/}
                                                </TransformComponent>
                                            </TransformWrapper>
                                        </AccordionDetails>
                                    </Accordion>
                                )
                            }
                        )
                    }
                </div>
            </div>
        </div>
    );
}
