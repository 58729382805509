// @flow
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Bill} from "../../components/BillContext";
import {Link} from "react-router-dom";
import {useTablePaginator} from "./useTablePaginator";
import {Button, OverlayTrigger, Tooltip} from "react-bootstrap";
import toast, {Toaster} from "react-hot-toast";
import InView from "../../components/InView";
import {getReadForPaymentBills, sendBillsForPayment} from "../../services/API/damageBills.service";
import img from '../../assets/images/attentionIcon.png';
import dopInfoImg from "../../assets/images/infoIcon.svg";
import './pay_table.css'
import {useSelector} from "react-redux";
import {getDuplicatesInfo} from "../../services/API/damageBill.service";
import useApi from "../../hooks/useApi";
import Modal from "react-modal";
import {LoadingSpinner} from "../../components/general/LoadingSpinner";
import dateFormat from "dateformat";

const payoutInconsistencies = {
    1: 'ooh, Outside office hours ',
    10: 'K, Kulanz ',
    20: 'dop, Doppelzahlung',
    21: 'dop, Doppelzahlung',
    22: 'dop, Doppelzahlung',
    30: 'AuZa, AuslandsZahlung',
    40: 'MwSt, Zahlung ohne Mehrwertsteuer',
    50: 'MwSt, Zahlung ohne Mehrwertsteuer',
    51: 'MwSt, Zahlung ohne Mehrwertsteuer',
    52: 'MwSt, Zahlung ohne Mehrwertsteuer',
    60: 'Bauteil, Es ist nur ein Bauteil zugeordnet',
}

const inconsistenciesText = {
    20: 'Schadenfall bereits erstattet für Vertrag',
    21: 'Gleicher Rechnungsbetrag bereits erstattet für Vertrag',
    22: 'Gleiche Rechnungsnummer wurde bereits verwendet für Zahlungsempfänger'
}

const formatIban = (inputString: string) => {
    let newString = inputString.toUpperCase();
    newString = newString.replace(/[^\dA-Z]/g, '');
    const reg = new RegExp('.{' + 4 + '}', 'g');
    return newString
        .replace(reg, function (a) {
            return a + ' ';
        })
        .replace(/[^0-9A-Z]+$/, '');
};

function AuthBillItem({
                          bill,
                          isChecked,
                          onToggle,
                      }: { bill: Bill & {
        "domestic_currency": string;
        "foreign_currency": string;
        "rate": string;
    }, isChecked: boolean, onToggle: () => void }) {
    const [inconsistencies, setInconsistencies] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const uid = useSelector(state => state.auth.loggedUser.uid);
    const {data, request, error, loading, canceled} = useApi(getDuplicatesInfo);
    const [showDopInfo, setShowDopInfo] = useState(true);

    const toggleModal = useCallback(() => {
        setIsOpen(prevOpen => !prevOpen);
    }, [])


    useEffect(() => {
        if (canceled) toast.error('Request to service damagebills was canceled, time exceeded', 'damagebills');
    }, [canceled]);

    useEffect(() => {
        if (error) {
            toast.error('Die gefundenen Duplikate sind älter als zwei Jahre.');
            setIsOpen(false);
            setShowDopInfo(false);
        }
    }, [error]);


    useEffect(() => {
        if (bill?.payout_inconsistencies) {
            const inc = bill?.payout_inconsistencies.split(',').map((i) => {if (i === '20' || i === '21' || i === '22') return `${payoutInconsistencies[i]},dop`;
                else if (i === '40' || i === '50' || i === '51' || i === '52') return `${payoutInconsistencies[i]},MwSt`;
                else return `${payoutInconsistencies[i]}, `});
            const newArray = [...new Set(inc)];
            setInconsistencies(newArray);
        }
    }, [bill?.payout_inconsistencies]);

    const handleModalOpen = () => {
        request({id: bill?.damage_bill_id});
        setIsOpen(true);
    }
    const permissions = JSON.parse(localStorage.getItem('user-permissions'));

    return (<>
        {permissions && ((permissions["web-for-damage-bill-management"] && permissions["web-for-damage-bill-management"].includes("createSepaFilesForSelectedBills")) || permissions.isAdmin) &&
            <td>
            {uid!==bill?.authorized_by_user_id && <input type={"checkbox"} checked={isChecked} className={'ml-3'}
                   disabled={!(bill.billed_by_car_dealer_id && bill.car_dealer_name && bill.shop_bank_account_id && bill.bank_account_data && bill.iban && bill.bic) || bill.total_after_deduction === '0.00' || bill.total_after_taxes === '0.00'}
                   onChange={onToggle.bind(this, bill.damage_bill_id, bill.payout_inconsistencies)}/>}
        </td>}
        <td>{inconsistencies && inconsistencies.map((inc) => {
            return <div><OverlayTrigger
                delay={{ hide: 450, show: 300 }}
                overlay={(props) => (
                    <Tooltip {...props}>
                        {`${inc ? inc.split(',')[1] : ''}`}
                    </Tooltip>
                )}
                placement="right"
            >
                {<div key={inc} style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: '10px'}}>
                    {inc.split(',')[2] === 'dop' && showDopInfo &&
                        permissions && ((permissions["web-for-damage-bill-management"] && permissions["web-for-damage-bill-management"].includes("checkDuplicatesInfo")) || permissions.isAdmin) &&
                        <button onClick={handleModalOpen} style={{border: 'none', background: 'none'}}>
                        <img src={dopInfoImg} alt={'Dop info'} width={20} height={20} /></button>}
                    {`${inc ? inc.split(',')[2] === 'dop' && !showDopInfo ? '' : inc.split(',')[0] : ''}`}</div>}
            </OverlayTrigger></div>
        })}
            {<div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: '10px'}}>{(bill.contract_check===1 || bill.contract_check===2) && 'E/C'}</div>}
        </td>

        <td>{bill.damage_bill_id}</td>
        <td>{bill.damage_number}</td>
        <td>{bill.prepared_for_payment_date}</td>
        <td>{bill.prepared_for_payment_by_user}</td>
        <td>{bill.car_dealer_name}</td>
        <td>{bill.bill_number}</td>
        <td>{'-'}</td>
        <td>{formatIban(bill.iban)}</td>
        <td>{bill.bic}</td>
        <td>{bill.bank_name}</td>
        <td className={'text-right pr-2'}>{bill.foreign_currency} {bill.total_after_deduction}</td>
        <td className={'text-right pr-5'}>{bill.foreign_currency} {bill.total_after_taxes}</td>
        <td>{bill.is_tax_included ? 'Ja' : 'Nein'}</td>
        {permissions && ((permissions["web-for-damage-bill-management"] && permissions["web-for-damage-bill-management"].includes("getSingleDamagebill")) || permissions.isAdmin) &&
            <td>
            <Link to={`/bills/${bill.damage_bill_id}`} target={'_blank'}>
                <i className="material-icons-round">open_in_new</i>
            </Link>
        </td>}
        <Modal isOpen={isOpen}
               onRequestClose={toggleModal}
               overlayClassName={"modal-overlay"}
               className={"modal-wide"}
               appElement={document.getElementsByTagName('body')}
               contentLabel="Doppelzahlung"
        >
            <div className={"form-group"}>
                {loading ? <div style={{display: 'flex', justifyContent: 'center'}}><LoadingSpinner isSpinning={loading}/></div> :
                    data && data.code === 200 && data.data && error === '' && <table style={{width: '100%'}}>
                    <thead>
                    <tr>
                        <th>Kriterien</th>
                        <th style={{paddingRight: '10px'}}>Schadenfallnummer</th>
                        <th style={{paddingRight: '10px'}}>Rechnungsnummer</th>
                        <th style={{paddingRight: '10px'}}>Rechnungsdatum</th>
                        <th style={{paddingRight: '10px'}}>Zahlungsempfänger</th>
                        <th style={{paddingRight: '10px'}}>Kilometerstand bei Schadeneintritt</th>
                        <th>Bauteilname</th>
                        <th style={{textAlign: 'center'}}>Givit Link</th>
                        <th style={{textAlign: 'center'}}>Link zur Rechnung</th>
                    </tr>
                    </thead>
                    <tbody>
                    {data && data.data?.curent_claim_invoice_data &&
                        <tr className={"Table-Row someClass"} id={"damage-bill-items"}>
                            <td>Aktuelle Rechnung</td>
                            <td>{data.data?.curent_claim_invoice_data?.claim_number}</td>
                            <td>{data.data?.curent_claim_invoice_data?.invoice_number}</td>
                            <td>{data.data?.curent_claim_invoice_data?.invoice_date?.date ? dateFormat(data.data?.curent_claim_invoice_data.invoice_date.date, 'dd.mm.yyyy h:mm TT') : '-'}</td>
                            <td>{data.data?.curent_claim_invoice_data.payment_receiver?.name || ''}</td>
                            <td>{data.data?.curent_claim_invoice_data.mileage}</td>
                            <td>{data.data?.curent_claim_invoice_data.parts?.map(part => part?.teilbez).join(', ')}</td>
                            <td style={{textAlign: 'center'}}>{data.data?.curent_claim_invoice_data.givitLink &&
                                <Link to={{ pathname: data.data?.curent_claim_invoice_data.givitLink}} target={'_blank'}>GIVIT</Link>}</td>
                            <td style={{textAlign: 'center'}}>
                                {data.data?.curent_claim_invoice_data?.damage_bill_id && <Link to={`/bills/${data.data?.curent_claim_invoice_data.damage_bill_id}`} target={'_blank'}>
                                    <i className="material-icons-round">open_in_new</i>
                                </Link>}
                            </td>
                        </tr>}
                    {data && data.data && Object.entries(data?.data?.inconsistencies).map(inc => {
                        return <>{inconsistenciesText[inc[0]] && <tr className={"Table-Row someClass"} id={"damage-bill-items"}>
                            <td colSpan={9} style={{fontWeight: 'bold', textAlign: 'center'}}>{inconsistenciesText[inc[0]]}:</td>
                        </tr>}
                        {inc[1].map((item, index) => {
                            return <tr className={"Table-Row someClass"} id={"damage-bill-items"} key={`${index}-${inc[0]}`}>
                                <td>Dublikat {index+1}</td>
                                <td>{item?.schnr}</td>
                                <td>{item?.belnr}</td>
                                <td>{item?.scabrand?.date ? dateFormat(item?.scabrand?.date, 'dd.mm.yyyy h:mm TT') : '-'}</td>
                                <td>{item?.provider?.name || ''}</td>
                                <td>{item?.mileage}</td>
                                <td>{item?.spare_parts?.map(part => part?.teilbez).join(', ')}</td>
                                <td style={{textAlign: 'center'}}>{item?.givitLink &&
                                    <Link to={{ pathname: item?.givitLink}} target={'_blank'}>GIVIT</Link>}</td>
                                <td style={{textAlign: 'center'}}>{item?.damage_bill_id &&
                                    <Link to={`/bills/${item?.damage_bill_id}`} target={'_blank'}>
                                        <i className="material-icons-round">open_in_new</i>
                                    </Link>}</td>
                            </tr>
                    })}</>})}
                    </tbody>
                </table>}
            </div>
            <div className={"form-buttons-wrapper"}>
                <button onClick={toggleModal} className={"btn btn-danger form-buttons"}>Abbrechen</button>
            </div>
        </Modal>
    </>)
}

const AuthBillItemMemo = React.memo(AuthBillItem)

const getWithTruthyValues = (obj) => Object.entries(obj).filter(([, v]) => v);

function PayPage() {
    const [, hasError, billsPaginated, hasMore, total, refresh, nextPage] = useTablePaginator(getReadForPaymentBills, 'damagebills');
    const [isMasterChecked, setMasterChecked] = useState<boolean>(false);
    const [selectedBills, setSelectedBills] = useState({});
    const [selectedBillsInconsistencies, setSelectedBillsInconsistencies] = useState({});
    const permissions = JSON.parse(localStorage.getItem('user-permissions'));
    const canCreateSepaFile = permissions && ((permissions["web-for-damage-bill-management"] && permissions["web-for-damage-bill-management"].includes("createSepaFilesForSelectedBills")) || permissions.isAdmin);

    const onInView = useCallback(() => {
        if (hasMore && !hasError) nextPage()
    }, [hasMore, hasError, nextPage]);

    const selectedBillsArr = useMemo(() => {
        return getWithTruthyValues(selectedBills).map(([k]) => k)
    }, [selectedBills]);

    const selectedBillsInconsistenciesArr = useMemo(() => {
        return getWithTruthyValues(selectedBillsInconsistencies).map(([k]) => k);
    }, [selectedBillsInconsistencies]);

    const toggleBillForPayment = useCallback((billId, payout_inconsistencies) => {
        function getNewSelected(obj) {
            return ({
                ...obj, [billId]: !obj[billId]
            })
        }

        if (payout_inconsistencies) {
            setSelectedBillsInconsistencies(getNewSelected);
        } else {
            setMasterChecked(false);
            setSelectedBills(getNewSelected);
        }
    }, [selectedBills, billsPaginated])

    const sendToPayment = async () => {
        await toast.promise(sendBillsForPayment(selectedBillsArr, isMasterChecked, selectedBillsInconsistenciesArr),
            {
                loading: 'Sending',
                success: () => {
                    setSelectedBills({});
                    setSelectedBillsInconsistencies({});
                    refresh();
                    return `Successfully sent ${selectedBillsArr.length + selectedBillsInconsistenciesArr.length} ${selectedBillsArr.length > 1 || selectedBillsInconsistenciesArr.length > 1 ? 'items' : 'item'}`
                },
                error: (err) => {if(err.response?.data?.code===503) toast.error('Service sepa is currently unavailable'); else return err.response?.data?.message || `This just happened: ${err.response.data.code}`},
            }
        ).then(console.log)
            .catch((e) => console.log(e.message))
    }


    const handleMasterCheckbox = (e) => {
        setMasterChecked(e.target.checked)
        if (e.target.checked) {
            let obj = {}
            billsPaginated.forEach((bill) => {
                if (bill.billed_by_car_dealer_id && bill.car_dealer_name && bill.shop_bank_account_id && bill.bank_account_data && bill.iban && bill.bic && !bill.payout_inconsistencies && bill.total_after_deduction !== '0.00' && bill.total_after_taxes !== '0.00' && bill.gvv_contract_chk===0) return obj[bill.damage_bill_id] = (bill.billed_by_car_dealer_id && bill.car_dealer_name && bill.shop_bank_account_id && bill.bank_account_data)
            })
            setSelectedBills(obj)
        } else {
            setSelectedBills({})
        }
    }


    return permissions && ((permissions["web-for-damage-bill-management"] && permissions["web-for-damage-bill-management"].includes("getAllDamageBillsPreparedForPayment")) || permissions.isAdmin) ?
        <>
        <div className={"payment-page"}>
            <Toaster/>
            <div style={{
                width: 'calc(100% - 2rem)',
                display: 'flex',
                flexDirection: 'column',
                margin: '1rem',
                maxHeight: '69.5vh',
                border: '1px solid rgba(0,0,0,.12)',
                borderRadius: '11px',
                overflow: 'auto'
            }}>

                <table>
                    <thead>
                    <tr>
                        {canCreateSepaFile && <th><input type={'checkbox'} checked={isMasterChecked} onChange={handleMasterCheckbox}/></th>}
                        <th style={{textAlign: 'center'}}><img src={img} width={25} height={22} alt={'Attention'}/></th>
                        <th>GA Nr.</th>
                        <th>SchNr</th>
                        <th>Abr.Dat U. Uhrzeit</th>
                        <th>Abr.durch</th>
                        <th>Empfanger</th>
                        <th>Re Nr.</th>
                        <th>Hdl Nr.</th>
                        <th>IBAN</th>
                        <th>BIC</th>
                        <th>Bankname</th>
                        <th>Betrag netto</th>
                        <th>Betrag brutto</th>
                        <th>MwSt.</th>
                        {permissions && ((permissions["web-for-damage-bill-management"] && permissions["web-for-damage-bill-management"].includes("getSingleDamagebill")) || permissions.isAdmin) &&
                            <th>Aktion</th>}
                    </tr>
                    </thead>
                    <tbody>
                    {billsPaginated.map((bill, index) => {
                        return index !== Math.round(billsPaginated.length / 1.2) ?
                            <tr style={{background: (bill.payout_inconsistencies || !bill.iban || !bill.bic || bill.total_after_deduction === '0.00' || bill.total_after_taxes === '0.00'  || bill.gvv_contract_chk===1 || bill.contract_check===1 || bill.contract_check===2) && '#F7BEAA'}}
                                key={bill.damage_bill_id}>
                                <AuthBillItemMemo
                                    key={bill.damage_bill_id} onToggle={toggleBillForPayment}
                                    isChecked={bill.payout_inconsistencies ? !!selectedBillsInconsistencies[bill.damage_bill_id] : !!selectedBills[bill.damage_bill_id]}
                                    bill={bill}/>
                            </tr> :
                            <InView
                                style={{background: (bill.payout_inconsistencies || !bill.iban || !bill.bic || bill.total_after_deduction === '0.00' || bill.total_after_taxes === '0.00' || bill.gvv_contract_chk===1 || bill.contract_check===1 || bill.contract_check===2) && '#F7BEAA'}}
                                onceOnIntersect={onInView} as={'tr'} key={bill.damage_bill_id}>
                                <AuthBillItemMemo
                                    key={bill.damage_bill_id} onToggle={toggleBillForPayment}
                                    isChecked={bill.payout_inconsistencies ? !!selectedBillsInconsistencies[bill.damage_bill_id] : !!selectedBills[bill.damage_bill_id]}
                                    bill={bill}/>
                            </InView>
                    })}
                    </tbody>
                </table>
                <div className={'table-footer'}>
                    <div className={'table-footer-inner'}>Eregbnisse 1 - {billsPaginated.length} of {total}</div>
                </div>

            </div>

            {canCreateSepaFile && <Button onClick={sendToPayment}
                    disabled={selectedBillsArr.length === 0 && selectedBillsInconsistenciesArr.length === 0}>
                <label className={"label"} style={{height: "13px"}}>Zur Zahlungen senden</label>
                </Button>}

        </div>
    </> : <div className={"payment-page"}>
            <h1>Schäden zur Zahlung freigeben</h1>
            <div>Sie dürfen diesen Inhalt nicht sehen</div>
        </div>;
}


export default PayPage;