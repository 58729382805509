// @flow
import * as React from 'react';
import {DamageBill} from "../components/bills/DamageBill";
import {BillsList} from "../components/contract/BillsList";
import {Route, Switch, useRouteMatch} from "react-router-dom";
import {Toaster} from "react-hot-toast";
import {useSelector} from "react-redux";
import rgisLogo from "../assets/images/RealGrantLogo.svg";
import {logoStyle} from "./BlankPage";

/**
 * @deprecated replaced by SearchPagination slice
 * @type {React$Context<{}>}
 */
export const BillsDocumentContext = React.createContext({})

export function BillDocument() {
    const {path} = useRouteMatch();
    const {showSidebar} = useSelector(state => state.searchPagination);
    const styleBillForm ={
        minWidth: showSidebar ? 'calc(100% - 520px)': 'unset',
        maxWidth: showSidebar ? 'calc(100% - 320px)': 'unset',
        width: showSidebar ? '95%': '94%'
    }

    return (
        <div className={"Bills2"}>
            <div className={"row"} style={{height: "100%", flexWrap: "nowrap", justifyContent: "unset", gap: '12px'}}>
                <Toaster/>
                <BillsList/>
                <div className={"BillForm"} style={{...styleBillForm}}>
                    <div className={"Frame-Damages"}>
                        {/*
                        *   Nested route for managing bills
                        */}
                        <Switch>
                            <Route exact path={`${path}`}>
                                <img style={logoStyle} alt={'Real Garant logo'} src={rgisLogo}/>
                                <p>Bitte wählen Sie eine Rechnung aus der Liste aus</p>
                            </Route>
                            <Route path={`${path}/:billId`}>
                                <DamageBill/>
                            </Route>
                        </Switch>
                    </div>

                </div>
            </div>
        </div>

    );
}
