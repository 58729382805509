import {BsPencilSquare, BsFillPersonLinesFill} from "react-icons/bs";
import * as React from "react";

export const ListRow = React.forwardRef(({index, item, onEdit, onEditRoles}, ref) => {
    const permissions = JSON.parse(localStorage.getItem('user-permissions'));
    return (
        <tr ref={ref} className={"Table-Row someClass"} id={"damage-bill-items-" + index} style={{height: '50px'}}>
            <td style={{color: item.anmtage === -1 && item.kwtage === -1 ? 'red' : '#212529'}}>{item.first_name + ' ' + item.last_name}{(item.anmtage === -1 && item.kwtage === -1) && ' - DEAKTIVIERT'}</td>
            <td>{item.email}</td>
            <td>{item.rolesNames[0]}</td>
            <td className={"Last-Table-Cell"} style={{width: '70px'}}>
                {permissions && ((permissions["web-for-user-management"] && permissions["web-for-user-management"].includes("getAllRoles") && permissions["web-for-user-management"].includes("getRolesForUser")) || permissions.isAdmin) &&
                    item.anmtage !== -1 && item.kwtage !== -1 &&
                    <button style={{border: "none", background: "unset", float: "right"}} data-itemid={item.id}
                            title={"Rollen"}
                            onClick={onEditRoles}>
                        <BsFillPersonLinesFill
                            style={{
                                color: "gray",
                                background: "unset",
                                margin: "0 0 0 0px"
                            }}
                            className={"Icon-Decline"}/>
                    </button>}
                {permissions && ((permissions["web-for-user-management"] && permissions["web-for-user-management"].includes("updateUser")) || permissions.isAdmin) &&
                    item.anmtage !== -1 && item.kwtage !== -1 &&
                    <button style={{border: "none", background: "unset", float: "right"}} data-itemid={item.id}
                            title={"Edit"}
                            onClick={onEdit}>
                        <BsPencilSquare
                            style={{
                                color: "gray",
                                background: "unset",
                                margin: "0 0 0 0px"
                            }}
                            className={"Icon-Decline"}/>
                    </button>}
            </td>
        </tr>
    );

});