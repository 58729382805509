// @flow
import {Form} from "react-bootstrap";
import {GeneralTranslations} from "../../translations/General/GeneralTranslations";
import {Input} from "reactstrap";


import * as React from 'react';
import {useCallback, useState, useRef} from "react";
import Autocomplete from "react-autocomplete";
import InView from "../InView";
import {searchTeamLead} from "../../services/API/teams.service";
import {useSearchPaginator} from "../contract/BillsList/useSearchPaginator";


const getItemValue = (item) => item.label + '->' + item.value;

function TeamLeadAutocomplete({value, label}) {

    // actually if carDealerSource should be 1 for car dealer and only 2 for external providers
    // but in all other cases we have this field disabled, so it is ok
    const [keyword, setKeyword] = useState(label || '');
    const [statuses] = useState([]);
    const [, , data, , nextPage] = useSearchPaginator(keyword, statuses, searchTeamLead, 'users');
    const [teamLeadId, setTeamLeadId] = useState(value || 0)

    const leads = (data || []).map(l => ({
        value: l.id, label: l.username
    }))

    const handleOnChange = useCallback(async (e) => setKeyword(e.target.value), [])


    const onSelect = useCallback((val) => {
        let result = val.split('->');
        let selectedCarDealerId = parseInt(result[1]);
        setKeyword(result[0])
        setTeamLeadId(selectedCarDealerId)
    }, [])

    function renderInput({...props}) {
        return <input style={{background: 'transparent'}} {...props}/>
    }

    function renderItem(item, isHighlighted) {
        return (<div key={item.value} style={{background: isHighlighted ? 'whitesmoke' : 'white', cursor: 'pointer'}}>
            {item.label}
        </div>);
    }

    // this way of rendering is not ideal
    // it is a temp fix until we start redesigning
    function renderMenu(items) {
        const menuStyle = {
            borderRadius: '3px',
            boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
            background: 'rgba(255, 255, 255, 0.9)',
            padding: '2px 0',
            fontSize: '90%',
            position: 'absolute',
            overflow: 'auto',
            width: '100%',
            height: 'auto',
            maxHeight: '300px',
            top: '35px',
            left: 0,
            zIndex: 1,
        }

        return <div id={'shop-autocomplete'} className={'shop-autocomplete'} style={{...menuStyle}}>
            {items.slice(0, items.length - 1).map(item => item)}
            {items && items?.length > 0 && <InView onceOnIntersect={nextPage}> {items[items.length - 1]}</InView>}

        </div>
    }

    return (<>
            <input type="hidden" name={'team_lead_user_id'} value={teamLeadId || ''}/>
            <Autocomplete
                value={keyword}
                items={leads}
                getItemValue={getItemValue}
                onChange={handleOnChange}
                onSelect={onSelect}
                renderMenu={renderMenu}
                wrapperStyle={{
                    position: 'relative'
                }}
                renderInput={(props) => renderInput({...props, className: 'form-control'})}
                renderItem={renderItem}/>

        </>

    );
}


export function TeamForm({fields, formSubmit, ...props}) {
    const userFormRef = useRef(null);

    function onUserFormSubmit(e) {
        e.preventDefault();
        const data = new FormData(userFormRef.current);
        formSubmit(data);
    }

    function updateItem(event) {
        // let tmpItem = userState;
        // tmpItem[event.target.name] = event.target.value;
        // setUserState({...tmpItem});
    }

    return (<Form id="userForm" ref={userFormRef} onSubmit={onUserFormSubmit}>
            <input type={"hidden"} name={"id"} defaultValue={fields.id}/>
            <div className={"row"}>
                <div className={"col-md-6"}>
                    <div className={"form-group"}>
                        Name: <input type={"text"} className={"form-control"} name={"name"}
                                     onChange={updateItem} defaultValue={fields.name || ''}/>
                    </div>
                </div>
                <div className={"col-md-6"}>
                    <div className={"form-group"}>
                        Abteilung:
                        <Input type="select"
                               name="department"
                               id="department"
                               defaultValue={fields.department}
                               style={{height: "32px", textTransform: 'capitalize'}}
                        >
                            <option value={-1}
                                    disabled>{GeneralTranslations.de.label_please} {GeneralTranslations.de.label_select}</option>

                            <option value={1}>Claims</option>
                            <option value={2}>Claims invoices</option>
                            <option value={3}>Contracts</option>
                        </Input>
                    </div>
                </div>

                <div className={"col-md-6"}>
                    <div className={"form-group"}>
                        Abteilungsleiter: <TeamLeadAutocomplete value={fields.team_lead_user_id} label={fields.fullname}/>
                    </div>
                </div>
            </div>

            <button className={"btn btn-primary form-buttons"}>Speichern</button>
            <button onClick={props.toggleModal} className={"btn btn-danger form-buttons"}>Abbrechen</button>

        </Form>

    );
}
