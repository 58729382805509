// @flow
import React, {useRef, useState} from "react";
import toast from 'react-hot-toast';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Alert from 'react-bootstrap/Alert';
import {Input} from "reactstrap";
import {useForm} from "react-hook-form";
import {createBill} from "../../../services/API/damageBill.service";
import {useDispatch, useSelector} from "react-redux";
import {updateFilters} from "../../../features/SearchPagination/searchPagination.slice";
import Select from "react-select";
import {useCountries} from "../../../hooks/useCountries";


const documentTypes: Array<{ value: number, label: string }> = [{
    value: 0, label: 'Other'
}, {
    value: 1, label: 'Invoice'
}]

const FileErrors = {
    1: 'Die hochgeladene Datei überschreitet die Richtlinie upload_max_filesize in php.ini',// 'The uploaded file exceeds the upload_max_filesize directive in php.ini',
    2: 'Die hochgeladene Datei überschreitet die im HTML-Formular angegebene Richtlinie MAX_FILE_SIZE', // 'The uploaded file exceeds the MAX_FILE_SIZE directive that was specified in the HTML form',
    3: 'Die hochgeladene Datei wurde nur teilweise hochgeladen', // 'The uploaded file was only partially uploaded',
    4: 'Es wurde keine Datei hochgeladen', //'No file was uploaded',
    6: 'Fehlen eines temporären Ordners', //'Missing a temporary folder',
    7: 'Die Datei konnte nicht auf die Festplatte geschrieben werden.', //'Failed to write file to disk.',
    8: 'Eine PHP-Erweiterung hat den Datei-Upload gestoppt.',//'A PHP extension stopped the file upload.',
    21: 'Die Datei hat eine falsche Erweiterung, verfügbare Erweiterungen sind: jpg, jpeg, png, pdf',//'File has an incorrect extension, available extensions are: jpg, jpeg, png, pdf',
    22: 'Maximale Dateigröße überschritten. 20 MB begrenzen',//'Filesize limit exceeded. Limit 20MB',
    100: 'Datei ist leer' //'File is empty'
}

function MultipleBillsUploader() {

    const dispatch = useDispatch();
    const [showMessage, setShowMessage] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [show, setShow] = useState(false);
    const [isSaveDisabled, setSaveDisabled] = useState<boolean>(false);
    const [selectedDocumentType, setSelectedDocumentType] = useState<number>(documentTypes[1].value);
    const {handleSubmit} = useForm();
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [selectedWorkingCountryId, setWorkingCountryId] = useState<number | null>(null);

    const [errors, setErrors] = useState<{ error: number, filename: string }[]>([]);

    const handleShow = () => {
        setShow(true);
        setShowMessage(false);
    }
    const handleClose = () => {
        setErrors([]);
        setWorkingCountryId(null);
        setSelectedFiles([])
        setShow(false);
    }

    const {data} = useCountries();

    const usersWorkingCountryIds = useSelector((state) => state.auth.loggedUser.user.working_countries)

    const allOptions = data && data._embedded ? (data._embedded?.records?.map(({
                                                               country_name, country_id
                                                           }) => ({
        label: country_name, value: country_id
    }))) : []

    const options = usersWorkingCountryIds ? allOptions.filter(({value}) => usersWorkingCountryIds.includes(value)) : []


    function onChange(e) {
        setSelectedFiles(e.target.files);
        setShowMessage(false);
        setSaveDisabled(false);
    }

    const resetFileInputValue = () => {
        setSelectedFiles([]);
        fileInputRef.current.value = "";
    };

    const onSubmit = () => {
        if (selectedWorkingCountryId === null) {
            toast.error('Land nicht ausgewählt')
            return;
        }
        if (selectedFiles.length === 0) {
            toast.error('At least one document is required')
            return;
        } else {
            if (isSaveDisabled) return;
            setSaveDisabled(true);
        }

        const fd = new FormData();
        Object.entries(selectedFiles).forEach(([key, value]) => {
            fd.append(`documents[${key}][file]`, value);
            fd.append(`documents[${key}][document_type]`, selectedDocumentType);
        })
        fd.append('country_id', selectedWorkingCountryId);


        return toast.promise(createBill(fd), {
            loading: 'Sending...',
            success: (response) => {
                setErrors(response.data?.errors || [])

                if (response.data.data?.length > 0) {
                    dispatch(updateFilters({}))
                    resetFileInputValue();
                    setSaveDisabled(false);
                }

                if (response.data.errors.length > 0) {
                    return 'Einige Dateien wurden nicht hochgeladen'//'Some files were not uploaded'
                }

                return `Erfolgreich ${response.data?.data?.length || 0} neue Rechnungen erstellt` //`Successfully created ${response.data.data.length} new bills`;
            }, error: (err) => {
                resetFileInputValue();
                setSaveDisabled(false)
                if(err?.response?.data?.code===503) toast.error('User microservice is currently unavailable');
                return `${err?.response?.data?.message || 'Oops'}`;
            }
        }, {
            success: {
                duration: 5000,
            },
        });
    }

    function handleChange(e) {
        setSelectedDocumentType(e.currentTarget.value);
    }

    return (
        <div style={{
            alignSelf: "flex-end",
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            padding: '0 1rem 1rem 1rem'
        }}>
            <Button className={"mainButton"} variant="primary" onClick={handleShow}>
                <label className="label" style={{height: "13px"}}>Mehrere Rechnungen hochladen</label>
            </Button>
            <Modal className={"ModalStyle"} show={show} onHide={handleClose}>
                <form id={"myForm"} onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Mehrere Rechnungen hochladen</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{
                            paddingLeft: '1rem'
                        }}>
                            <input ref={fileInputRef} multiple type={"file"}
                                   accept="image/*, .pdf"
                                   onChange={(e) => onChange(e)}/>
                            {showMessage ? <p style={{color: "red", marginLeft: "15px"}}>Invalid form data</p> : null}

                            <div className={''}>
                                <label style={{marginRight: "10px"}}>
                                    {
                                        `Typ des Dokuments:` //`Document type: `
                                    }
                                </label>
                                <Input type="select"
                                       disabled={false}
                                       bsSize={'sm'}
                                       onChange={handleChange}
                                       value={selectedDocumentType}
                                       style={{display: 'inline', width: '150px'}}>
                                    {
                                        documentTypes.map((t) => (
                                            <option key={t.label} value={t.value}>{t.label}</option>
                                        ))
                                    }
                                </Input>
                            </div>
                            <div className={"row"}>
                                    <div className={"col-md-6"}>
                                        <div className={"form-group"}>
                                            <label style={{marginRight: "10px"}}>
                                                {/*Country: */}
                                                Land:
                                            </label>
                                            {options.length ? (<Select
                                                onChange={(selectedItem) => setWorkingCountryId(selectedItem.value)}
                                                closeMenuOnSelect={true}
                                                options={options}
                                            />) : null}
                                        </div>
                                    </div>
                                </div>

                            {errors.length > 0 && <Alert variant={'warning'}>
                                <ul>
                                    {errors.map(({error, filename}) => <li
                                        key={filename}>{filename} - {FileErrors[error]}</li>)}
                                </ul>
                            </Alert>}
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            {/*Close*/}
                            Abbrechen
                        </Button>
                        <Button variant="primary" type={"submit"}>
                            {/*Upload*/}
                            Hochladen
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </div>
    );
}

export default React.memo(MultipleBillsUploader)
