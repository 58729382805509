import rgisLogo from "../assets/images/RealGrantLogo.svg";
import React from "react";

export const logoStyle = {
    width: '120px',
    marginBottom: '1.5rem',
    alignSelf: 'center'
}

function BlankPage() {
    return (
        <div className={"Frame-Damages"}>
            <img style={logoStyle} alt={'Real Garant logo'} src={rgisLogo}/>
            <p>Bitte wählen Sie eine Rechnung aus der Liste aus</p>
        </div>
    )
}

export default BlankPage;