import {AxiosResponse} from "axios";
import {client, headers} from "../../app/axiosClient";
import {carDealersUrl, customFiltersUrl, damageBillUrl} from "../ApiService";

export type Tenants = {
    "id": number,
    "tenant_name_description": string,
    "tenant_id": number
}

export type Employees = {
    id: number,
    user_id: number,
    first_name: string,
    last_name: string,
    is_deleted: boolean
}

export type CustomFilters = {
    id: number,
    user_id: number,
    title: string,
    mandnr: string,
    amount_from: string,
    amount_to: string,
    blocked_by_me: boolean,
    transactions_by_employee: string,
    is_active: boolean,
    is_deleted: boolean,
}

export type CustomFiltersFormInputs = {
    id?: number,
    title: string,
    mandnr: string,
    amount_from: string,
    amount_to: string,
    blocked_by_me: boolean,
    transactions_by_employee: string,
    is_active: boolean,
}

export function getCustomFilters(pageNumber?: number, cancelToken?: any): Promise<AxiosResponse> {
    return client.get(`${customFiltersUrl}`, {
        headers: {
            ...headers
        },
        params: {
            page: pageNumber
        },
        cancelToken
    })
}

export function getCustomFiltersWithSearch(queryString: string, pageNumber?: number, cancelToken?: any): Promise<AxiosResponse> {
    return client.get(`${customFiltersUrl}?filters[0][property]=title&filters[0][operator]=l&filters[0][value]=${queryString}`, {
        headers: {
            ...headers
        },
        params: {
            page: pageNumber
        },
        cancelToken
    })
}

export function deactivateAllFilters(): Promise<AxiosResponse> {
    return client.post(`${customFiltersUrl}/deactivateall`);
}

export function postCustomFilters(filters: CustomFiltersFormInputs,cancelToken?: any): Promise<AxiosResponse> {
    const fd = new FormData();
    Object.entries(filters).forEach(([k, v]) => {
        if (v) {
            fd.append(k, v);
        }
    });
    return client.post(`${customFiltersUrl}`, fd , {
        headers: {
            ...headers
        },
        cancelToken
    })
}

export function putCustomFilters(filters: CustomFiltersFormInputs, id: string | number, cancelToken?: any): Promise<AxiosResponse> {
    const fd = new FormData();
    Object.entries(filters).forEach(([k, v]) => {
        if (v) {
            fd.append(k, v);
        }
    });
    return client.put(`${customFiltersUrl}/${id}`, fd , {
        headers: {
            ...headers,
        },
        cancelToken
    })
}

export function deleteCustomFilter(id: string | number, cancelToken?: any): Promise<AxiosResponse> {
    return client.delete(`${customFiltersUrl}/${id}`, {
        headers: {
            ...headers,
        },
        cancelToken
    })
}

export function getAllEmployees(cancelToken?: any): Promise<AxiosResponse> {
    return client.get(`${damageBillUrl}/employees`, {
        headers: {
            ...headers
        },
        cancelToken
    })
}

export function getAllTenants(cancelToken?: any): Promise<AxiosResponse> {
    return client.get(`${carDealersUrl}/tenant-currencies`, {
        headers: {
            ...headers
        },
        cancelToken
    })
}
export function getActiveCustomFilterForUser(cancelToken?: any): Promise<AxiosResponse> {
    return client.get(`${customFiltersUrl}/current`, {
        headers: {
            ...headers
        },
        cancelToken
    })
}
// export function activateCustomFilter(id: number, cancelToken?: any): Promise<AxiosResponse> {
//
//     return client.post(`${customFiltersUrl}/customfilters/activate/${id}`, {
//         headers: {
//             ...headers,
//         }, cancelToken
//     })
// }

export function activateCustomFilter(id:number): Promise<AxiosResponse> {
    return client.put(`${customFiltersUrl}/activate/${id}`);
}