import * as React from "react";
import {useState} from "react";
import {useSelector} from "react-redux";

export function InputText({stateChanger, setFocusedId, dataTableItem, getNextFocusableField, canEditItems, ...props}) {
    const {isReadOnly} = useSelector(state => state.damageBill)
    const [state, setState] = useState({
        value: props['value'] || ''
    });

    // useEffect(() => {
    // if (state.value !== props['value']) {
    //     setState({
    //         value: props['value'] || ''
    //     })
    // }
    // }, [props])

    function handleOnChange(e) {
        setState({
            value: e.target.value
        });
        stateChanger(props['field'], e.target.value);
    }

    return (<input name={props['name']}
                              onKeyDown={(e) => {
                                  if (e.key === 'Enter') {
                                      e.preventDefault()
                                      setFocusedId(getNextFocusableField(dataTableItem))
                                  }
                              }}
                              data-table-item={`${dataTableItem[0]}${dataTableItem[1]}`}
                              value={state.value} className="form-control"
                              onChange={handleOnChange}
                              disabled={isReadOnly || !canEditItems}
                              {...props}
        />
    );
}
