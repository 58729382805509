// @flow
import React, {useEffect, useState} from "react";
import {Toaster} from "react-hot-toast";
import {Link} from "react-router-dom";
import PayPage from "../Pay";
import SepaPage from "../Sepa";
import EmailPage from "../Email";
import {useDispatch, useSelector} from "react-redux";
import {resetCarDealersForEmails} from "../../features/SearchPagination/searchPagination.slice";
import {RolesPage} from "../RolesPage";
import {UsersPage} from "../UsersPage";
import {useLocation} from "react-router";
import {logInUserAutomatically} from "../../features/Authentication/authSlice";

function AdminPage() {
    const permissions = JSON.parse(localStorage.getItem('user-permissions'));
    const activeTabFromStorage = sessionStorage.getItem('activeTab');
    const {loggedUser} = useSelector(state => state.auth);
    const [activeTab, setActiveTab]=useState(activeTabFromStorage || '');
    const [isHovered, setIsHovered]= useState(null);
    const dispatch= useDispatch();
    const location = useLocation();
    const token = new URLSearchParams(location.search).get('token');
    const page = new URLSearchParams(location.search).get('page');

    useEffect(() => {
        if (token) {
            dispatch(logInUserAutomatically({token: token.substring(0, token.length-3)}));
        }
    }, [token]);

    useEffect(() => {
        if (activeTabFromStorage) setActiveTab(activeTabFromStorage);
        else if (page && permissions) {
            if (page === 'roles' && ((permissions["web-for-user-management"] && permissions["web-for-user-management"].includes("getAllRoles")) || permissions.isAdmin)) {
                setActiveTab('showAllRoles');
                sessionStorage.setItem('activeTab', 'showAllRoles');
            }
            else if (page === 'users' && ((permissions["web-for-user-management"] && permissions["web-for-user-management"].includes("getAllUsers")) || permissions.isAdmin)) {
                setActiveTab('showAllUsers');
                sessionStorage.setItem('activeTab', 'showAllUsers');
            }
        }
        else if (permissions) {
            if ((permissions["web-for-damage-bill-management"] && permissions["web-for-damage-bill-management"].includes("getAllDamageBillsPreparedForPayment")) || permissions.isAdmin) {
                setActiveTab('showSettlement');
                sessionStorage.setItem('activeTab', 'showSettlement');
            }
            else if ((permissions["web-for-damage-bill-management"] && permissions["web-for-damage-bill-management"].includes("getAllSepaFiles")) || permissions.isAdmin) {
                setActiveTab('showSepaPaymentFile');
                sessionStorage.setItem('activeTab', 'showSepaPaymentFile');
            }
            else if ((permissions["web-for-car-dealer-management"] && permissions["web-for-car-dealer-management"].includes("updateCarDealerEmail")) || permissions.isAdmin) {
                setActiveTab('showChangeRecipient');
                sessionStorage.setItem('activeTab', 'showChangeRecipient');
            }
            else if ((permissions["web-for-user-management"] && permissions["web-for-user-management"].includes("getAllRoles")) || permissions.isAdmin) {
                setActiveTab('showAllRoles');
                sessionStorage.setItem('activeTab', 'showAllRoles');
            }
            else if ((permissions["web-for-user-management"] && permissions["web-for-user-management"].includes("getAllUsers")) || permissions.isAdmin) {
                setActiveTab('showAllUsers');
                sessionStorage.setItem('activeTab', 'showAllUsers');
            }
        } else setActiveTab('showSettlement');
    }, [loggedUser]);

    const handleTabClick = (tab: string)=>{
        if (activeTab === 'showChangeRecipient') dispatch(resetCarDealersForEmails());
        setActiveTab(tab);
        sessionStorage.setItem('activeTab', tab);
    }
    const handleMouseEnter = (tab: string) => {
        setIsHovered(tab);
    }
    const handleMouseLeave = () =>{
        setIsHovered(null);
    }
    const tabStyle = (tab:string) => {
        return {
            backgroundColor: activeTab === tab ? '#007bff' : isHovered ===tab ? '#007bff' : '#ffffff',
            color: activeTab === tab ? '#ffffff' : isHovered === tab ? '#ffffff' : '#007bff'
        }
    }

    return (<>
        <div className={"payment-page"}>
            <Toaster/>
            <div style={{position: 'absolute', left: 0, padding: '20px'}}><Link to="/bills">Zurück zur Hauptseite</Link></div>
            <h1 style={{marginBlock: '20px'}}>Administration</h1>
            <div className={'admin-tab-container'}>
                { permissions && ((permissions["web-for-damage-bill-management"] && permissions["web-for-damage-bill-management"].includes("getAllDamageBillsPreparedForPayment")) || permissions.isAdmin) && <div className={'admin-tab'} style={tabStyle('showSettlement')} onClick={() => handleTabClick('showSettlement')} onMouseEnter={()=>handleMouseEnter('showSettlement')} onMouseLeave={handleMouseLeave} >Abrechnungsgenehmigung</div>}
                { permissions && ((permissions["web-for-damage-bill-management"] && permissions["web-for-damage-bill-management"].includes("getAllSepaFiles")) || permissions.isAdmin) && <div className={'admin-tab'} style={tabStyle('showSepaPaymentFile')} onClick={() => handleTabClick('showSepaPaymentFile')} onMouseEnter={()=>handleMouseEnter('showSepaPaymentFile')} onMouseLeave={handleMouseLeave} >Sepa-Zahlungsdatei</div>}
                { permissions && ((permissions["web-for-car-dealer-management"] && permissions["web-for-car-dealer-management"].includes("updateCarDealerEmail") && permissions["web-for-car-dealer-management"].includes("getAllCarDealers")) || permissions.isAdmin) && <div className={'admin-tab'} style={tabStyle('showChangeRecipient')} onClick={() => handleTabClick('showChangeRecipient')} onMouseEnter={()=>handleMouseEnter('showChangeRecipient')} onMouseLeave={handleMouseLeave} >Empfänger-E-Mail-Adressen ändern</div>}
                { permissions && ((permissions["web-for-user-management"] && permissions["web-for-user-management"].includes("getAllRoles")) || permissions.isAdmin) && <div className={'admin-tab'} style={tabStyle('showAllRoles')} onClick={() => handleTabClick('showAllRoles')} onMouseEnter={()=>handleMouseEnter('showAllRoles')} onMouseLeave={handleMouseLeave} >Rollen</div>}
                { permissions && ((permissions["web-for-user-management"] && permissions["web-for-user-management"].includes("getAllUsers")) || permissions.isAdmin) && <div className={'admin-tab'} style={tabStyle('showAllUsers')} onClick={() => handleTabClick('showAllUsers')} onMouseEnter={()=>handleMouseEnter('showAllUsers')} onMouseLeave={handleMouseLeave} >Benutzer</div>}
            </div>
            <div style={{marginTop: '20px', width: '100%'}}>
                {
                    activeTab==='showSettlement' && <PayPage/>
                }
                {
                    activeTab==='showSepaPaymentFile' && <SepaPage/>
                }
                {
                    activeTab==='showChangeRecipient' && <EmailPage/>
                }
                {
                    activeTab==='showAllRoles' && <RolesPage/>
                }
                {
                    activeTab==='showAllUsers' && <UsersPage/>
                }
            </div>
        </div>
    </>)
}
export default AdminPage;