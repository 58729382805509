// @flow
import * as React from 'react';
import IncludeTaxToggle from "./IncludeTaxToggle";
import {ContactPerson} from "./ContactPerson";
import {ContactReason} from "./ContactReason";
import {ContactFrom} from "./ContactFrom";
import {BillNumber} from "./BillNumber";
import {Date} from "./Date";
import {TotalValue} from "./TotalValue";
import {ShopAutoComplete} from "./ShopAutoComplete";
import {FirstVat} from "./FirstVat";
import {KmInRepair} from "./KmInRepair";
import {RepairDate} from "./RepairDate";
import {AcceptanceDate} from "./AcceptanceDate";

import './form-bill-details.css';
import {AccountNumber} from "./AccountNumber";
import {useSelector} from "react-redux";
import SelectCarDealer from "./SelectCarDealer";
import BillEmailField from "./BillEmailField/BillEmailField";
import {useEffect, useState} from "react";

export function FormBillDetails2({showConvertedValue, hideConvertedValue}) {
    const permissions = JSON.parse(localStorage.getItem('user-permissions'));
    const {bill, errors: {formValidation}, errorsValidation, damage, validationsData} = useSelector(state => state.damageBill);

    const [acceptanceDate, setAcceptanceDate]= useState();
    const [repairDate,setRepairDate]= useState();
    const[kmStatusRepair, setKmStatusRepair]= useState();
    const [bankAccount, setBankAccount]= useState();

    useEffect(()=>{
        if (!validationsData) return;
        setAcceptanceDate(validationsData?.acceptance_date);
        setRepairDate(validationsData?.repair_date);
        setKmStatusRepair(validationsData?.km_status_repair);
        setBankAccount(validationsData?.bank_account);
    },[validationsData]);

    const acceptanceClass=(errorsValidation || !damage) ? '' : acceptanceDate ? 'validation_true' : 'validation_false';
    const repairClass=(errorsValidation || !damage) ? '' : repairDate ? 'validation_true' : 'validation_false';
    const kmClass=(errorsValidation || !damage) ? '' : kmStatusRepair ? 'validation_true' : 'validation_false';
    const bankAccountClass=(errorsValidation || !damage) ? '' : bankAccount ? 'validation_true' : 'validation_false';


    return (
        <>
            <div className={'form-bill-details-container'}>
                {permissions && ((permissions["web-for-damage-bill-management"] && permissions["web-for-damage-bill-management"].includes("getSingleDamagebill")) || permissions.isAdmin) &&
                    <> <SelectCarDealer errorMessage={formValidation?.carDealerSource}/>
                        <ShopAutoComplete name="billed_by_car_dealer_id"
                                          errorMessage={formValidation?.billed_by_car_dealer_id}/></>
                }
                {permissions && ((permissions["web-for-damage-bill-management"] && permissions["web-for-damage-bill-management"].includes("getSingleDamagebill")) || permissions.isAdmin) &&
                    <AccountNumber errorMessage={formValidation?.shop_bank_account_id} bankAccountValidation={bankAccountClass}/>}
                {permissions && ((permissions["web-for-damage-bill-management"] && permissions["web-for-damage-bill-management"].includes("getSingleDamagebill")) || permissions.isAdmin) &&
                    <><BillNumber errorMessage={formValidation?.bill_number}/>
                        <Date errorMessage={formValidation?.bill_date}/>
                        <AcceptanceDate acceptance_date={bill?.acceptance_date?.date} acceptanceDateValidation={acceptanceClass}/>
                        <RepairDate repairDateValidation={repairClass}/>
                        {permissions && ((permissions["web-for-damage-bill-management"] && permissions["web-for-damage-bill-management"].includes("getSingleDamagebill")) || permissions.isAdmin) &&
                            <BillEmailField/>}
                        {permissions && ((permissions["web-for-damage-bill-management"] && permissions["web-for-damage-bill-management"].includes("getSingleDamagebill")) || permissions.isAdmin) &&
                            <ContactPerson/>}
                        {permissions && ((permissions["web-for-damage-bill-management"] && permissions["web-for-damage-bill-management"].includes("getAllContactReasons")) || permissions.isAdmin) &&
                            <ContactReason errorMessage={formValidation?.bill_contact_reason}/>}
                        <ContactFrom/>
                        <KmInRepair errorMessage={formValidation?.vehicle_mileage_after_reparation} kmValidation={kmClass}/>
                        <TotalValue showConvertedValue={showConvertedValue}
                                    hideConvertedValue={hideConvertedValue}/>
                        <FirstVat/>
                        <IncludeTaxToggle/></>}
            </div>
        </>

    );
}

