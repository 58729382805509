import { useEffect } from "react";

const useRefreshOnTabFocus = (callback) => {
    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.visibilityState === "visible") {
                callback();
            }
        };

        document.addEventListener("visibilitychange", handleVisibilityChange);

        return () => {
            document.removeEventListener("visibilitychange", handleVisibilityChange);
        };
    }, [callback]);
};

export default useRefreshOnTabFocus;