import * as React from "react";
import {useEffect, useRef, useState} from "react";
import Autocomplete from "react-autocomplete";
import {useSelector} from "react-redux";

type DEDUCTION_CODE = {
    code: string;
    id: number;
    name: string;
}

export function DeductionCodeAutocomplete({
                                              deductionCode,
                                              autocompleteData,
                                              stateChanger,
                                              setFocusedId,
                                              dataTableItem,
                                              focusedId,
                                              getNextFocusableField,
                                              field,
                                              index,
                                              billItems,
                                              canEditItems,
                                              ...props
                                          }) {
    const {isReadOnly} = useSelector(state => state.damageBill)
    const autocompleteRef = useRef();
    const [searchValue, setSearchValue] = useState({
        value: deductionCode?.id || '',
        label: deductionCode ? `${deductionCode?.code} - ${deductionCode?.name}` : ``,
    })
    const [menuOffset, setMenuOffset] = useState(35);
    const [filteredData, setFilteredData] = useState([...autocompleteData]);
    const [isCorrect, setIsCorrect] = useState(true);

    const onInputChange = (value) => {
        const payingCodeWrapper = document.querySelector('.paying_code_wrapper');
        if (payingCodeWrapper.style.width.split('c')[0] < value.length) payingCodeWrapper.style.width = `${value.length}ch`
    }

    useEffect(() => {
        if (focusedId[0] === dataTableItem[0] && focusedId[1] === dataTableItem[1]) {
            autocompleteRef.current?.focus();
        }
    }, [focusedId, dataTableItem])

    useEffect(() => {
        stateChanger(field, searchValue.value);
    }, [searchValue.value, field, stateChanger])

    useEffect(() => {
        if (filteredData.length > 0) {
            setIsCorrect(true);
        } else {
            setIsCorrect(false);
        }
    }, [filteredData]);

    function handleOnChange(e) {
        setIsCorrect(true)
        if (e.target.value.trim() !== '') {
            setSearchValue({
                label: e.target.value,
                value: searchValue.value
            });
        } else {
            setSearchValue({
                label: '',
                value: ''
            });
        }
        const height = e.target.scrollHeight;
        if (menuOffset < height) setMenuOffset(height);
        setFilteredData(autocompleteData.filter(item => {
            let name = `${item.code} - ${item.name}`.toLowerCase();
            return name.includes(e.target.value.trim().toLowerCase());
        }));
        onInputChange(e.target.value);
    }

    const handleUserInput = () => {
        setIsCorrect(true);
        if (filteredData.length === 0 || filteredData.length === autocompleteData.length) {
            setSearchValue({
                value: '',
                label: ''
            })
            setFilteredData([...autocompleteData]);
            return;
        }
        const [item] = filteredData;
        setSearchValue({
            value: item?.id || '',
            label: item ? `${item?.code} - ${item?.name}` : ``
        })
    }

    function getItemValue(item) {
        return item.code;
    }

    function onSelect(itemId) {
        const item = filteredData.find(i => i.code === itemId);
        setSearchValue({
            value: item?.id || '',
            label: item ? `${item?.code} - ${item?.name}` : ``
        })
        // setFocusedId(getNextFocusableField(dataTableItem))
        setIsCorrect(true)
        onInputChange(`${item?.code} - ${item?.name}`);
    }

    function renderInput({disabled, ...props}) {
        return <input disabled={disabled} {...props}
                      onKeyDown={(e) => {
                         if (e.key === 'Enter') {
                             e.preventDefault();
                             handleUserInput();
                             setFocusedId(getNextFocusableField(dataTableItem))
                         }
                          if(billItems===index+1 || billItems-1===index+1){
                              const element = document.querySelector('.billItemsTbody');
                              element.scrollTop=element.scrollHeight;
                          }
                      }}
                      className={'form-control input_2'}
                      onClick={()=>{
                          if(billItems===index+1 || billItems-1===index+1){
                              const element = document.querySelector('.billItemsTbody');
                              element.scrollTop=element.scrollHeight;
                          }

                      }}
        />
    }

    function renderItem(item: DEDUCTION_CODE, isHighlighted) {
        return (
            <div key={item.id} style={{background: isHighlighted ? 'lightgray' : 'white'}}>
                {item.code} - {item.name}
            </div>
        );
    }

    function renderMenu(items) {
        const menuStyle = {
            borderRadius: '3px',
            boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
            background: 'rgba(255, 255, 255, 0.9)',
            padding: '2px 0',
            fontSize: '90%',
            position: 'absolute',
            overflow: 'auto',
            width: '100%',
            height: 'auto',
            top: menuOffset + 'px',
            left: 0,
            zIndex: 99999,
        }
        return <div style={{...menuStyle}} children={items}/>
    }

    return (
        <div className={isCorrect ? "autocompleteDiv" : "autocompleteDiv autocompleteDivWarning"}>
            <input type="hidden" name={props['name']} value={searchValue.value}/>

            <Autocomplete
                ref={autocompleteRef}
                value={searchValue.label}
                name={props['name']}
                field="name"
                items={filteredData}
                getItemValue={getItemValue}
                onChange={handleOnChange}
                renderMenu={renderMenu}
                onSelect={onSelect}
                renderInput={(props) => renderInput({disabled: isReadOnly || !canEditItems, ...props})}
                renderItem={renderItem}/>
        </div>
    );
}
