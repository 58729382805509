// @flow
import * as React from 'react';
import {Button} from "react-bootstrap"
import {checkLoggedUser} from "../../features/Authentication/authSlice";
import {useDispatch, useSelector} from "react-redux";
import {BILL_PROCESSING_STATUSES} from "../StatusContext";
import {changePaymentType} from "../../features/DamageBill/bill.actions";

export function SaveBillB({formRef}) {
    const dispatch = useDispatch();
    const {
        bill
    } = useSelector(state => state.damageBill)



    const onClickForm = async () => {
        if (formRef && formRef.current) {
            if (bill.processing_status=== BILL_PROCESSING_STATUSES.BILL_PAYED) {
                await dispatch(checkLoggedUser());
                dispatch(
                    await changePaymentType(
                        {
                            billDamageBillId: bill?.damage_bill_id,
                            data: (() => {
                                const data = new FormData(formRef.current);
                                const l = {}
                                for (const pair of data?.entries()) {
                                    l[pair[0]] = pair[1];
                                }
                                return l;
                            })()
                        }
                    )
                )
            } else {
                await dispatch(checkLoggedUser());
                formRef.current.requestSubmit();
            }
        }
    };

    return (
        <Button variant="primary"
                size={'sm'}
                onClick={onClickForm}>Rechnung speichern</Button>
    );
}
