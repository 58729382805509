import {AxiosResponse} from "axios";
import {rolesUrl, permissionsUrl} from "../ApiService";
import {client, headers} from "../../app/axiosClient";

export function getRoles(queryString: string, pageNumber: number, statuses: string = '', cancelToken?: any): Promise<AxiosResponse> {
    return client.get(`${rolesUrl}?filters[0][property]=name&filters[0][operator]=l&filters[0][value]=${queryString}&filters[1][property]=applications&filters[1][operator]=eq&filters[1][value]=${statuses}`, {
        headers: {
            ...headers
        },
        params: {
            page: pageNumber,
            per_page: 20
        },
        cancelToken
    })
}

export function getRolesWithPermissionCount(cancelToken?: any): Promise<AxiosResponse> {
    return client.get(`${rolesUrl}/with-permission-count`, {
        headers: {
            ...headers
        },
        cancelToken
    })
}

export function createRole(role, cancelTokenParam?: any): Promise<AxiosResponse> {
    const fd = new FormData();
    fd.append('name', role.name);
    fd.append('description', role.description);
    fd.append('applications', role.applications);
    return client.post(`${rolesUrl}`, fd, {
        headers: {
            ...headers
        },
        cancelToken: cancelTokenParam
    });
}

export function updateRole(roleId, role, cancelTokenParam?: any): Promise<AxiosResponse> {
    const fd = new FormData();
    fd.append('name', role.name);
    fd.append('description', role.description);
    fd.append('applications', role.applications);
    return client.put(`${rolesUrl}/${roleId}`, fd, {
        headers: {
            ...headers
        },
        cancelToken: cancelTokenParam
    });
}
export function getPermissions(queryString: string, pageNumber: number, cancelToken?: any): Promise<AxiosResponse> {
    return client.get(`${permissionsUrl}${queryString || ''}`, {
        headers: {
            ...headers
        },
        params: {
            page: pageNumber
        },
        cancelToken
    });
}
export function getPermissionsFilter(queryString: string, pageNumber: number, statuses: string = '', cancelToken?: any): Promise<AxiosResponse> {
    return client.get(`${permissionsUrl}?filters[0][property]=name&filters[0][operator]=l&filters[0][value]=${queryString}&filters[1][property]=applications&filters[1][operator]=eq&filters[1][value]=${statuses}`, {
        headers: {
            ...headers
        },
        params: {
            page: pageNumber,
            per_page: 20
        },
        cancelToken
    });
}
export function getRolePermissions(roleId, queryString: string = '', statuses: string = '', cancelToken?: any): Promise<AxiosResponse> {
    return client.get(`${rolesUrl}/${roleId}/permissions?filters[0][property]=name&filters[0][operator]=l&filters[0][value]=${queryString}&filters[1][property]=applications&filters[1][operator]=eq&filters[1][value]=${statuses}`, {
        headers: {
            ...headers
        },
        cancelToken
    })
}
export function updateRolePermission(fd, roleId, cancelToken?: any): Promise<AxiosResponse> {

    return client.put(`${rolesUrl}/${roleId}/permissions`, fd, {
        headers: {
            ...headers
        },
        cancelToken
    })
}



