import * as React from "react";
import {roleApplication} from "../roles/List";

export const ListRow = React.forwardRef(({index, item}, ref) => {
    const application = roleApplication();
    return (
        <tr ref={ref} className={"Table-Row someClass"} id={"damage-bill-items-" + index} style={{height: '50px'}}>
            <td className={"hash"} style={{width: '4%'}}>{item.id}</td>
            <td style={{width: '40%'}}>{item.name}</td>
            <td style={{width: '38%'}}>{item.description}</td>
            <td style={{width: '18%'}}>{application[item.applications]}</td>
        </tr>
    );
})


