// @flow
import {useCallback, useEffect, useState} from "react";
import useApi from "../../../hooks/useApi";

export function useSearchPaginator(keyword: string, statuses: Array<number>, apiFunc?: Promise, microservice?: string, hasPermission?: boolean, hasSecondFilter: boolean = false, secondFilter: string = ''): [boolean, boolean, Array<any>, boolean, () => void, () => void, number] {
    const [billsPaginated, setBillsPaginated] = useState<Array<any>>([])
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [hasMore, setHasMore] = useState<boolean>(false);
    useEffect(() => {
        if (!hasPermission) {
            return []}
    }, [hasPermission]);

    const [requestFrom, setRequestFrom] = useState<'page' | 'keyword'>('keyword');

    const {data, request, error, loading} = useApi(apiFunc, microservice , {
        cancelPreviousOngoing: false
    })

    // useEffect(() => {
    //     if (canceled) toast.error(`Request to service ${microservice} was canceled, time exceeded`, `${microservice}`);
    // }, [canceled]);

    const nextPage = useCallback(() => {
        if (hasMore) {
            setPageNumber(prevNumber => prevNumber + 1);
            setRequestFrom('page');
        }
    }, [hasMore])

    const refresh = useCallback(() => {
        setBillsPaginated([]);
        setPageNumber(1);
        setRequestFrom('keyword');
        setHasMore(false);
        if(hasSecondFilter) request({keyword, pageNumber: 1, secondFilter});
        else request({keyword, pageNumber: 1});
    }, [request, keyword, secondFilter, hasSecondFilter])

    /**
     * When keyword or selected statuses have changed
     * reset to init state
     */
    useEffect(() => {
        setBillsPaginated([]);
        setHasMore(false);
        setPageNumber(1);
        setRequestFrom('keyword');
    }, [keyword, secondFilter, apiFunc])


    /**
     * If response contain data, check if there are more to fetch later
     */
    useEffect(() => {
        if (!data || !data._page_count || !data._page) return;
        setHasMore(data._page_count > data._page)
    }, [data, pageNumber])


    /**
     * If response contain data, append them to already fetched data
     */
    useEffect(() => {
        if (!data || !data?._embedded) {
            setBillsPaginated(prevBills => {
                if (prevBills) return [
                    ...prevBills
                ]; else return []
            })
            return;
        }
        setBillsPaginated(prevBills => {
           const newData = data._embedded.records;
            if (pageNumber === 1) return [
                ...newData
            ]; else return [
                ...prevBills,
                ...newData
            ]
        });
    }, [data]);

    /**
     * Cancel request if
     * 1) user typed to fast
     * 2) selected/unselected a status to fast
     * 3) or a page was already being fetched
     */
    useEffect(() => {
        const timer = setTimeout(() => {
            if(hasSecondFilter) request({keyword, pageNumber, secondFilter}, requestFrom === 'keyword')
        else request({keyword, pageNumber}, requestFrom === 'keyword')
            }, 300)
        return () => {
            clearTimeout(timer)
        }
    }, [request, keyword, secondFilter, pageNumber])

    return [loading, (!!error), billsPaginated, hasMore, nextPage, refresh, pageNumber]
}
