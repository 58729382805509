import React from "react";
import Button from "react-bootstrap/Button"
import {Link} from "react-router-dom";

function AdminButton() {
    const handleClick = (e) => {
        e.preventDefault();
        if(typeof window!=='undefined'){
            window.open('/admin','_blank', 'noopener,noreferrer');

        }
    }

    return (<div style={{display: 'flex', width: '100%', justifyContent: 'center', marginBottom: '1rem'}}>
        <Link to={'/admin'} onClick={handleClick}> <Button className={""} variant="primary" >Admin</Button></Link>
    </div>)
}

export default AdminButton
