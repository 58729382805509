import {useRef, useState} from "react";
import {Form} from "react-bootstrap";
import * as React from "react";
import "../users/Users.css";
import Select from "react-select";

const colourStyles = {
    menu: base => ({
        ...base,
        zIndex: 100,
    }),
}

export function CustomFilterForm(props) {
    const [filterState, setFilterState] = useState(props.filterData);
    const [saveAsNew, setSaveAsNew] = useState(false);
    const filterFormRef = useRef(null);
    const permissions = JSON.parse(localStorage.getItem('user-permissions'));
    const tenants = props.tenants ? (props.tenants.map((tenant) => ({
        label: tenant.tenant_name_description, value: `${tenant.tenant_id}`
    }))) : [];
    const employees = props.employees ? (props.employees.map((employee) => ({
        label: `${employee.first_name} ${employee.last_name}`, value: `${employee.user_id}`
    }))) : [];

    function onFilterFormSubmit(e) {
        e.preventDefault();
        const data = new FormData(filterFormRef.current);
        const filter = {
            title: !!data.get('title') ? data.get('title') : '',
            mandnr: !!filterState.mandnr && typeof filterState.mandnr !== 'string' ? filterState.mandnr?.join(',') : filterState.mandnr,
            amount_from: !!data.get('amount_from') ? data.get('amount_from') : '',
            amount_to: !!data.get('amount_to') ? data.get('amount_to') : '',
            blocked_by_me: data.get('blocked_by_me') ? '1' : '0',
            transactions_by_employee: !!filterState.transactions_by_employee && typeof filterState.transactions_by_employee !== 'string' ? filterState.transactions_by_employee?.join(',') : filterState.transactions_by_employee,
            is_active: data.get('is_active') ? '1' : '0',
        }
        props.formSubmit(!!data.get('id') && !saveAsNew ? {...filter, id: data.get('id')} : filter);
    }

    function updateItem(event) {
        let tmpItem = filterState;
        tmpItem[event.target.name] = event.target.value;
        setFilterState({...tmpItem});
    }

    return (
        <Form id="userForm" ref={filterFormRef} onSubmit={onFilterFormSubmit}>
            {!!filterState.id && <input type={"hidden"} name={"id"} value={filterState.id}/>}
            <div className={"row"}>
                <div className={"col-md-6"}>
                    <div className={"form-group"}>
                        Title: <input type={"text"} className={"form-control"} name={"title"} value={filterState.title} onChange={updateItem}/>
                    </div>
                </div>
                <div className={"col-md-3"}>
                    <div className={"row ml-0 align-items-center w-100 h-100"} style={{gap: '20%'}}>
                        Is blocked by me: <input type={"checkbox"} name={"blocked_by_me"} defaultChecked={filterState.blocked_by_me}
                                                 onChange={updateItem} value={filterState.blocked_by_me} style={{width: '28px', height: '28px'}}/>
                    </div>
                </div>
                {permissions && ((permissions["web-for-damage-bill-management"] && permissions["web-for-damage-bill-management"].includes("activateCustomFilter")) || permissions.isAdmin) &&
                    <div className={"col-md-3"}>
                        <div className={"row ml-0 align-items-center w-100 h-100"} style={{gap: '20%'}}>
                            Is active: <input type={"checkbox"} name={"is_active"} defaultChecked={filterState.is_active}
                                              onChange={updateItem} value={filterState.is_active} style={{width: '28px', height: '28px'}}/>
                        </div>
                    </div>
                }
            </div>
            <div className={"row"}>
                <div className={"col-md-6"}>
                    <div className={"form-group"}>
                        Amount from: <input type={"number"} step="0.01" className={"form-control"} name={"amount_from"}
                                         onChange={updateItem} value={filterState.amount_from}/>
                    </div>
                </div>
                <div className={"col-md-6"}>
                    <div className={"form-group"}>
                        Amount to: <input type={"number"} step="0.01" className={"form-control"} name={"amount_to"}
                                            onChange={updateItem} value={filterState.amount_to}/>
                    </div>
                </div>
            </div>
            <div className={"row"}>
                {permissions && ((permissions["web-for-damage-bill-management"] && permissions["web-for-damage-bill-management"].includes("getAllEmployees")) || permissions.isAdmin) &&
                    <div className={"col-md-6"}>
                        <div className={"form-group"}>
                            Employees:
                            {employees.length ? (<Select
                                onChange={(selectedItems) => {
                                    let ids = selectedItems.map(({value}) => value);
                                    setFilterState((prev) => ({
                                        ...prev,
                                        transactions_by_employee: ids
                                    }));
                                }}
                                closeMenuOnSelect={false}
                                isMulti
                                options={employees}
                                styles={colourStyles}
                                value={!!filterState.transactions_by_employee ? employees.filter(item => {
                                    return filterState.transactions_by_employee?.includes(`${item.value}`)
                                }) : []}
                            />) : null}
                        </div>
                    </div>
                }
                {permissions && ((permissions["web-for-car-dealer-management"] && permissions["web-for-car-dealer-management"].includes("getAllTenantsCurrencies")) || permissions.isAdmin) &&
                    <div className={"col-md-6"}>
                        <div className={"form-group"}>
                            Tenants:  {tenants.length ? (<Select
                            onChange={(selectedItems) => {
                                let ids = selectedItems.map(({value}) => value);
                                setFilterState((prev) => ({
                                    ...prev,
                                    mandnr: ids
                                }));
                            }}
                            closeMenuOnSelect={false}
                            isMulti
                            options={tenants}
                            styles={colourStyles}
                            value={!!filterState.mandnr ? tenants.filter(item => {
                               return filterState.mandnr?.includes(`${item.value}`)
                            }) : []}
                        />) : null}
                        </div>
                    </div>
                }
            </div>
            <div className={"form-buttons-wrapper"}>
                {permissions && ((!!filterState.id && (permissions["web-for-damage-bill-management"] && permissions["web-for-damage-bill-management"].includes("updateCustomFilter"))) || (permissions["web-for-damage-bill-management"] && permissions["web-for-damage-bill-management"].includes("insertNewCustomFilter")) || permissions.isAdmin) &&
                    <button className={"btn btn-primary form-buttons"}>Save</button>}
                {!!filterState.id && permissions && ((permissions["web-for-damage-bill-management"] && permissions["web-for-damage-bill-management"].includes("insertNewCustomFilter")) || permissions.isAdmin) &&
                    <button className={"btn btn-primary form-buttons"} onClick={() => setSaveAsNew(true)}>Save as new</button>}
                <button type={"button"} onClick={props.toggleModal} className={"btn btn-danger form-buttons"}>Cancel</button>
            </div>
        </Form>
    );
}
