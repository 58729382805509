//@flow
import {useCallback, useEffect, useState} from "react";
import useApi from "../../hooks/useApi";
import toast from "react-hot-toast";

export function useTablePaginator(apiFunc?: Promise, microservice?: string): [boolean, boolean, Array<any>, boolean] {
    const [billsPaginated, setBillsPaginated] = useState<Array<any>>([])
    const [hasMore, setHasMore] = useState<boolean>(false);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [total, setTotal] = useState<number>(0);
    const {data, request, error, loading, canceled} = useApi(apiFunc, microservice)

    useEffect(() => {
        if (canceled) toast.error(`Request to service ${microservice} was canceled, time exceeded`, `${microservice}`);
    }, [canceled]);

    const nextPage = useCallback(() => {
        setPageNumber(prevNumber => prevNumber + 1);
    }, [])

    useEffect(() => {
        setBillsPaginated([]);
        setHasMore(false);
    }, [])

    useEffect(() => {
        if (!data || !data._total_items || !data._page_count || !data._page) return;
        setTotal(data._total_items)
        setHasMore(data._page_count > data._page)
    }, [data, pageNumber])

    useEffect(() => {
        if (!data || !data._embedded) return;
        setBillsPaginated(prevBills => {
            return [
                ...prevBills,
                ...data._embedded.records
            ]
        });
    }, [data])

    const refresh = useCallback(() => {
        setBillsPaginated([])
        setTotal(0);
        if (pageNumber === 1) {
            request({pageNumber: 1});
        } else {
            setPageNumber(1);
        }
        setHasMore(false);
    }, [request, pageNumber])

    useEffect(() => {
        const timer = setTimeout(() => request({pageNumber}), 100)
        return () => {
            clearTimeout(timer)
        }
    }, [request, pageNumber])

    return [loading, (!!error), billsPaginated, hasMore, total, refresh, nextPage]
}
