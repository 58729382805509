import * as React from 'react';
import {ProtectedRoute} from "./ProtectedRoute";
import LoginPage from "../pages/Login";
import ResetPassword from "../pages/ResetPassword";
import {AuthenticatedRoute} from "./AuthenticatedRoute";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {Documents} from "../pages/Documents";
import {Link, Redirect, Route, Switch} from "react-router-dom";
import {BillDocument} from "../pages/BillDocument";
import PayPage from "../pages/Pay";
import EmailPage from "../pages/Email";
import SepaPage from "../pages/Sepa";
import {UsersPage} from "../pages/UsersPage";
import {RolesPage} from "../pages/RolesPage";
import {PermissionsPage} from "../pages/PermissionsPage";
import {TeamsPage} from "../pages/Teams";
import {TeamMembersPage} from "../pages/Teams/members";
import AdminPage from "../pages/Admin";
import CustomFiltersPage from "../pages/CustomFilters";
import BlankPage from "../pages/BlankPage";


const theme = createTheme();

export function TopLevelRoutes() {
    return (
        <Switch>
            <ProtectedRoute path={'/login'} component={LoginPage}/>
            <ProtectedRoute path={'/reset-password'} component={ResetPassword}/>
            <AuthenticatedRoute path="/documents/:billId">
                {/* Theme Provider is needed here because of the collapsible component inside Documents*/}
                <ThemeProvider theme={theme}>
                    <Documents/>
                </ThemeProvider>
            </AuthenticatedRoute>
            <AuthenticatedRoute exact path="/">
                <Redirect to={'/bills'}/>
            </AuthenticatedRoute>
            <AuthenticatedRoute path="/bills" component={BillDocument}/>
            <AuthenticatedRoute path="/pay" component={PayPage}/>
            <AuthenticatedRoute path="/sepa" component={SepaPage}/>
            <AuthenticatedRoute path="/email" component={EmailPage}/>
            <AuthenticatedRoute exact path="/teams" component={TeamsPage}/>
            <AuthenticatedRoute path="/teams/:teamId" component={TeamMembersPage}/>
            <AuthenticatedRoute path="/users" component={UsersPage}/>
            <AuthenticatedRoute path="/roles" component={RolesPage}/>
            <AuthenticatedRoute path="/permissions" component={PermissionsPage}/>
            <AuthenticatedRoute path="/admin" component={AdminPage}/>
            <AuthenticatedRoute path="/custom-filters" component={CustomFiltersPage}/>
            <AuthenticatedRoute path="/blank-page" component={BlankPage}/>
            <Route path="/api">
                <Redirect to={{pathname: '/404'}}/>
            </Route>
            <Route path="*">
                <h1>404 page</h1>
                <Link to={'/'}>homepage</Link>
            </Route>
        </Switch>
    );
}
