import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    toggleAllCareDealersForEmails,
    toggleSelectAllButtonCarDealers
} from "../../features/SearchPagination/searchPagination.slice";

function SendAllCarDealersForUpdatingEmails() {
    const {carDealers, select_all_button_carDealers} = useSelector(state => state.searchPagination);
    const dispatch = useDispatch();

    const handleClickedCheckbox = (e) => {
        dispatch(toggleSelectAllButtonCarDealers(e.target.checked));

        const carDealersIds = carDealers.map(item => [item.id, e.target.checked]);
        const carDealersIdsForEmails = Object.fromEntries(carDealersIds);
        dispatch(toggleAllCareDealersForEmails(carDealersIdsForEmails));
    }

    return (

            <input type="checkbox"
                   style={{cursor: 'pointer'}}
                   id={"carDealers_selectAll_checkbox"}
                   onChange={(e) =>{ handleClickedCheckbox(e); console.log('checked')}}
                   checked={select_all_button_carDealers}
            />


    );
}

export default React.memo(SendAllCarDealersForUpdatingEmails)
