import {BsTrashFill} from "react-icons/bs";
import * as React from "react";

export const ListRow = React.forwardRef(({item, onEdit, onEditRoles, onDelete}, ref) => {
    const permissions = JSON.parse(localStorage.getItem('user-permissions'));
    return (
        <tr ref={ref} className={"Table-Row someClass"} style={{height: '50px'}}>
            <td>{item.user.first_name} {item.user.last_name}</td>
            <td>{item.user.username}</td>
            <td>{item.country_name}</td>
            <td className={"Last-Table-Cell"}>

                {permissions && ((permissions["web-for-user-management"] && permissions["web-for-user-management"].includes("deleteMember")) || permissions.isAdmin) &&
                <button style={{border: "none", background: "unset", float: "right"}} data-itemid={item.id}
                        title={"Delete"}
                        onClick={onDelete}>
                    <BsTrashFill
                        style={{
                            color: "red",
                            background: "unset",
                            margin: "0 0 0 0px"
                        }}
                        className={"Icon-Decline"}/>
                    </button>}

                {/*{permissions && ((permissions["web-for-user-management"] && permissions["web-for-user-management"].includes("updateUserWorkingCountry")) || permissions.isAdmin) &&*/}
                {/*<button style={{border: "none", background: "unset", float: "right"}} data-itemid={item.id}*/}
                {/*        title={"Edit"}*/}
                {/*        onClick={onEdit}>*/}
                {/*    <BsPencilSquare*/}
                {/*        style={{*/}
                {/*            color: "gray",*/}
                {/*            background: "unset",*/}
                {/*            margin: "0 0 0 0px"*/}
                {/*        }}*/}
                {/*        className={"Icon-Decline"}/>*/}
                {/*</button>}*/}


            </td>
        </tr>
    );

});
