import React from "react";
import Modal from "react-bootstrap/Modal";
import './error_message.css';
export function NotificationModalContract({show, setShow, message}) {

    return (
        <Modal className={''} show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton className={'background'}>
                <Modal.Title className={'modal-title'}>Fehler!</Modal.Title>
            </Modal.Header>
            <Modal.Body className={'background text-center'}>
                <div className={'d-flex flex-column align-items-center px-3 pt-2'}>
                    {message}
                </div>
            </Modal.Body>

        </Modal>
    );
}
