import {useState, useCallback} from "react";
import * as React from "react"
import {ListRow} from "./ListRow";
import {useSearchPaginator} from "../contract/BillsList/useSearchPaginator";
import InView from "../InView";
import {roleApplication, SelectStyling} from "../roles/List";
import {getPermissionsFilter} from "../../services/API/roles.service";
import {BarStyling} from "../bills/List/SearchBar";

export function List() {
    const permissions = JSON.parse(localStorage.getItem('user-permissions'));
    const application = roleApplication();
    const [keyword, setKeyword] = useState('');
    const [statusesP, setStatusesP] = useState('');
    const [statuses] = useState([]);
    const [, hasError, allPermissions, hasMore, nextPage] = useSearchPaginator(keyword, statuses, getPermissionsFilter, 'permissions', permissions && ((permissions["web-for-user-management"] && permissions["web-for-user-management"].includes("getAllPermissions")) || permissions.isAdmin), true, statusesP);

    const requestNextPage = useCallback(() => {
        if (hasMore && !hasError) {
            nextPage()
        }
    }, [hasMore, hasError, nextPage]);

    const handleChangePermissions = (keyword) => {
        setKeyword(keyword);
    }
    const handleOnChangeApplicationPermissions = (application) => {
        setStatusesP(application);
    }

    return permissions && ((permissions["web-for-user-management"] && permissions["web-for-user-management"].includes("getAllPermissions")) || permissions.isAdmin) ?
    <div className={"list-container"}>
        <div className={"toolbox"} style={{display: 'flex', gap: '20px'}}>
            <input
                type={"text"}
                style={{...BarStyling, borderRadius: '5px'}}
                key="random2wq"
                value={keyword}
                onChange={e => handleChangePermissions(e.target.value)}
                placeholder={"Berechtigung suchen"}
            />
            <select style={SelectStyling} onChange={(e) => handleOnChangeApplicationPermissions(e.target.value)}>
                <option value={''}>All permissions</option>
                <option value={0}>{application[0]}</option>
                <option value={1}>{application[1]}</option>
                <option value={2}>{application[2]}</option>
            </select>
        </div>
        <div>
            <table>
                <thead>
                <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Beschreibung</th>
                    <th>Application</th>
                </tr>
                </thead>
                <tbody>
                {allPermissions.filter((item) => item.is_visible).map((item, index) => {
                    return (
                        (index !== Math.round(allPermissions.filter((item) => item.is_visible).length - 1)) ?
                            <ListRow key={"permission_item_" + index} item={item} index={index}
                            />
                            : <InView as={ListRow} key={"permission_item_" + index} item={item} index={index}
                                      onceOnIntersect={requestNextPage}
                            />

                    )

                })}
                </tbody>
            </table>
        </div>
    </div> : <div>Sie dürfen diesen Inhalt nicht sehen</div>;
}