import React, {useCallback, useEffect, useState} from 'react';
import Select from 'react-select';
import {useDispatch, useSelector} from "react-redux";
import {
    updateFilters,
    toggleSortOrder, updateStatusOfPaginatedPill, saveCountedFilteredBills, fetchBills, setPageNumber
} from "../../../features/SearchPagination/searchPagination.slice";
import Button from "react-bootstrap/Button";
import {BsSortDown, BsSortUp} from "react-icons/bs";
import useApi from "../../../hooks/useApi";
import {getBillFilters} from "../../../services/API/damageBill.service";
import {
    deactivateAllFilters,
    activateCustomFilter, getActiveCustomFilterForUser, getCustomFiltersWithSearch
} from "../../../services/API/customFilters.service";
import {useSearchPaginator} from "../../contract/BillsList/useSearchPaginator";
import {setChosenFilter} from "../../../features/SearchPagination/searchPagination.slice";
import toast from "react-hot-toast";
import {useHistory} from "react-router";


export const colourStyles = {
    control: styles => ({...styles, backgroundColor: 'white'}),
    valueContainer: styles => ({...styles, padding: '4px 8px'}),
    indicatorSeparator: styles => ({...styles, marginTop: '10px', marginBottom: '10px'}),
    svg: styles => ({...styles, height: '18px', width: '20px'}),
    option: (styles) => {
        return {
            ...styles,
            textAlign: 'left',
        };
    },
};
export const BarStyling = {
    background: "white",
    padding: "0.5rem",
    width: "100%",
    border: "1px solid hsl(0, 0%, 80%)"
};

const SearchBar = ({setShowAdvancedSearch}) => {
    const {bill, isLoading} = useSelector(state => state.damageBill);
    const {keyword, sortOrder, statuses, chosenFilter} = useSelector(state => state.searchPagination);
    const dispatch = useDispatch();
    const [optionsFilters, setOptionsFilters] = useState();
    const {data, request} = useApi(getBillFilters, 'damagebills');
    const [keywordNew, setKeywordNew] = useState('');
    const [statusesNew] = useState([]);
    const [, hasError, allFilters, hasMore, nextPage, refresh] = useSearchPaginator(keywordNew, statusesNew, getCustomFiltersWithSearch);
    const {data: deactivateFiltersData, loading: deactivateFiltersLoading, error: deactivateFiltersError, request: deactivateFiltersRequest} = useApi(deactivateAllFilters);
    const {data: activateCustomFilterData, loading: activateCustomFilterLoading, error: activateCustomFilterError, request: activateCustomFilterRequest} = useApi(activateCustomFilter);
    const {
        data: activeCustomFilterUser,
        request: getActiveCustomFilterForUserRequest,
        error: errorActiveCustomFilter
    } = useApi(getActiveCustomFilterForUser);
    let selectOptionsData = data?.data?.map((item) => {
        return {value: item?.processing_status, label: `${item?.name}  (${item?.value})`}
    });
    const noFilterOption = {value: "no_filter", label: "No filter"}
    const permissions = JSON.parse(localStorage.getItem('user-permissions'));
    const history = useHistory();

    useEffect(() => {
        if (activateCustomFilterError) toast.error(activateCustomFilterError);
        else if (deactivateFiltersError) toast.error(deactivateFiltersError);
    }, [activateCustomFilterError, deactivateFiltersError]);

    useEffect(() => {
        if ((activateCustomFilterData && activateCustomFilterData.code === 200) ||
            (deactivateFiltersData && deactivateFiltersData.code === 200))
            permissions && ((permissions["web-for-damage-bill-management"] && permissions["web-for-damage-bill-management"].includes("getAllDamagebills")) || permissions.isAdmin) &&
            dispatch(fetchBills());
    }, [activateCustomFilterData, deactivateFiltersData]);

    const onInView = useCallback(() => {
        if (hasMore && !hasError) nextPage();
    }, [hasMore, hasError, nextPage]);

    useEffect(() => {
        request();
    }, [bill?.processing_status, request])

    /**
     * On first render and everytime the selected bill changes processing status
     * request to fetch again paginated bills with query from last request
     */
    useEffect(() => {
        dispatch(updateFilters({}));
    }, [dispatch])

    useEffect(() => {
        dispatch(updateStatusOfPaginatedPill({
            id: bill?.id,
            status: bill?.processing_status
        }));
    }, [dispatch, bill?.processing_status])

    const onChangeHandler = (keyword) => dispatch(updateFilters({keyword}));

    const onToggle = () => dispatch(toggleSortOrder());

    const onChangeStatusHandler = (selected) => {
        request();
        let statuses = [];
        let count = 0;
        for (let i in selected) {
            statuses[i] = selected[i].value;
            count += Number((selected[i].label.substring(selected[i].label.indexOf('(') + 1, selected[i].label.indexOf(')'))));
        }
        dispatch(saveCountedFilteredBills(count));
        dispatch(updateFilters({statuses}));
    }
    useEffect(() => {
        allFilters && setOptionsFilters([noFilterOption, ...allFilters?.map((item) => ({label: item.title, value: item.id}))]);
    }, [allFilters]);

    const handleSelectChange = (option) => {
        dispatch(setPageNumber(1));
        if (option.value === "no_filter") {
            dispatch(setChosenFilter("no_filter"));
            deactivateFiltersRequest();
        } else {
            dispatch(setChosenFilter(option.value));
            activateCustomFilterRequest({id: option.value});
        }
        history.push('/bills');
        const childWindow = window.open('', 'childWindow', 'popup');
        if (childWindow.location.pathname !== '/blank-page') window.open('/blank-page', 'childWindow');
    }

    useEffect(() => {
        permissions && ((permissions["web-for-damage-bill-management"] && permissions["web-for-damage-bill-management"].includes("getCurrentCustomFilters")) || permissions.isAdmin) &&
        getActiveCustomFilterForUserRequest();
    }, []);

    const handleWindowFocus = () => {
        dispatch(setPageNumber(1));
        refresh();
        permissions && ((permissions["web-for-damage-bill-management"] && permissions["web-for-damage-bill-management"].includes("getCurrentCustomFilters")) || permissions.isAdmin) &&
        getActiveCustomFilterForUserRequest();
        permissions && ((permissions["web-for-damage-bill-management"] && permissions["web-for-damage-bill-management"].includes("getAllDamagebills")) || permissions.isAdmin) &&
        dispatch(fetchBills());
        if (errorActiveCustomFilter) return;

        if (activeCustomFilterUser && activeCustomFilterUser.id) {
            dispatch(setChosenFilter(activeCustomFilterUser.id));
        } else {
            dispatch(setChosenFilter("no_filter"));
        }
    };
    useEffect(() => {
        if (activeCustomFilterUser && activeCustomFilterUser.id) {
            dispatch(setChosenFilter(activeCustomFilterUser.id));
        } else {
            dispatch(setChosenFilter("no_filter"));
        }
        const handleVisibilityChange = () => {
            if (document.visibilityState === "visible") {
                handleWindowFocus();
            }
        };

        document.addEventListener("visibilitychange", handleVisibilityChange);
        return () => {
            document.removeEventListener("visibilitychange", handleVisibilityChange);
        };
    }, [activeCustomFilterUser]);

    return (
        <>
            <div>
                <div className="col-md-12 select_field"
                     style={{fontSize: '14px', display: "flex", flexDirection: 'column', gap: '0.5rem'}}>
                    {permissions && ((permissions["web-for-damage-bill-management"] && permissions["web-for-damage-bill-management"].includes("getAllCustomFilters")) || permissions.isAdmin) &&
                        <div>
                            <Select
                                options={optionsFilters}
                                value={optionsFilters && (chosenFilter && optionsFilters.find((opt) => opt.value === chosenFilter))}
                                onChange={handleSelectChange}
                                isLoading={activateCustomFilterLoading || deactivateFiltersLoading || isLoading}
                                isMulti={false}
                                placeholder="Choose filter"
                                getOptionLabel={(e) => e.label}
                                getOptionValue={(e) => e.value}
                                onMenuScrollToBottom={onInView}
                                onInputChange={(val) => setKeywordNew(val)}
                                isOptionDisabled={(option) => permissions && (((permissions["web-for-damage-bill-management"] && !permissions["web-for-damage-bill-management"].includes("activateCustomFilter")) && !permissions.isAdmin && option.value !== 'no_filter') || ((permissions["web-for-damage-bill-management"] && !permissions["web-for-damage-bill-management"].includes("deActivateAllCustomFilter")) && !permissions.isAdmin && option.value === 'no_filter'))}
                            />
                        </div>
                    }
                    {permissions && ((permissions["web-for-damage-bill-management"] && permissions["web-for-damage-bill-management"].includes("getAllDamagebillFilters")) || permissions.isAdmin) &&
                        <Select
                        onChange={e => onChangeStatusHandler(e)}
                        placeholder={"Alle"}
                        closeMenuOnSelect={false}
                        isMulti
                        options={selectOptionsData}
                        styles={colourStyles}
                        value={selectOptionsData && selectOptionsData.filter(item => statuses.includes(item.value))}
                        onFocus={() => setShowAdvancedSearch(false)}

                    />}
                </div>
            </div>
            <div style={{display: "flex", gap: '0.5rem', padding: '10px 15px'}}>
                <input
                    type={"text"}
                    style={BarStyling}
                    key="random1"
                    value={keyword}
                    onChange={e => onChangeHandler(e.target.value)}
                    placeholder={"Rechnung suchen"}
                    className={"searchBar"}
                    onFocus={() => setShowAdvancedSearch(false)}
                />
                <Button variant="primary"
                        onClick={onToggle}>{sortOrder === 'asc' ? <BsSortUp/> : <BsSortDown/>}</Button>
            </div>
        </>
    );
}

export default SearchBar;
