import {
    map,
    filter,
    switchMap,
    mergeMap,
    takeUntil,
    catchError,
} from 'rxjs/operators'
import {
    advanceFetchBills,
    advanceFetchBillsFailed,
    advanceFetchBillsFullFilled,
    fetchBills,
    fetchBillsFailed,
    fetchBillsFullFilled,
    fetchCarDealers,
    fetchCarDealersFailed,
    fetchCarDealersFullFilled,
    nextPage,
    nextPageCarDealers,
    toggleSortOrder,
    updateFilters,
    updateFiltersCarDealers,
    updateEmailsCarDealersFailed,
    updateEmailsCarDealersFullFilled,
    updateEmailsCarDealers
} from "./searchPagination.slice";
import {combineLatest, of} from "rxjs";
import {advanceSearchBills, searchBills} from "../../services/API/damageBills.service";
import {fromAxios} from "../../utils/observables/fromAxios.observable";
import toast from "react-hot-toast";
import {
    putUpdateCarDealersEmailAddress,
    searchCarDealersNew as searchCarDealers
} from "../../services/API/carDealer.service";



export const nextPageEpic = action$ =>
    action$.pipe(
        filter(nextPage.match),
        map(() => fetchBills())
    )

export const filtersUpdatedEpic = action$ =>
    action$.pipe(
        filter((action) => {
            return updateFilters.match(action) || toggleSortOrder.match(action)
        }),
        map(() => fetchBills())
    )

const mapToQueryParamsAndPage = ([keyword, pageNumber, statuses, sortedColumn, sortOrder]) => {
    return {
        keyword,
        pageNumber,
        statuses,
        sortedColumn,
        sortOrder
    }
}

const mapResponseToPayload = ({data}) => ({
    billsPaginated: data._embedded.records,
    hasMore: data._page_count > data._page,
    totalItems: data._total_items,
    page: data._page
})
export const nextPageCarDealersEpic = action$ =>
    action$.pipe(
        filter(nextPageCarDealers.match),
        map(() => fetchCarDealers())
    )

export const filtersUpdatedCarDealersEpic = action$ =>
    action$.pipe(
        filter((action) => {
            return updateFiltersCarDealers.match(action)
        }),
        map(() => fetchCarDealers())
    )

const mapToQueryParamsAndPageCarDealers = ([pageNumberCarDealers, hdnr, default_email_address, zip, full_name, filial]) => {
    return {
        pageNumberCarDealers,
        hdnr,
        default_email_address,
        zip,
        full_name,
        filial

    }
}

const mapResponseToPayloadCarDealers= ({data}) => ({
    carDealers: data._embedded.records,
    hasMoreCarDealers: data._page_count > data._page,
    totalItemsCarDealers: data._total_items
})
/**
 * Epic used to fetch bills for BillsList
 * @param action$
 * @param state$ is used to populate the request with the proper params
 * @returns {*}
 */
export const fetchBillsEpic = (action$, state$) =>
    action$.pipe(
        filter(fetchBills.match),
        mergeMap(() =>
            combineLatest(
                [
                    of(state$.value.searchPagination.keyword), // make observables of state
                    of(state$.value.searchPagination.pageNumber),
                    of(state$.value.searchPagination.statuses),
                    of(state$.value.searchPagination.sortedColumn),
                    of(state$.value.searchPagination.sortOrder),
                ]
            )
                .pipe(
                    map(mapToQueryParamsAndPage), // map the state to a more convenient format
                    switchMap((obj) =>
                        fromAxios(searchBills, [obj])
                            .pipe(
                                takeUntil( // if another action for fetching bills gets dispatched cancel this one
                                    action$
                                        .pipe(
                                            filter(fetchBills.match)
                                        )
                                ),
                                map(mapResponseToPayload),
                                map((data) => fetchBillsFullFilled(data)), // dispatch action with response data
                                catchError((err) =>{ if (err.status === 503) toast.error('Service is currently unavailable'); else return of(fetchBillsFailed())}), // dispatch action with error
                            )
                    ),
                )
        )
    );
export const fetchCarDealersEpic = (action$, state$) =>
    action$.pipe(
        filter(fetchCarDealers.match),
        mergeMap(() =>
            combineLatest(
                [
                    of(state$.value.searchPagination.pageNumberCarDealers),
                    of(state$.value.searchPagination.hdnr),
                    of(state$.value.searchPagination.default_email_address),
                    of(state$.value.searchPagination.zip),
                    of(state$.value.searchPagination.full_name),
                    of(state$.value.searchPagination.filial),


                ]
            )
                .pipe(
                    map(mapToQueryParamsAndPageCarDealers), // map the state to a more convenient format
                    switchMap((obj) =>
                         fromAxios(searchCarDealers, [obj])
                            .pipe(
                                takeUntil( // if another action for fetching bills gets dispatched cancel this one
                                    action$
                                        .pipe(
                                            filter(fetchCarDealers.match)
                                        )
                                ),
                                map(mapResponseToPayloadCarDealers),
                                map((data) => fetchCarDealersFullFilled(data)), // dispatch action with response data
                                catchError((err) =>{ if (err.status === 503) toast.error('Service is currently unavailable'); else return of(fetchCarDealersFailed())}), // dispatch action with error
                            )
                    ),
                )
        )
    );

/**
 * Epic used to fetch bills for BillsList
 * @param action$
 * @returns {*}
 */
export const advancedFetchBillsEpic = (action$) =>
    action$.pipe(
        filter(advanceFetchBills.match),
        switchMap(({payload}) =>
            fromAxios(advanceSearchBills, [payload])
                .pipe(
                    takeUntil( // if another action for fetching bills gets dispatched cancel this one
                        action$
                            .pipe(
                                filter(advanceFetchBills.match)
                            )
                    ),
                    map(mapResponseToPayload),
                    map((data) => advanceFetchBillsFullFilled(data)), // dispatch action with response data
                    catchError((err) =>{ for (let i = 500; i < 600; i++) if (err.message === `Request failed with status code ${i}`) toast.error('Service damagebills is currently unavailable'); return  of(advanceFetchBillsFailed())}), // dispatch action with error
                )
        ),
    );
export const updateEmailsCarDealersEpic = (action$) => action$.pipe(filter(updateEmailsCarDealers.match), filter(action => !!(action.payload)), switchMap(({payload}) => fromAxios(putUpdateCarDealersEmailAddress, [payload.ids, payload.all_selected, payload.default_email_address, payload.searchParams])
    .pipe(takeUntil(action$
            .pipe(filter(updateEmailsCarDealers.match))), mergeMap(({data}) => of(updateEmailsCarDealersFullFilled(data),// dispatch action with response data
            // fetchCarDealers()
        )), catchError((err) => {
            for (let i = 500; i < 600; i++) if (err.message === `Request failed with status code ${i}`) toast.error('Service car dealers is currently unavailable');
            return of(updateEmailsCarDealersFailed(err?.isAxiosError ? err?.response?.data : err))
        }), // dispatch action with error
    )),)