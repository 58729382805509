//@flow
import {Form} from "react-bootstrap";
import * as React from "react";
import {useCallback, useEffect, useRef, useState} from "react";
import DamageInfo from "../DamageInfo";
import {Loader} from "../../general/Loader";
import {useDispatch, useSelector} from "react-redux";
import {FormBills} from "./FormBills";
import {DeclineBillButton} from "../../billsAdd/DeclineBillButton";
import {AuthorizeBillButton} from "../../billsAdd/AuthorizeBillButton";
import {PrepareBillForPaymentButton} from "../../billsAdd/PrepareBillForPaymentButton";
import {SaveBillB} from "../../billsAdd/SaveBillB";
import {ManualSetupSubmitButton} from "./FormBills/ManualSetupSubmitButton";
import {fetchValidationJsonData, updateBill} from "../../../features/DamageBill/bill.actions";
import {DeleteBillButton} from "../../billsAdd/DeleteBillButton";
import {BILL_PROCESSING_STATUSES} from "../../StatusContext";
import {ResolveBillButton} from "../../billsAdd/ResolveBillButton";
import {ErrorMessageModal} from "../../general/ErrorMessageModal/ErrorMessageModal";
import {convertForeignToDomesticCurrency} from "../../../utils/damageItemsCalculations";
import {Toaster} from "react-hot-toast";
import {NotificationModalContract} from "../../general/ErrorMessageModal/NotificationModalContract";
import {SelectionModalContract} from "../../general/ErrorMessageModal/SelectionModalContract";

export const BillFormContext = React.createContext<>({})

export function BillForm() {

    const {
        bill,
        error,
        isLoading,
        allowPaymentButton,
        allowAuthorizeButton,
        allowDeclineButton,
        isManualSetup,
        allowResolveButton,
        isReadOnly, hasEditRight, validationCodes, payout_inconsistencies,
        validation_codes_warning, currency, maxPaymentLimit, paymentTotal, contractCheck
    } = useSelector(state => state.damageBill)
    const damage= useSelector(state => state.damageBill.damage)

    const dispatch = useDispatch();

    const [billFormClassNameForZoomedItems, setBillFormClassNameForZoomedItems] = useState('notZoomedItems');
    const [manualHeaderId, setManualHeaderId] = useState(undefined);
    const [manualPositionsId, setManualPositionsId] = useState([]);
    const permissions = JSON.parse(localStorage.getItem('user-permissions'));
    const hasPermissionChangeSettlement = permissions && ((permissions["web-for-damage-bill-management"] && permissions["web-for-damage-bill-management"].includes("changeSettlementType")) || permissions.isAdmin);
    const [show, setShow] = useState<boolean>(false);
    const [showNotificationModal, setShowNotificationModal]= useState<boolean>(false);
    const [showSelectionModal, setShowSelectionModal]= useState<boolean>(false);
    const [selectionName, setSelectionName]= useState('');
    const [refreshLogsIndicator, setRefreshLogsIndicator]=useState(0);
    const [userNotifiedContract, setUserNotifiedContract]= useState(false);

    useEffect(() => {
        if ((validationCodes && validationCodes.length > 0) || payout_inconsistencies !== '' || validation_codes_warning !== '') setShow(true);
    }, [validationCodes, payout_inconsistencies]);

    useEffect(() => {
        if(hasEditRight===true && (contractCheck===1 || contractCheck===2)){
            setShowNotificationModal(true);
        }
    }, [hasEditRight]);

    const billFormRef = useRef<HTMLFormElement>(null);

    useEffect(() => {
        if(!bill) return;
        if (!!bill?.damage_bill_id && damage) {
            dispatch(fetchValidationJsonData({damage_bill_id: bill?.damage_bill_id}));
        }
    }, [bill?.damage_bill_id, dispatch, bill, damage])

    const onBillFormSubmit = useCallback(async (e) => {
        e.preventDefault();
        dispatch(
            updateBill(
                {
                    billId: bill.damage_bill_id,
                    data: (() => {
                        const data = new FormData(billFormRef.current);
                        const l = {}
                        for (const pair of data.entries()) {
                            l[pair[0]] = pair[1];
                        }
                        return l;
                    })()
                }
            )
        )
    }, [dispatch, bill]);

    return (<BillFormContext.Provider value={{
        setBillFormClassNameForZoomedItems,
        manualHeaderId,
        manualPositionsId,
        setManualHeaderId,
        setManualPositionsId,
        billFormRef
    }}>
        <Loader isLoading={isLoading && !damage}/>
        <NotificationModalContract show={showNotificationModal} setShow={setShowNotificationModal} message={contractCheck===1 ? "ACHTUNG: Der Vertrag ist ausgelaufen" : contractCheck===2 ? "ACHTUNG: Der Vertrag ist storniert " : '' }/>
        <SelectionModalContract show={showSelectionModal} setShow={setShowSelectionModal} selectionName={selectionName} formRef={billFormRef} message={"Der Vertrag ist ausgelaufen/storniert. Trotzdem abrechnen?"} setRefreshLogsIndicator={setRefreshLogsIndicator} setUserNotifiedContract={setUserNotifiedContract}/>
        {permissions && ((permissions["web-for-damage-bill-management"] && permissions["web-for-damage-bill-management"].includes("prepareForPaymentDamageBill") && permissions["web-for-damage-bill-management"].includes("updateDamagebill") && permissions["web-for-damage-bill-management"].includes("authorizeDamageBill")) || permissions.isAdmin) &&
            <ErrorMessageModal show={show} setShow={setShow} formRef={billFormRef} userNotifiedContract={userNotifiedContract} />}
        <Toaster/>
        {error
            ? <h4>Bill with id '{error}' does not exist</h4>
            : <Form id="billForm"
                    ref={billFormRef}
                    onKeyDown={(e: KeyboardEvent) => {
                        if (e.key === 'Enter') {
                            e.preventDefault()
                        }
                    }}
                    onSubmit={onBillFormSubmit}
                    className={billFormClassNameForZoomedItems}>
                <DamageInfo refreshLogsIndicator={ refreshLogsIndicator} setRefreshLogsIndicator={setRefreshLogsIndicator}/>

                {/*This is for Bill Content*/}
                <div className={"InvGenerals"}>
                    <FormBills/>
                </div>


                <div className={"billButtonsHolder"}>
                    {permissions && ((permissions["web-for-damage-bill-management"] && permissions["web-for-damage-bill-management"].includes("toggleDamagebillDelete") && permissions["web-for-damage-bill-management"].includes("updateDamagebill")) || permissions.isAdmin) &&
                        bill?.processing_status < BILL_PROCESSING_STATUSES.BILL_PAYED && <DeleteBillButton/>}

                    {hasEditRight && permissions && ((permissions["web-for-damage-bill-management"] && permissions["web-for-damage-bill-management"].includes("updateDamagebill")) || permissions.isAdmin) &&
                    <>
                        {allowDeclineButton &&
                            permissions && ((permissions["web-for-damage-bill-management"] && permissions["web-for-damage-bill-management"].includes("declineDamageBill") && permissions["web-for-damage-bill-management"].includes("updateDamagebill")) || permissions.isAdmin) &&
                            <DeclineBillButton/>}
                        {allowAuthorizeButton &&
                            permissions && ((permissions["web-for-damage-bill-management"] && permissions["web-for-damage-bill-management"].includes("authorizeDamageBill") && permissions["web-for-damage-bill-management"].includes("updateDamagebill")) || permissions.isAdmin) &&
                            <AuthorizeBillButton formRef={billFormRef} setShow={setShowSelectionModal} setSelectionName={setSelectionName} />}
                        {allowPaymentButton &&
                            maxPaymentLimit < convertForeignToDomesticCurrency(paymentTotal, currency?.rateEUR) &&
                            permissions && ((permissions["web-for-damage-bill-management"] && permissions["web-for-damage-bill-management"].includes("prepareForPaymentDamageBill") && permissions["web-for-damage-bill-management"].includes("updateDamagebill")) || permissions.isAdmin) &&
                            <PrepareBillForPaymentButton formRef={billFormRef} setShow={setShowSelectionModal} setSelectionName={setSelectionName} />}

                            {allowResolveButton &&
                                permissions && ((permissions["web-for-damage-bill-management"] && permissions["web-for-damage-bill-management"].includes("resolveDamageBill") && permissions["web-for-damage-bill-management"].includes("updateDamagebill")) || permissions.isAdmin) &&
                                <ResolveBillButton formRef={billFormRef}/>}

                    </>
                    }
                    {(!isManualSetup && !isReadOnly || (bill.processing_status===BILL_PROCESSING_STATUSES.BILL_PAYED && hasPermissionChangeSettlement)) && permissions && ((permissions["web-for-damage-bill-management"] && permissions["web-for-damage-bill-management"].includes("updateDamagebill")) || permissions.isAdmin) && <SaveBillB formRef={billFormRef}/>}
                    {isManualSetup &&
                        permissions && ((permissions["web-for-damage-bill-management"] && permissions["web-for-damage-bill-management"].includes("setManuallySetupData") && permissions["web-for-damage-bill-management"].includes("updateDamagebill")) || permissions.isAdmin) &&
                        <ManualSetupSubmitButton/>}
                </div>

            </Form>}
    </BillFormContext.Provider>)

}
