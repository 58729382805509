// @flow
import {AxiosResponse} from "axios";
import {damageBillUrl, damagesUrl, ocrUrl, sepaUrl} from "../ApiService";
import {client, headers} from "../../app/axiosClient";
import type {AdvancedBillSearchParams} from "../../features/SearchPagination/searchPagination.slice";
/**
 * Gets a list of paginated bill based on a query string and the number of page
 * @param queryString
 * @param pageNumber
 * @param cancelToken
 * @returns {Promise<AxiosResponse>}
 */
export function searchBills({sortedColumn, sortOrder, pageNumber, keyword, statuses}: {
    sortedColumn: string,
    sortedOrder: string,
    statuses: string[],
    keyword: string,
    pageNumber: number,
}, cancelToken?: any): Promise<AxiosResponse> {
    return client.get(damageBillUrl, {
        headers: {
            ...headers
        },
        params: {
            page: pageNumber,
            'filters[0][operator]': 'eq',
            'filters[0][property]': 'multiple_property',
            'filters[0][value]': keyword,
            'filters[1][operator]': 'in',
            'filters[1][property]': 'processing_status',
            'filters[1][value]': statuses.join(','),
            'sort[0][column]': sortedColumn,
            'sort[0][order]': sortOrder
        },
        cancelToken: cancelToken
    })
}

export function advanceSearchBills(searchParams: AdvancedBillSearchParams, cancelToken?: any): Promise<AxiosResponse> {
    return client.get(damageBillUrl, {
        headers: {
            ...headers
        },
        params: {
            'company_name': searchParams.company_name,
            'zip': searchParams.postcode,
            'hdnr': searchParams.partner_number,
            'city': searchParams.city,
            'iban': searchParams.IBAN,
            'vin': searchParams.chassis_number,
            'registration_number': searchParams.registration_number,
            'consumer_name': searchParams.customer_name,
            'filters[0][property]': 'contract_id',
            'filters[0][operator]': 'eq',
            'filters[0][value]': searchParams.contract_number,
            'filters[1][property]': 'processing_status',
            'filters[1][operator]': 'eq',
            'filters[1][value]': searchParams.processing_status !== '0' ? searchParams.processing_status : '',
            'filters[2][property]': 'default_email_address',
            'filters[2][operator]': 'eq',
            'filters[2][value]': searchParams.email_address
        },
        cancelToken: cancelToken
    })
}

export function getReadForPaymentBills(pageNumber?: number, cancelToken?: any): Promise<AxiosResponse> {
    return client.get(`${damageBillUrl}/preparedforpayment`, {
        headers: {
            ...headers
        },
        params: {
            page: pageNumber
        },
        cancelToken
    })
}

export function sendBillsForPayment(billIds: number[] | string[], selectAll: boolean, billIdsInconsistencies: number[] | string[], cancelToken?: any): Promise<AxiosResponse> {
    let billIdsStr = selectAll ? '' : billIds.join(',');
    let billIdsStrInconsistencies = billIdsInconsistencies.join(',');
    const d = new FormData();
    d.append('bills', billIdsStr);
    d.append('bills_inconsistencies', billIdsStrInconsistencies);
    d.append('all_selected', selectAll)
    return client.put(`${sepaUrl}`, d, {
        headers: {
            ...headers
        },
        cancelToken,
        timeout: 60000
    })
}

export function getDeductionCode(cancelToken?: any): Promise<AxiosResponse> {
    return client.get(`${damageBillUrl}/deductioncodes`, {
        headers: {
            ...headers
        },
        cancelToken: cancelToken
    })
}

// export function getDamages({
//     damageNumber,
//     contractNumber,
//     chassisNumber
// }: { damageNumber: number, contractNumber: number, chassisNumber: number }, cancelToken?: any): Promise<AxiosResponse> {
//     return client.get(`${damagesUrl}/search`, {
//         headers: {
//             ...headers
//         },
//         params: {
//             damage_number: damageNumber,
//             contract_number: contractNumber,
//             chassis_number: chassisNumber
//         },
//         cancelToken: cancelToken
//     })
// }
// Temp fix, use one above instead
export function getDamages(damageNumber: number, contractNumber: number, chassisNumber: number, cancelToken?: any): Promise<AxiosResponse> {
    return client.get(`${damagesUrl}/search`, {
        headers: {
            ...headers
        },
        params: {
            damage_number: damageNumber,
            contract_number: contractNumber,
            chassis_number: chassisNumber
        },
        cancelToken: cancelToken
    })
}

export function getFraudInfo(damageId: number, billContactReason, cancelToken?: any): Promise<AxiosResponse> {
    const newData = {[damageId]:billContactReason}
    const fd= new FormData();
    fd.append("data", JSON.stringify(newData));

    return client.post(`${damagesUrl}/fraudchk`, fd, {
        headers: {
            ...headers
        },
        cancelToken: cancelToken
    })
}

export function bindClaimToBill(damageBillId: number, fd: FormData, cancelToken?: any): Promise<AxiosResponse> {
    return client.put(`${damageBillUrl}/${damageBillId}/updatedamageid`, fd, {
        headers: {
            ...headers
        },
        cancelToken: cancelToken
    })
}

export function sendBillsToOCR(billIds: (number[] | string[]), all_selected: boolean, bills_versions_uncheck: (number[] | string[]), cancelToken?: any): Promise<AxiosResponse> {
    const fd = new FormData();
    billIds.forEach(billId => {
        fd.append('bills_versions[' + billId + ']', billId);
    })
    fd.append('all_selected', all_selected);
    bills_versions_uncheck.forEach(billId => {
        fd.append('bills_versions_uncheck[' + billId + ']', billId);
    })
    return client.put(ocrUrl, fd, {
        headers: {
            ...headers,
        },
        cancelToken: cancelToken
    })
}
export function sendBillsForManuelMerge(billIds: (number[] | string[]), mainBillId: number | string, cancelToken?: any): Promise<AxiosResponse> {
    const fd = new FormData();
    billIds.forEach(billId => {
        fd.append('bills_versions[' + billId + ']', billId);
    })
    return client.put(`${damageBillUrl}/mergebills/${mainBillId}`, fd, {
        headers: {
            ...headers,
        },
        cancelToken: cancelToken
    })
}
export function sendBillsForAutomaticMerge(billIds: (number[] | string[]), mainBillId: number | string, cancelToken?: any): Promise<AxiosResponse> {
    const fd = new FormData();
    billIds.forEach(billId => {
        fd.append('bills_versions[' + billId + ']', billId);
    })
    return client.put(`${damageBillUrl}/mergebills/${mainBillId}?automatic=true`, fd, {
        headers: {
            ...headers,
        },
        cancelToken: cancelToken
    })
}
export function mergeBillsRollback(mainBillId: number | string): Promise<AxiosResponse> {
    return client.put(`${damageBillUrl}/mergebillsrollback/${mainBillId}`)
}

export function sendEmailsPaymentLetter(damageBillId: number, textValue: string, cancelToken?: any): Promise<AxiosResponse> {
   const fd= new FormData();
    fd.append('email_address', textValue);
    return client.put(`${damageBillUrl}/customemailinvoicereport/${damageBillId}`, fd, {
        headers: {
            ...headers
        },
        cancelToken: cancelToken
    })
}