// @flow
import * as React from 'react';
import {useCallback, useEffect, useRef, useState} from "react";
import Autocomplete from "react-autocomplete";
import {Form} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {setShop} from "../../../features/DamageBill/bill.slice";
import {searchCarDealers, searchExternalProviders} from "../../../services/API/carDealer.service";
import {
    fetchCarDealerSoldBill,
    fetchExternalProvider,
    fetchValidationJsonData
} from "../../../features/DamageBill/bill.actions";
import {getCarDealerFullName} from "../../BillContext";
import InView from "../../InView";
import {useSearchPaginator} from "../../contract/BillsList/useSearchPaginator";
import './ShopAutoComplete.css';
import {usePopper} from "react-popper";

type Props = {
    errorMessage?: string
};

export function ShopAutoComplete({stateChanger, errorMessage: errorMessageProp, ...props}: Props) {
    const {
        shopAutoCompleteValue,
        isShopDisabled,
        shopAutoCompleteId,
        carDealerSource,
        isReadOnly,
        carDealerWhoSentBill,
        externalProvider,
        bill, damage
    } = useSelector(state => state.damageBill)
    const dispatch = useDispatch();
    const [shops, setShops] = useState([]);
    const [errorMessage, setErrorMessage] = useState<string>(errorMessageProp);

    const inputRef = useRef(null);
    const tooltipRef = useRef(null);
    const {styles, attributes, update} = usePopper(inputRef.current, tooltipRef.current, {
        placement: 'bottom',
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [0, 8],
                },
            },
            {
                name: 'arrow',
            },
        ],
    });

    useEffect(() => {
        setErrorMessage(errorMessageProp)
        return () => {
            setErrorMessage(undefined)
            if (update) update()
        }
    }, [errorMessageProp, update])
    /**
     * each time we update bill remove validation errors
     */
    useEffect(() => {
        setKeyword('');
        setErrorMessage(undefined)
        if (update) update()
    }, [bill, update])
    useEffect(() => {
        setKeyword(shopAutoCompleteValue || '')
    }, [bill])


    // actually if carDealerSource should be 1 for car dealer and only 2 for external providers
    // but in all other cases we have this field disabled, so it is ok
    const [keyword, setKeyword] = useState('');
    const [statuses] = useState([]);
    const [, , data, , nextPage] = useSearchPaginator(keyword, statuses, carDealerSource === 1 ? searchCarDealers : searchExternalProviders, 'cardealers');

    useEffect(() => {
        if (!data) return;
        setShops(data.map(rec => ({
            value: rec.id,
            label: getCarDealerFullName(rec)
        })));
    }, [data])

    const handleOnChange = useCallback(async (e) => {
        dispatch(setShop({
            value: e.target.value
        }));
        setKeyword(e.target.value);
        if (e.target.value === '' && !!bill?.damage_bill_id && damage)
            dispatch(fetchValidationJsonData({damage_bill_id: bill?.damage_bill_id, iban: '', bic: ''}));
    }, [dispatch, damage, bill?.damage_bill_id]);

    function getItemValue(item) {
        return item.label + '->' + item.value;
    }

    const onSelect = useCallback((val) => {
        let result = val.split('->');
        let selectedCarDealerId = parseInt(result[1]);

        /**
         * This will be overwritten by the next dispatch below
         * Although this dispatch might seem redundant keeping it, will conclude to better UX
         * (Image fetching data is too slow, then user will not see that he selected an item right away)
         */
        dispatch(setShop({
            value: result[0],
            id: selectedCarDealerId
        }))


        /**
         * We need to check what to fetch for the selected id
         */
        if (carDealerSource === 1) {
            if (selectedCarDealerId !== carDealerWhoSentBill?.id) {
                dispatch(fetchCarDealerSoldBill(selectedCarDealerId))
            }
        } else if (carDealerSource === 2) {
            if (selectedCarDealerId !== externalProvider?.id) {
                dispatch(fetchExternalProvider(selectedCarDealerId))
            }
        }


    }, [carDealerWhoSentBill, dispatch, externalProvider, carDealerSource])

    function renderInput({disabled, ...props}) {
        return <input style={{background: disabled ? '#f2f4f4' : 'transparent'}} disabled={disabled}  {...props}/>
    }

    function renderItem(item, isHighlighted) {
        return (
            <div key={item.value} style={{background: isHighlighted ? 'whitesmoke' : 'white', cursor: 'pointer'}}>
                {item.label}
            </div>
        );
    }

    // this way of rendering is not ideal
    // it is a temp fix until we start redesigning
    function renderMenu(items) {
        const menuStyle = {
            borderRadius: '3px',
            boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
            background: 'rgba(255, 255, 255, 0.9)',
            padding: '2px 0',
            fontSize: '90%',
            position: 'absolute',
            overflow: 'auto',
            width: '100%',
            height: 'auto',
            maxHeight: '300px',
            top: '35px',
            left: 0,
            zIndex: 2,
        }

        return <div id={'shop-autocomplete'} className={'shop-autocomplete'} style={{...menuStyle}}>
            {items.slice(0, items.length - 1).map(item => item)}
            {
                items && items?.length > 0 && <InView onceOnIntersect={nextPage}> {items[items.length - 1]}</InView>
            }

        </div>
    }

    const customStyleError = {
        position: 'relative',
        width: '60%',
        marginTop: '7px',
        marginInline: 'auto'

    }
    const customStyleArrow = {
        position: 'absolute',
        top: '-14%',
        left: '50%'
    }
    const {showSidebar} = useSelector(state => state.searchPagination);

    return (
        <div className={"Field-Input-Shop"} style={{flex: showSidebar ? '2 1 380px' : '2 1 450px'}}>
            <Form.Group>
                <Form.Label className={"Input-label"} style={{marginBottom: "1px"}}>Autohaus *</Form.Label>
                <div className={"Field-Input-Text"} style={{height: "32px"}}>
                    <input type="hidden" name={props['name']} value={shopAutoCompleteId || ''}/>
                    <Autocomplete value={shopAutoCompleteValue}
                                  field="shopId"
                                  items={shops}
                                  getItemValue={getItemValue}
                                  onChange={handleOnChange}
                                  onSelect={onSelect}
                                  renderMenu={renderMenu}
                                  wrapperStyle={{
                                      position: 'relative',
                                      minWidth: '351px',
                                      zIndex: '3'
                                  }}
                                  ref={inputRef}
                                  renderInput={(props) => renderInput({disabled: isReadOnly || isShopDisabled, ...props})}
                                  renderItem={renderItem}/>
                    <div className={`popper-tooltip ${!errorMessage && 'popper-tooltip-hidden'}`} ref={tooltipRef}
                         style={{...styles.popper, ...customStyleError}} {...attributes.popper}>
                        {errorMessage && errorMessage.isEmpty}
                        <div className="arrow" data-popper-arrow
                             style={{...styles.arrow, ...customStyleArrow}} {...attributes.arrow}/>
                    </div>

                </div>


            </Form.Group>


        </div>
    );
}
