// @flow
import React, {useCallback, useEffect, useState} from "react";
import {useTablePaginator} from "../Pay/useTablePaginator";
import {Bill} from "../../components/BillContext";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import DocumentService from "../../services/DocumentService";
import dateFormat from "dateformat";
import {getSepaFileDownloads, getSepaFiles, getSepaXmlFile} from "../../services/API/sepafiles.service";
import '../Pay/pay_table.css';
import InView from "../../components/InView";
import toast, {Toaster} from "react-hot-toast";

function SepaPage() {
    const [, hasError, filesPaginated, hasMore, total, , nextPage] = useTablePaginator(getSepaFiles, 'sepafiles');
    const [files, setFiles] = useState<Array<Bill>>([]);
    const [isModalOpen, setModalOpen] = useState<boolean>(false);
    const [downloadRecords, setDownloadRecords] = useState<[]>([]);
    const permissions = JSON.parse(localStorage.getItem('user-permissions'));

    const onInView = useCallback(() => {
        if (hasMore && !hasError) nextPage()
    }, [hasMore, hasError, nextPage]);


    const handleCloseModal = () => {
        setModalOpen(false);
    };

    useEffect(() => {
        setFiles(filesPaginated)
    }, [filesPaginated])

    const canDownload = permissions && ((permissions["web-for-damage-bill-management"] && permissions["web-for-damage-bill-management"].includes("getSingleSepaFileXml")) || permissions.isAdmin);
    const canSeeDownloads = permissions && ((permissions["web-for-damage-bill-management"] && permissions["web-for-damage-bill-management"].includes("getAllSepaFileDownloads")) || permissions.isAdmin);

    const downloadSepaXmlFile = useCallback(async (id) => {
        try {
            const {status, data} = await getSepaXmlFile(id);
            const {data: dataDownloads} = canSeeDownloads ? await getSepaFileDownloads(id) : {data: null};

            if (status === 200) {
                setFiles(prev => prev.map(file => file.id === id ? {
                    ...file,
                    downloads_count: data.downloads_counter,
                    last_download_date: canSeeDownloads ? dataDownloads._embedded?.records[0]?.download_date : '',
                    last_download_by_user: canSeeDownloads ? dataDownloads._embedded?.records[0]?.created_by_user : ''
                } : file))
            }
            if (data.file) {
                DocumentService.requestDownload(data.file, data.filename, 'application/xml')
            }
        } catch (e) {
            if(e.response.status>=500 && e.response.status<=599){
                toast.error(e.response?.statusText);
            }
        }
    }, [])

    const getFileDownloads = useCallback(async (id) => {
        try {
            const {data} = await getSepaFileDownloads(id)
            setDownloadRecords(data?._embedded?.records);
            setModalOpen(true);
        } catch (e) {
            if(e.response?.status>=500 && e.response?.status<=599){
                toast.error(e.response?.statusText);
            }
        }
    }, [])

    return permissions && ((permissions["web-for-damage-bill-management"] && permissions["web-for-damage-bill-management"].includes("getAllSepaFiles")) || permissions.isAdmin) ?
        <>
        <Modal className={"ModalStyle"} show={isModalOpen} onHide={handleCloseModal}>
            <Modal.Header closeButton>
                <Modal.Title>Downloaded von</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                    <div className={'payment-page'} style={{
                        width: 'calc(100% - 2rem)',
                        display: 'flex',
                        flexDirection: 'column',
                        margin: '1rem',
                        maxHeight: '70vh',
                        border: '1px solid rgba(0,0,0,.12)',
                        borderRadius: '11px',
                        overflow: 'auto'
                    }}>

                    <table>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Erstellt von</th>
                            <th>Download bei</th>
                        </tr>
                        </thead>
                        <tbody>
                        {downloadRecords.map((record, index) => {
                            return <tr key={record.sepa_file_id}>
                                <td>{index + 1}</td>
                                <td>{record.created_by_user || '-'}</td>
                                <td>{record.download_date?.date ? dateFormat(record.download_date.date, 'dd.mm.yyyy h:mm TT') : '-'}</td>
                            </tr>
                        })}
                        </tbody>
                    </table>
                    <div className={'table-footer'}>
                        <div className={'table-footer-inner'}>{downloadRecords.length} Eregbnisse</div>
                    </div>
                </div>

                </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                    Schließen
                </Button>
            </Modal.Footer>
        </Modal>


        <div className={"payment-page"}>
            <Toaster/>
            <div style={{
                width: 'calc(100% - 2rem)',
                display: 'flex',
                flexDirection: 'column',
                margin: '1rem',
                maxHeight: '69.5vh',
                border: '1px solid rgba(0,0,0,.12)',
                borderRadius: '11px',
                overflow: 'auto',
                cursor: 'pointer'
            }}>

                    <table>
                        <thead>
                        <tr>
                            <th>#</th>
                            {canDownload && <th>Datei</th>}
                            <th>Mandant</th>
                            <th>Anzahl Rechnungen</th>
                            <th>Summe bezahlt</th>
                            <th>Ausland</th>
                            <th>Erstellt von</th>
                            <th>Erstellt am</th>
                            {canSeeDownloads && <th>Heruntergeladen von</th>}
                            {canSeeDownloads && <th>Heruntergeladen am</th>}
                            {canSeeDownloads && <th>Downloads</th>}
                        </tr>
                        </thead>
                        <tbody>
                        {files.length === 0 ? <tr style={{padding: '1rem'}}>
                                <td colSpan={7}>Keine eregbnisse</td>
                            </tr> :
                            files.map((file, index) => {
                                return index !== Math.round(files.length / 1.2) ? <tr key={file.file_name}>
                                    <td>{index + 1}</td>
                                    {canDownload && <td className='with-icon with-action'
                                                        onClick={downloadSepaXmlFile.bind(this, file.id)}>
                                        <span>{`schaden_${file.mandnr}_${file.date_created.date.split(' ')[0].replace(/-/g, '_')}_${file.foreign_currency}`}</span>
                                    </td>}
                                    <td>{file.mandnr}</td>
                                    <td>{file.bills_count}</td>
                                    <td>{file.total_for_paying} {file.foreign_currency}</td>
                                    <td>{file.currency_abroad === 1 ? 'Yes' : 'No'}</td>
                                    <td>{file.created_by_user || '-'}</td>
                                    <td>{file.date_created?.date ? dateFormat(file.date_created.date, 'dd.mm.yyyy') : '-'}</td>
                                    {canSeeDownloads && <td onClick={getFileDownloads.bind(this, file.id)}>{file.last_download_by_user}</td>}
                                    {canSeeDownloads && <td>{file.last_download_date?.date ? dateFormat(file.last_download_date?.date,'dd.mm.yyyy' ): '-'}</td>}
                                    {canSeeDownloads && <td className={'with-action'}
                                                            onClick={getFileDownloads.bind(this, file.id)}>{file.downloads_count || 0}</td>}
                                </tr> : <InView as={'tr'} key={file.file_name} onceOnIntersect={onInView}>
                                    <td>{index + 1}</td>
                                    {canDownload && <td className='with-icon with-action'
                                                        onClick={downloadSepaXmlFile.bind(this, file.id)}>
                                        <span>{`schaden_${file.mandnr}_${file.date_created.date.split(' ')[0].replace(/-/g, '_')}_${file.foreign_currency}`}</span>
                                    </td>}
                                    <td>{file.mandnr}</td>
                                    <td>{file.bills_count}</td>
                                    <td>{file.total_for_paying}</td>
                                    <td>{file.created_by_user || '-'}</td>
                                    <td>{file.date_created?.date ? dateFormat(file.date_created.date, 'dd.mm.yyyy') : '-'}</td>
                                    {canSeeDownloads && <td onClick={getFileDownloads.bind(this, file.id)}>{file.last_download_by_user}</td>}
                                    {canSeeDownloads && <td>{file.last_download_date?.date ? dateFormat(file.last_download_date?.date,'dd.mm.yyyy' ): '-'}</td>}
                                    {canSeeDownloads && <td className={'with-action'}
                                                            onClick={getFileDownloads.bind(this, file.id)}>{file.downloads_count || 0}</td>}
                                </InView>
                            })}
                        </tbody>
                    </table>
                    <div className={'table-footer'}>
                        <div className={'table-footer-inner'}>Eregbnisse 1 - {filesPaginated.length} of {total}</div>
                    </div>
                </div>
            </div>
        </> : <div>Sie dürfen diesen Inhalt nicht sehen</div>
}

export default SepaPage;