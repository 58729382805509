// @flow
import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {Input} from "reactstrap";
import {useSelector} from "react-redux";
import useApi from "../../../hooks/useApi";
import {getContactReasons} from "../../../services/API/carDealer.service";
import {GeneralTranslations} from "../../../translations/General/GeneralTranslations";
import {usePopper} from "react-popper";

// const options = [
//     {value: 1, label: 'repair costs'},
//     {value: 2, label: 'towing costs'},
//     {value: 3, label: 'lawyer costs'},
//     {value: 4, label: 'legal costs'},
//     {value: 5, label: 'goodwill'},
//     {value: 6, label: 'VAT (Value added tax)'},
//     {value: 7, label: 'additional payment'},
//     {value: 8, label: 'damage recovery'},
//     {value: 9, label: 'Settlement payment'},
//     {value: 10, label: 'mobility'},
//     {value: 11, label: 'translation costs'},
// ];

interface IContactReason {
    contact_reason: string;
    contact_reason_id: number;
    created_by_user_id: number;
    date_created: { date: string, timezone_type: number, timezone: String };
    id: number;
    is_deleted: 0 | 1;
    language_id: number;
}

type Props = {
    errorMessage?: string,
};
export function ContactReason({errorMessage: errorMessageProp}: Props) {
    const {bill, isReadOnly} = useSelector(state => state.damageBill)
    const [errorMessage, setErrorMessage] = useState<string>(errorMessageProp);
    const [selectedReason, setSelectedReason] = useState<number>(bill?.bill_contact_reason || null)
    const [reasons, setReasons] = useState<IContactReason[]>([]);
    const {request, data} = useApi(getContactReasons, 'damagebills');
    const permissions = JSON.parse(localStorage.getItem('user-permissions'));
    const hasPermission= permissions && ((permissions["web-for-damage-bill-management"] && permissions["web-for-damage-bill-management"].includes("changeSettlementType")) || permissions.isAdmin);

    const [validFieldClass, setValidFieldClass] = useState('');
    const inputRef = useRef(null);
    const tooltipRef = useRef(null);
    const {styles, attributes, update} = usePopper(inputRef.current, tooltipRef.current, {
        placement: 'bottom',
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [0, 8],
                },
            },
            {
                name: 'arrow',
            },
        ],
    });

    useEffect(() => {
        setErrorMessage(errorMessageProp)
        return () => {
            setErrorMessage(undefined)
            if (update) update()
        }
    }, [errorMessageProp, update])


    /**
     * each time we update bill remove validation errors
     */
    useEffect(() => {
        setErrorMessage(undefined)
        setValidFieldClass('')
        if (update) update()
    }, [bill, update])

    useEffect(() => {
        if (!data || !data?._embedded) return;
        setReasons(data._embedded.records)
    }, [data])


    useEffect(() => {
        setSelectedReason(bill?.bill_contact_reason || null)
    }, [bill?.bill_contact_reason])

    useEffect(() => {
        request();
    }, [bill, request])


    return (
        <div className={"Field-Input-Drop"} style={{flex: '1 1 178px'}}>
            <label className={"Input-label"} style={{marginBottom: 0}}>Zahlungsart {bill?.does_car_dealer_use_rg_funds===true ? '*': ''}</label>
            <div className={"Frame-19"}>
                <div className={"Field-Filters"} ref={inputRef}>
                    <label className={"No-filters"} >
                        <Input type="select"
                               name="bill_contact_reason"
                               className={validFieldClass + " Input-text"}
                               id="bill_contact_reason"
                               value={reasons.find((b: IContactReason) => b.contact_reason_id === selectedReason) ? selectedReason : -1}
                               disabled={(bill?.processing_status===8 && hasPermission) ? false : isReadOnly}
                               style={{height: "32px", textTransform: 'capitalize'}}
                               onChange={(e) => setSelectedReason(parseInt(e.target.value))}
                        >
                            <option value={-1}
                                    disabled={bill?.does_car_dealer_use_rg_funds === true } >{GeneralTranslations.de.label_please} {GeneralTranslations.de.label_select}</option>
                            {
                                reasons.map((item: IContactReason) => {
                                    if (item.contact_reason_id === bill?.bill_contact_reason) {
                                        return (
                                            <option value={item.contact_reason_id}
                                                    key={item.contact_reason_id}>{item.contact_reason}</option>
                                        )
                                    }
                                    return (
                                        <option value={item.contact_reason_id}
                                                key={item.contact_reason_id}>{item.contact_reason}</option>
                                    )
                                })
                            }
                        </Input>

                    </label>
                    <div className={`popper-tooltip ${!errorMessage && 'popper-tooltip-hidden'}`} ref={tooltipRef}
                         style={styles.popper} {...attributes.popper}>
                        {errorMessage && (errorMessage.notGreaterThan || errorMessage.isEmpty)}
                        <div className="arrow" data-popper-arrow style={styles.arrow} {...attributes.arrow}/>
                    </div>
                </div>
            </div>

        </div>

    );
}
