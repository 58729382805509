// @flow
import * as React from 'react';
import {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchDamage} from "../../../features/DamageBill/bill.actions";
import DamageInfoItems from "./DamageInfoItems";
import BindToClaimModal from "./BindToClaimModal";
import DamageInfoCTAs from "./DamageInfoCTAs";
import useOpenWindow from "../../../hooks/useOpenWindow";
import './damage-info.css'
import {checkLoggedUser} from "../../../features/Authentication/authSlice";
import PaymentLetterModal from "./PaymentLetterModal";
import LKTModal from "./LKTModal";
import MKTModal from "./MKTModal";

function DamageInfo({refreshLogsIndicator, setRefreshLogsIndicator}) {
    const {bill, isManualSetup} = useSelector(state => state.damageBill)
    const dispatch = useDispatch();
    const [show, setShow] = useState<boolean>(false);
    const {openWindow: openDocumentsWindow, isClosed} = useOpenWindow(`/documents/${bill?.damage_bill_id}`);
    const permissions = JSON.parse(localStorage.getItem('user-permissions'));
    const canEditItems = permissions && ((permissions["web-for-damage-bill-management"] && permissions["web-for-damage-bill-management"].includes("updateDamagebill")) || permissions.isAdmin);
    const canGetDamage = permissions && ((permissions["web-for-damage-management"] && permissions["web-for-damage-management"].includes("getSingleDamage")) || permissions.isAdmin);
    const [showPaymentLetterModal, setShowPaymentLetterModal]= useState(false);
    const [showLKTModal, setShowLKTModal]= useState(false);
    const [showMKTModal, setShowMKTModal]= useState(false);


    const handleClose = useCallback(() => {
        setShow(false);
    }, []);

    const handleShow = useCallback(async () => {
        await dispatch(checkLoggedUser());
        setShow(true);
    }, [dispatch]);

    const handleClosePaymentModal = useCallback(() => {
        setShowPaymentLetterModal(false);
    }, []);

    const handleShowPaymentModal = useCallback(() => {
        setShowPaymentLetterModal(true);
    }, []);

    const handleChangeLogsIndicator = () =>{
        setRefreshLogsIndicator(prevState => prevState +1);
    }
    const handleCloseMKTModal = useCallback(() => {
        setShowMKTModal(false);
    }, []);
    const handleCloseLKTModal = useCallback(() => {
        setShowLKTModal(false);
    }, []);
    const handleShowMKTModal = useCallback(() => {
        setShowMKTModal(true);
    }, []);
    const handleShowLKTModal = useCallback(() => {
        setShowLKTModal(true);
    }, []);

    useEffect(() => {

        /**
         * It seems that there is a bug with Firefox v95.0.2
         * and calling window.open throws "Should not already be working." error message from
         * performSyncWorkOnRoot function.
         * Wrapping it with setTimeout fixes the issue
         * Note: calling 'openDocumentsWindow' from an event callback does not throw an error
         */
        dispatch(checkLoggedUser());
        setTimeout(() => {
            if (bill?.damage_bill_id && !isClosed && permissions && ((permissions["web-for-damage-bill-management"] && permissions["web-for-damage-bill-management"].includes("getSingleDamagebillDocument")) || permissions.isAdmin)) {
                openDocumentsWindow();
            }
        }, 0)
    }, [openDocumentsWindow, bill, isClosed]);

    /**
     * When 'bill' is available or changed
     * fetch data based on 'damage_id' if it is available
     */
    useEffect(() => {
        if (!bill) return;
        const {damage_id} = bill.damage_bill;

        if (damage_id && canGetDamage) {
            dispatch(fetchDamage({damage_id, mileage: bill.vehicle_mileage_after_reparation}))
        }
    }, [dispatch, bill])

    return (
        <div className={"DamageInfo"}>
            {!isManualSetup &&
                permissions && ((permissions["web-for-damage-bill-management"] && permissions["web-for-damage-bill-management"].includes("updateBillDamageNumber")) || permissions.isAdmin) &&
                <BindToClaimModal show={show} onClose={handleClose}/>}
            {!isManualSetup &&
                permissions && ((permissions["web-for-damage-bill-management"] && permissions["web-for-damage-bill-management"].includes("customEmailInvoiceReportForBill")) || permissions.isAdmin) &&
                <PaymentLetterModal show={showPaymentLetterModal} onClose={handleClosePaymentModal} changeIndicator={handleChangeLogsIndicator} />}
            {!isManualSetup && showLKTModal && (bill?.processing_status >= 1 && bill?.processing_status <= 5) && canEditItems && <LKTModal show={showLKTModal} onClose={handleCloseLKTModal}/>}
            {!isManualSetup && showMKTModal && (bill?.processing_status >= 1 && bill?.processing_status <= 5) && canEditItems && <MKTModal show={showMKTModal} onClose={handleCloseMKTModal}/>}
            <DamageInfoCTAs showClaimsModal={handleShow}
                            showDocumentsWindow={openDocumentsWindow} showPaymentLetterModal={handleShowPaymentModal} changeIndicator={handleChangeLogsIndicator} refreshLogsIndicator={refreshLogsIndicator}/>
            {!isManualSetup && canGetDamage && <DamageInfoItems showMKTModal={handleShowMKTModal} showLKTModal={handleShowLKTModal}/>}

        </div>
    );
}

export default DamageInfo;

