import {useCallback, useEffect, useRef, useState, useMemo} from "react";
import Modal from "react-modal";
import {Form, Button} from "react-bootstrap";
import * as React from "react";
import {UserForm} from "./UserForm";
import useApi from "../../hooks/useApi";
import {
    createUser,
    getUserRoles,
    getUsers,
    updateUser,
    updateUserRoles
} from "../../services/API/users.service";
import "./Users.css";
import {useQuery} from 'react-query'
import {ListRow} from "./ListRow";
import {ListRowCheck} from "../permissions/ListRowCheck";
import {getRolesWithPermissionCount} from "../../services/API/roles.service";
import toast, {Toaster} from "react-hot-toast";
import {BarStyling} from "../bills/List/SearchBar";
import {LoadingSpinner} from "../general/LoadingSpinner";

function getInBetweenPages(first: number, last: number, curr: number) {
    const options = [...Array(last).keys()].map((i) => Number(i + first));
    const front = options.slice(0, 3);
    const back = options.slice(last - 3, last);
    const currFront = options.slice(Math.max(curr - 2, 1), Math.min(curr + 1, last));

    return [...new Set([...front, ...back, curr, ...currFront])].sort((a, b) => a - b);
}

const Pagination: React.FC<{
    page: number;
    setPage: React.Dispatch<React.SetStateAction<number>>;
    pages?: number;
    hasNext?: object;
}> = ({page, setPage, pages}) => {
    const pagesBetween = useMemo(() => getInBetweenPages(1, pages || 1, page || 1), [
        pages, page
    ]);

    return (
        <div style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: 5
        }}>
            {pagesBetween.map((pageB) => (
                <Button key={pageB} disabled={pageB === page}
                        variant="outline-primary"
                        onClick={() => setPage(pageB)}
                        size={'sm'}>
                    {pageB}
                </Button>
            ))}
        </div>
    );
};
export default Pagination;

const initState = {
    id: 0,
    username: '',
    password: '',
    first_name: '',
    last_name: '',
    title_id: 0,
    email: '',
    mobile: '',
    phone: '',
    address: '',
    zip_code_id: 0,
    language_id: 0,
    is_active: true,
    is_deleted: false,
    working_countries: [],
};

export const useCarDealers = (page: number, filter?: string) => {
    const permissions = JSON.parse(localStorage.getItem('user-permissions'));
    const q = useQuery(
        ['users', filter || 'all', page],
        () => getUsers(filter || '', page),
        {
            keepPreviousData: true,
            enabled: permissions && ((permissions["web-for-user-management"] && permissions["web-for-user-management"].includes("getAllUsers")) || permissions.isAdmin)
        },
    );

    return {
        ...q,
        data: q.data?.data,
        pages: q.data?.data._page_count,
        hasNext: q.data?.data._links.next,
        hasPrev: q.data?.data._links.prev,
    };
};


export function List() {
    let [userState, setUserState] = useState(initState);

    const [keyword, setKeyword] = useState('');
    const [page, setPage] = useState(1);
    const permissions = JSON.parse(localStorage.getItem('user-permissions'));
    const {data: users, pages, hasNext} = useCarDealers(page, keyword);

    // each time filterChanges, page needs to restart from 1
    useEffect(() => {
        setPage(1);
    }, [keyword]);

    const [isOpen, setIsOpen] = useState(false);
    const [isOpenRolesModal, setIsOpenRolesModal] = useState(false);
    const userRolesFormRef = useRef();
    let [checkedIds, setCheckedIds] = useState([]);
    const {request: addUserRequest, error: addUserError, data: addUserData, canceled: addUserCanceled} = useApi(createUser, 'users');
    const {request: updateUserRequest, error: updateUserError, data: updateUserData, canceled: updateUserCanceled} = useApi(updateUser, 'users');
    const {data: addUserRolesData, request: updateUserRolesRequest, error: addUserRolesError, canceled: addUserRolesCanceled} = useApi(updateUserRoles, 'users');
    const {data: getUserRolesData, request: getUserRolesRequest, error: getUserRolesError, loading:getUserRolesLoading} = useApi(getUserRoles, 'users');

    useEffect(() => {
        if (addUserRolesCanceled || updateUserCanceled || addUserCanceled) toast.error('Request to service users was canceled, time exceeded', 'users');
    }, [addUserRolesCanceled, updateUserCanceled, addUserCanceled]);

    const {request: getRolesRequest, error: getRolesError, data: allRoles, loading: getRolesLoading} = useApi(getRolesWithPermissionCount, 'roles');

    const toggleModalRoles = useCallback(() => {
        setIsOpenRolesModal(isOpenRolesModal => !isOpenRolesModal);
    }, []);

    const toggleModal = useCallback(() => {
        if (!isOpen) {
            setUserState({...initState});
        }
        setIsOpen(isOpen => !isOpen);
    }, [isOpen]);

    useEffect(() => {
        if (!updateUserData) return;
        if(updateUserData && (updateUserData.code === 200 || updateUserData.code === 204)) {
            toast.success(updateUserData.message);
            toggleModal();
        } else {
            updateUserError && toast.error(updateUserError);
            toast.dismiss(updateUserError);
        }
        }, [updateUserError, updateUserData])

    useEffect(() => {
        if (!addUserData) return;
        if(addUserData && (addUserData.code === 200 || addUserData.code === 204)) {
            toast.success(addUserData.message);
            toggleModal();
        }
        else {
            addUserError && toast.error(addUserError);
            toast.dismiss(addUserError);
        }
    }, [addUserError, addUserData])

    useEffect(() => {
        if (addUserRolesData !== null && addUserRolesData.code === 200) {
            toast.success(addUserRolesData.message);
            toggleModalRoles();
        } else if (addUserRolesError) {
            alert(addUserRolesError);
        }
    }, [addUserRolesData, toggleModalRoles, addUserRolesError]);

    useEffect(() => {
        if (!getUserRolesData || !allRoles) return;
        if (getUserRolesError) {
            toast.error(getUserRolesError);
        } else if (getRolesError) toast.error(getRolesError);
        else {
            if (!getUserRolesData._embedded || !allRoles.data) return;
            let tmpCheckedIds = getUserRolesData._embedded.records.map(item => item.id);
            setCheckedIds(tmpCheckedIds);
            toggleModalRoles();
        }
    }, [getUserRolesData, toggleModalRoles, getUserRolesError]);

    const onUserRolesFormSubmit = useCallback((e) => {
        e.preventDefault();
        const data = new FormData(userRolesFormRef.current);
        if (checkedIds.length === 0) {
            toast.error('Sie müssen mindestens eine Rolle auswählen');
            return;
        }
        data.set("role_ids", checkedIds);
        updateUserRolesRequest({data, id: data.get("id")});
    }, [updateUserRolesRequest, checkedIds]);

    const onUserFormSubmit = useCallback(async (user) => {
        if (user.id > 0) {
            await updateUserRequest({user, id: user.id});
            // refresh();
        } else {
            await addUserRequest({user});
            // refresh();
        }
    }, [updateUserRequest, addUserRequest]);

    const loadForEdit = useCallback(({password, ...rest}) => {
        setUserState({...rest});
        setIsOpen(true);
    }, []);

    const loadRoles = useCallback((item) => {
        setUserState({...item});
        setCheckedIds([]);
        getRolesRequest();
        getUserRolesRequest({id: item.id});
    }, [getUserRolesRequest, getRolesRequest]);

    const handleOnCheckChange = (id, isChecked) => {
        if (isChecked) {
            setCheckedIds(id);
        }
    }

    return permissions && ((permissions["web-for-user-management"] && permissions["web-for-user-management"].includes("getAllUsers")) || permissions.isAdmin) ?
        <div className={"list-container"}>
            <div className={'toolbox'}>
                    <input
                        type={"text"}
                        style={{...BarStyling, borderRadius: '5px'}}
                        key="random3wq"
                        value={keyword}
                        name={"keyword"}
                        onChange={(e) => setKeyword(e.target.value)}
                        placeholder={"Suche nach Benutzername"}
                    />
            </div>
            <Toaster />
            {/*<div className={"toolbox"}>*/}
            {/*    <button type="button" className={"Button-Add btn btn-primary"} onClick={toggleModal}>Add user</button>*/}
            {/*</div>*/}
            <div>
                <table>
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>E-Mail</th>
                        <th>Rolle(n)</th>
                        <th>Aktionen</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        !!users && users?._embedded?.records?.map((item, index) => {
                            return (
                                <ListRow key={"user_item_" + index} item={item} index={index}
                                         onEdit={loadForEdit.bind(this, item)}
                                         onEditRoles={loadRoles.bind(this, item)}/>
                            )
                        })
                    }
                    </tbody>
                </table>
            </div>
            <div style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                padding: '1rem 0',
                gap: '1em'
            }}>
                <span>
                    Seiten:
                </span>
                <Pagination page={page} setPage={setPage} hasNext={hasNext} pages={pages}/>
            </div>

            <Modal isOpen={isOpen}
                   onRequestClose={toggleModal}
                   overlayClassName={"modal-overlay"}
                   className={"modal-wide"}
                   appElement={document.getElementsByTagName('body')}
                   contentLabel="User">
                <UserForm toggleModal={toggleModal} formSubmit={onUserFormSubmit} userData={userState}/>
            </Modal>
            <Modal isOpen={(getRolesLoading || getUserRolesLoading) && !isOpenRolesModal} overlayClassName={"modal-overlay"} className={"modal-fullscreen"} appElement={document.getElementsByTagName('body')}>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}><LoadingSpinner isSpinning={getRolesLoading || getUserRolesLoading} />
                </div>
            </Modal>
            <Modal isOpen={isOpenRolesModal}
                   className={"modal-fullscreen"}
                   onRequestClose={toggleModalRoles}
                   overlayClassName={"modal-overlay"}
                   appElement={document.getElementsByTagName('body')}
                   contentLabel="Rolle berechtigungen">
                <Form id={"userRolesForm"} ref={userRolesFormRef} onSubmit={onUserRolesFormSubmit}
                      className={"role-form"}>
                    <input type={"hidden"} name={"id"} value={userState.id}/>
                    <div className={"form-group role-form-table"}>
                        <table style={{width: '100%'}}>
                            <thead>
                            <tr>
                                <th></th>
                                <th style={{textAlign: 'center'}}>#</th>
                                <th>Rolle</th>
                                <th>Beschreibung</th>
                                <th>Application</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                allRoles && allRoles.data.filter((item) => item.isSuperAdmin || item.permissionCount > 0).map((item, index) => {
                                        return (
                                                <ListRowCheck key={"role_item_" + index} item={item} index={index}
                                                              permission={permissions && ((permissions["web-for-user-management"] && permissions["web-for-user-management"].includes("saveUserRole")) || permissions.isAdmin)}
                                                              stateChanger={handleOnCheckChange}
                                                              isSelected={checkedIds[0] === item.id}
                                                flag={'role'}/>

                                        )
                                    }
                                )
                            }
                            </tbody>
                        </table>
                    </div>
                    <div className={"form-buttons-wrapper"}>
                        {permissions && ((permissions["web-for-user-management"] && permissions["web-for-user-management"].includes("saveUserRole")) || permissions.isAdmin) &&
                            <button className={"btn btn-primary form-buttons"}>Speichern</button>}
                        <button onClick={toggleModalRoles} className={"btn btn-danger form-buttons"}>Abbrechen</button>
                    </div>
                </Form>
            </Modal>
        </div> : <div>Sie dürfen diesen Inhalt nicht sehen</div>
}
