import {BsPencilSquare, BsTrashFill, BsLink45Deg} from "react-icons/bs";
import * as React from "react";
import {Link} from "react-router-dom";

export const ListRow = React.forwardRef(({item, onEdit, onEditRoles, onDelete}, ref) => {
    const permissions = JSON.parse(localStorage.getItem('user-permissions'));
    return (
        <tr ref={ref} className={"Table-Row someClass"} style={{height: '50px'}}>
            <td>{item.name}</td>
            <td>{item.department}</td>
            <td>{item.fullname}</td>
            <td className={"Last-Table-Cell"}>

                {permissions && ((permissions["web-for-user-management"] && permissions["web-for-user-management"].includes("getAllMembers")) || permissions.isAdmin) &&
                    <Link to={`/teams/${item.id}`}>
                    <BsLink45Deg
                        style={{
                            color: "gray",
                            background: "unset",
                            margin: "0 0 0 0px"
                        }}
                        className={"Icon-Decline"}/>
                    </Link>}

                {permissions && ((permissions["web-for-user-management"] && permissions["web-for-user-management"].includes("deleteTeam")) || permissions.isAdmin) &&
                    <button style={{border: "none", background: "unset", float: "right"}} data-itemid={item.id}
                        title={"Delete"}
                        onClick={onDelete}>
                    <BsTrashFill
                        style={{
                            color: "red",
                            background: "unset",
                            margin: "0 0 0 0px"
                        }}
                        className={"Icon-Decline"}/>
                    </button>}

                {permissions && ((permissions["web-for-user-management"] && permissions["web-for-user-management"].includes("updateTeam")) || permissions.isAdmin) &&
                    <button style={{border: "none", background: "unset", float: "right"}} data-itemid={item.id}
                        title={"Edit"}
                        onClick={onEdit}>
                    <BsPencilSquare
                        style={{
                            color: "gray",
                            background: "unset",
                            margin: "0 0 0 0px"
                        }}
                        className={"Icon-Decline"}/>
                    </button>}


            </td>
        </tr>
    );

});
