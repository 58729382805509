//@flow
import {Redirect, Route} from "react-router-dom";
import React from "react";
import {RouteProps, useLocation} from "react-router";
import {useSelector} from "react-redux";

export function AuthenticatedRoute({roles, ...props}: RouteProps) {

    // Changing from useAuth & React Context to Redux
    // const {state} = useAuth();
    const { loggedUser } = useSelector(state => state.auth);
    const location = useLocation();
    const token = new URLSearchParams(location.search).get('token');

    if (!token && !loggedUser.uid) return <Redirect to="/login"/>;
    return <Route {...props} />;
}
