import {useDispatch, useSelector} from "react-redux";
import useApi from "../../../hooks/useApi";
import {toggleLock} from "../../../services/API/damageBill.service";
import {getUser} from "../../../services/API/users.service";
import {useEffect} from "react";
import {updateEditAccess} from "../../../features/DamageBill/bill.slice";
import {Button} from "react-bootstrap";
import {MdLockOpen, MdLockOutline} from "react-icons/md";
import BillDocumentTranslations from "../../../translations/BillDocument";
import * as React from "react";
import {checkLoggedUser} from "../../../features/Authentication/authSlice";
import toast from 'react-hot-toast';

const EditAccess = () => {
    const dispatch = useDispatch();
    const {bill, lockedBy} = useSelector(state => state.damageBill)
    const uid = useSelector(state => state.auth.loggedUser.uid)
    const permissions = JSON.parse(localStorage.getItem('user-permissions'));

    const {data, request, error, canceled} = useApi(toggleLock, 'damagebills');
    const {data: user, request: requestUser} = useApi(getUser, 'users');

    useEffect(() => {
        if (canceled) toast.error('Request to service damagebills was canceled, time exceeded', 'damagebills');
    }, [canceled]);

    useEffect(() => {
        if (data) {
            dispatch(updateEditAccess({
                bill_unlocked_for_edit_by_user_id: data.bill_unlocked_for_edit_by_user_id, fetchedBy: uid, contract_check: data.contract_check}))
        }
    }, [data])

    useEffect(() => {
        if (lockedBy && lockedBy !== uid) {
            requestUser({id: lockedBy})
        }
    }, [lockedBy, uid, error])

    useEffect(()=>{
        {error && toast.error(error)}
    }, [data, error])

    if (lockedBy === null || error) {
        return <Button variant="outline-primary"
                       size={'sm'}
                       onClick={async () => {
                           await dispatch(checkLoggedUser());
                           request({id: bill?.damage_bill_id})
                       }}
        >
            <MdLockOutline
                style={{
                    cursor: 'pointer', width: "20.2px", height: "20.2px", objectFit: "contain",
                }}/>
            {BillDocumentTranslations.de.req_access}
        </Button>
    }

    if (lockedBy === uid) {
        return <Button variant="outline-primary"
                       size={'sm'}
                       onClick={async () => {
                           await dispatch(checkLoggedUser());
                           request({id: bill?.damage_bill_id})
                       }}
        >
            <MdLockOpen
                style={{
                    cursor: 'pointer', width: "20.2px", height: "20.2px", objectFit: "contain",
                }}/>
            {BillDocumentTranslations.de.rel_access}
        </Button>
    }


    return (permissions && ((permissions["web-for-user-management"] && permissions["web-for-user-management"].includes("getSingleUser")) || permissions.isAdmin) &&
        <div style={{display: 'flex'}}>
            <div className={"info-label"}>{BillDocumentTranslations.de.locked_by} {user?.first_name} {user?.last_name}</div>
        </div>
    )
}

export default EditAccess;