import * as React from "react";
import {useCallback, useEffect, useMemo, useState} from "react";
import Modal from "react-modal";
import {ListRow} from "./ListRow";
import useApi from "../../../hooks/useApi";
import InView from "../../InView";
import {
    createTeamMember, deleteTeamMember, getAllCountries,
    getTeamMembers,
    updateUserCountry
} from "../../../services/API/teams.service";
import {AddMemberForm, ChangeCountryForm} from "./UserForm";
import {useParams} from "react-router-dom";
import {useTablePaginator} from "../../../pages/Pay/useTablePaginator";
import toast, {Toaster} from "react-hot-toast";

const initState = {
    id: 0,
    username: '',
    password: '',
    first_name: '',
    last_name: '',
    title_id: 0,
    email: '',
    mobile: '',
    phone: '',
    address: '',
    zip_code_id: 0,
    language_id: 0,
    is_active: true,
    is_deleted: false
};

export function List() {
    let [userState, setUserState] = useState(initState);
    const {teamId} = useParams();
    const permissions = JSON.parse(localStorage.getItem('user-permissions'));

    const [isOpen, setIsOpen] = useState(false);
    const [isOpenAdd, setIsOpenAdd] = useState(false);
    const c = useCallback((...args) => {
        try {
            return createTeamMember(teamId, ...args);
        } catch (e) {
            console.log(e.message);
        }
    }, [teamId])
    const {data: addTeamData, request: addTeamRequest, error: addTeamError, canceled: addTeamCanceled} = useApi(c, 'users');
    const {data: deleteTeamData, request: deleteTeamRequest, error: deleteTeamError, canceled: deleteTeamCanceled} = useApi(deleteTeamMember, 'users');
    const {data: updateUserData, request: updateUserRequest, error: updateUserError, canceled: updateTeamCanceled} = useApi(updateUserCountry, 'users');

    useEffect(() => {
        if (addTeamCanceled || deleteTeamCanceled || updateTeamCanceled) toast.error('Request to service users was canceled, time exceeded', 'users');
    }, [addTeamCanceled, updateTeamCanceled, deleteTeamCanceled]);

    const {data: allCountries, request: getCountriesRequest} = useApi(getAllCountries, 'countries');

    const countries = useMemo(() => {
        const data = {}


        if(allCountries) {
            (allCountries?._embedded?.records || []).forEach(r => {
                data[r.country_id] = r.country_name
            })
        }



        return data ;

    }, [allCountries])

    const t = useCallback((...args) => {
        try {
            return getTeamMembers(teamId, ...args);
        } catch (e) {
            console.log(e.message);
        }
    }, [teamId]);
    const [, hasError, allTeams, hasMore, , refresh, nextPage] = useTablePaginator(t, 'users');


    const requestNextPage = useCallback(() => {
        if (hasMore && !hasError) {
            nextPage()
        }
    }, [hasMore, hasError, nextPage]);

    useEffect(() => {
        getCountriesRequest()
    }, [])


    useEffect(() => {
        if (addTeamData && addTeamData.code === 200) {
            refresh();
        } else if (addTeamError) {
            console.log(addTeamError)
            if(addTeamError?.code>=500 && addTeamError?.code<=599) toast.error('Teams service is currently unavailable')
        }
    }, [addTeamData, addTeamError, refresh]);

    useEffect(() => {
        if (deleteTeamData && deleteTeamData.code === 200) {
            refresh();
        } else if (deleteTeamError) {
            console.log(deleteTeamError)
        }
    }, [deleteTeamData, deleteTeamError, refresh]);

    useEffect(() => {
        if (updateUserData !== null && updateUserData.code === 200) {
            refresh();
        } else if (updateUserError) {
            alert(updateUserError);
        }
    }, [updateUserData, updateUserError, refresh]);


    const toggleModal = useCallback(() => {
        if (!isOpen) {
            setUserState({...initState});
        }
        setIsOpen(isOpen => !isOpen);
    }, [isOpen]);

    const toggleModalAdd = useCallback(() => {
        if (!isOpenAdd) {
            setUserState({...initState});
        }
        setIsOpenAdd(isOpen => !isOpen);
    }, [isOpenAdd]);


    const onTeamFormSubmit = useCallback((data) => {
        const team = {
            user_id: userState.user_id,
            country_id: data.get('country_id'),
        }
        if (data.get('id') > 0) {
            updateUserRequest(team);
            toggleModal();
        }
    }, [updateUserRequest, toggleModal, userState.user_id]);


    const onAddFormSubmit = useCallback((data) => {
        const team = {
            user_id: data.get('member_id'),
        }

        addTeamRequest({team});
        toggleModalAdd();

    }, [addTeamRequest, toggleModalAdd]);


    const loadForEdit = useCallback((item) => {
        setUserState({...item});
        setIsOpen(true);
    }, []);


    const deleteTeamAction = useCallback((item) => {
        deleteTeamRequest({id: item.team_id, member_id: item.user_id});
    }, [deleteTeamRequest]);

    return permissions && ((permissions["web-for-user-management"] && permissions["web-for-user-management"].includes("getAllMembers")) || permissions.isAdmin) ?
        <div className={"list-container"}>
        <div className={"toolbox"}>
            {permissions && ((permissions["web-for-user-management"] && permissions["web-for-user-management"].includes("insertNewMember") && permissions["web-for-user-management"].includes("getAllExcludeTeamMembers")) || permissions.isAdmin) &&
                <button type="button" className={"Button-Add btn btn-primary"} onClick={toggleModalAdd}>Teammitglied hinzufügen</button>
            }
        </div>
        <div>
            <table>
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Username</th>
                    <th>Land</th>
                    <th>Aktionen</th>
                </tr>
                </thead>
                <tbody>

                {allTeams !== null && allTeams.map((item, index) => {
                    const i = {
                        ...item,
                        country_name: countries[item.user.working_country_id]
                    }
                    return (index !== Math.round(allTeams.length - 1)) ? <ListRow key={"user_item_" + i.id} item={i}
                                                                                  onEdit={loadForEdit.bind(this, i)}
                                                                                  onDelete={deleteTeamAction.bind(this, i)}
                    /> : <InView as={ListRow} key={"user_item_" + i.id} item={i}
                                 onEdit={loadForEdit.bind(this, i)}
                                 onDelete={deleteTeamAction.bind(this, i)}
                                 onceOnIntersect={requestNextPage}
                    />

                })

                }


                </tbody>
            </table>
        </div>
        <Modal isOpen={isOpenAdd}
               onRequestClose={toggleModalAdd}
               overlayClassName={"modal-overlay"}
               className={"modal-wide"}
               appElement={document.getElementsByTagName('body')}
               contentLabel="New team">
            <AddMemberForm toggleModal={toggleModalAdd} formSubmit={onAddFormSubmit} fields={userState}/>
        </Modal>
        <Toaster/>
        <Modal isOpen={isOpen}
               onRequestClose={toggleModal}
               overlayClassName={"modal-overlay"}
               className={"modal-wide"}
               appElement={document.getElementsByTagName('body')}
               contentLabel="New team">
            <ChangeCountryForm toggleModal={toggleModal} formSubmit={onTeamFormSubmit} fields={userState}/>
        </Modal>
    </div> : <div>Sie dürfen diesen Inhalt nicht sehen</div>;
}
