import * as React from 'react';
import {useEffect} from 'react';
import {BillForm} from "./form/BillForm";
import {useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {fetchDamageBill} from "../../features/DamageBill/bill.actions";

export function DamageBill() {
    const dispatch = useDispatch();
    const permissions = JSON.parse(localStorage.getItem('user-permissions'));
    const {billId: billIdParam} = useParams();

    useEffect(() => {
       permissions && ((permissions["web-for-damage-bill-management"] && permissions["web-for-damage-bill-management"].includes("getSingleDamagebill")) || permissions.isAdmin) &&
       dispatch(fetchDamageBill(billIdParam));
    }, [dispatch, billIdParam])


    return <BillForm/>;
}
