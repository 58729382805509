import {AxiosResponse} from "axios";
import {permissionsUrl} from "../ApiService";
import {client, headers} from "../../app/axiosClient";

export function getPermissions(queryString: string, pageNumber: number, cancelToken?: any): Promise<AxiosResponse> {
    return client.get(`${permissionsUrl}${queryString || ''}`, {
        headers: {
            ...headers
        }, params: {
            page: pageNumber
        },
        cancelToken
    });
}


export function addPermission(permission, cancelTokenParam?: any): Promise<AxiosResponse> {
    return client.post(`${permissionsUrl}`, permission, {
        headers: {
            ...headers
        },
        cancelToken: cancelTokenParam
    });
}

export function updatePermission(permission, id, cancelTokenParam?: any): Promise<AxiosResponse> {
    return client.put(`${permissionsUrl}/${id}`, permission, {
        headers: {
            ...headers
        },
        cancelToken: cancelTokenParam
    });
}

export function getUserPermissions(requestToken: string, cancelToken?: any): Promise<AxiosResponse> {
    return client.get(`${permissionsUrl}/user-permissions`, {
        headers: {
            ...headers,
            "Authorization": 'Bearer ' + requestToken
        },
        cancelToken
    })
}
