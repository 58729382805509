import {useSelector} from "react-redux";
import {useMemo, useEffect} from "react";
import {Button} from "react-bootstrap";
import {BsLink45Deg} from "react-icons/bs";
import * as React from "react";
import {getGivitLink} from "../../../services/API/damageBill.service";
import useApi from "../../../hooks/useApi";

function GivitDamageButton() {
    const {bill} = useSelector(state => state.damageBill);
    const damage = useSelector(state => state.damageBill.damage)
    const {data: responseData, request: requestGivitLink} = useApi(getGivitLink, 'damagebills');

    useEffect(() => {
        if (!!damage) {
            requestGivitLink({id: bill?.damage_bill_id});
        }
    }, [damage, bill?.damage_bill_id, requestGivitLink]);

    const link = useMemo(() => {
        if (!damage) return '';
        if (responseData?.givitLink && responseData?.code === 200) {
            return responseData.givitLink;
        } else return '';
    }, [damage?.damage_number, responseData, bill?.damage_bill_id]);

    if (!link) return null;

    return <Button target={'_blank'} variant="outline-primary" size={'sm'} href={link}>
        <BsLink45Deg style={{width: "20.2px", height: "20.2px", objectFit: "contain"}}/>
        GIVIT
    </Button>

}

export default GivitDamageButton;
