import {OverlayTrigger, Tooltip} from "react-bootstrap";
import dateFormat from "dateformat";
import logInfoImg from "../../assets/images/logInfoIcon.svg";
import React, {useEffect, useState} from "react";
import useApi from "../../hooks/useApi";
import {getCarDealerLogInfo} from "../../services/API/carDealer.service";
import {useDispatch, useSelector} from "react-redux";
import {
    toggleCarDealerForEmails, toggleSelectAllButtonCarDealers,
    updateArrayUncheckCarDealers
} from "../../features/SearchPagination/searchPagination.slice";

export function CarDealersRow ({item, handleRowClick }) {
    const {carDealersIdsForEmails, all_selected_car_dealers,carDealers_uncheck, email_changed} = useSelector(state => state.searchPagination);
    const dispatch = useDispatch();
    const {data: logInfo, request: getLogInfo} = useApi(getCarDealerLogInfo, 'cardealers');
    const [showTooltip, setShowTooltip] = useState(false);
    const [message, setMessage]= useState('');
    const [dataArray, setDataArray]= useState([]);
    const permissions = JSON.parse(localStorage.getItem('user-permissions'));
    const handleClickedCheckbox = (id) => {
        dispatch(toggleCarDealerForEmails(id));
        if(all_selected_car_dealers===true){
            dispatch(toggleSelectAllButtonCarDealers(false))
        }
    }
    const handleChecked = (checked) => {
        let updatedArray = [...carDealers_uncheck];
        if (all_selected_car_dealers === true && checked === false) {
            if (!updatedArray.includes(item.id)) {
                updatedArray.push(item.id);
            }
        }
        if(all_selected_car_dealers === true && checked === true){
            if (updatedArray.includes(item.id)) {
                updatedArray = updatedArray.filter(itemF => itemF !== item.id);
            }
        }
        dispatch(updateArrayUncheckCarDealers(updatedArray));
    }
    useEffect(() => {
            logInfo?.data?.map(() => {
                const dataNew = Object.entries(logInfo?.data)
                setDataArray(dataNew);
            })

    }, [logInfo, getLogInfo, email_changed]);

    const hasPermissionToChangeMail = permissions && ((permissions["web-for-car-dealer-management"] && permissions["web-for-car-dealer-management"].includes("updateCarDealerEmail")) || permissions.isAdmin);


    return (<tr onClick={hasPermissionToChangeMail ? ()=> {handleRowClick()} : null} style={{cursor: hasPermissionToChangeMail ? 'pointer' : 'default'}}>
        {permissions && ((permissions["web-for-car-dealer-management"] && permissions["web-for-car-dealer-management"].includes("updateCarDealerEmail")) || permissions.isAdmin)
            && <td style={{width: '8%'}} onClick={(e)=> e.stopPropagation()}>
            <input type={'checkbox'}
                   className={'ml-3'}
                   checked={
                       all_selected_car_dealers
                           ? (carDealersIdsForEmails[item.id] === undefined ? true : carDealersIdsForEmails[item.id])
                           : carDealersIdsForEmails[item.id] || false
                   }
                   onChange={(e) => {handleClickedCheckbox(item.id);handleChecked(e.target.checked) }} style={{cursor: 'pointer'}}/>
        </td>}
        <td style={{width: '8%'}}>{item.hdnr}</td>
        <td style={{width: '7%'}}>{item.filial}</td>
        <td style={{width: '38%'}}>{`${item.name} ${item.name2}`}</td>
        <td style={{width: '10%'}}>{item.zip_code_and_place_name}</td>
        <td style={{width: '28%'}}>{item.default_email_address}</td>
        {permissions && ((permissions["web-for-car-dealer-management"] && permissions["web-for-car-dealer-management"].includes("getCarDealerLogs")) || permissions.isAdmin)
            && <td style={{width: '5%'}}> <div><OverlayTrigger
            delay={{hide: 200, show: 100}}
            show={showTooltip}
            trigger={['hover', 'focus']}
            overlay={(props) => (
                <Tooltip {...props} onMouseEnter={() => {setShowTooltip(true);}}
                         onMouseLeave={() => setShowTooltip(false)} className={'logInfo logSecond'}>
                    <table>
                        <thead>
                        <tr>
                            <th>Datum der Änderung</th>
                            <th>User, der die Änderung durchgeführt hat</th>
                            <th>Empfänger ID</th>
                            <th>Filial</th>
                            <th>Neuer Wert</th>
                            <th>Alte(r) Wert(e)</th>
                        </tr>
                        </thead>
                        <tbody>{dataArray?.length!==0 ? dataArray.map((log) => {
                           return <tr key={log[1][0]?.id}>
                                <td style={{minWidth: '150px'}}>{dateFormat(log[1][0]?.date_created?.date, "dd.mm.yyyy HH:MM:ss")}</td>
                                <td>{`${log[1][0]?.user_first_name} ${log[1][0]?.user_last_name}`}</td>
                                <td>{`${log[1][0]?.log_data?.hdnr}`}</td>
                                <td>{`${log[1]?.filial}`}</td>
                                <td>{`${log[1][0]?.log_data?.email_new}`}</td>
                                <td>{`${!!log[1][0]?.log_data?.email_old ? log[1][0]?.log_data?.email_old : ''}`}</td>
                           </tr>
                        }) : message}</tbody>
                    </table>
                </Tooltip>
            )}
            placement="auto"
            defaultShow={false}>
            <img src={logInfoImg} alt={'Log info'} width={25} height={25}
                 onMouseEnter={() => {setShowTooltip(true);   getLogInfo({id: item.id}); (!logInfo?.data || dataArray===[]) && setMessage('Keine eregbnisse')}}
                 onMouseLeave={() => setShowTooltip(false)}/>
        </OverlayTrigger></div></td>}

    </tr>)
}