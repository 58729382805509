import {LoadingSpinner} from "../../general/LoadingSpinner";
import BillListItem from "./ListItem";
import {useDispatch, useSelector} from "react-redux";
import { nextPage} from "../../../features/SearchPagination/searchPagination.slice";
import InView from "../../InView";

function BillsListItems({setShowAdvancedSearch, chooseClosing}) {
    const {bill} = useSelector(state => state.damageBill);
    const dispatch = useDispatch();
    const {loading, hasMore, hasError, billsPaginated} = useSelector(state => state.searchPagination);

    const hideAdvancedSearch = () => {
        setShowAdvancedSearch(false);
    }

    const requestNextPage = () => {
        if (hasMore && !hasError) {
            dispatch(nextPage())
        }
    }

    return (
        <ul style={{position: 'relative'}} className={"sidebarList"}>
            {
                billsPaginated?.map((item) => (
                    <li key={item.id}
                        style={{flexWrap: 'nowrap'}}
                        className={`row ${bill?.damage_bill_id === item.damage_bill_id ? "active" : ""}`}
                    >
                        <BillListItem item={item} hideAdvancedSearch={hideAdvancedSearch} chooseClosing={chooseClosing}/>
                    </li>
                ))
            }

            {!loading && billsPaginated?.length === 0 && <p>
                Keine eregbnisse
            </p>}
            {loading && <div style={{width: '100%', display: 'flex', justifyContent: 'center', padding: '1rem'}}>
                <LoadingSpinner isSpinning={loading}/>
            </div>}
            {!loading &&  hasMore && <InView as={'div'}
                                 style={{width: '100%', display: 'flex', justifyContent: 'center', padding: '1rem'}}
                                 onceOnIntersect={requestNextPage}>
                <LoadingSpinner isSpinning={true}/>
            </InView>}
        </ul>
    )
}

export default BillsListItems;
