import React, {useEffect, useRef} from "react";
import Modal from "react-bootstrap/Modal";
import './error_message.css';
import {Button} from "react-bootstrap"
import {markBillAuthorizedForPayment, updateBillForPayment} from "../../../features/DamageBill/bill.actions";
import {useDispatch, useSelector} from "react-redux";
import {sendLog} from "../../../services/API/damageBill.service";


export function SelectionModalContract({show, setShow, message, selectionName, formRef, setRefreshLogsIndicator, setUserNotifiedContract}) {
    const {
        bill,
        is_damage_closed_by_this_bill
    } = useSelector(state => state.damageBill);
    const dispatch = useDispatch();
    const onConfirmAuthorize = () => {
        if (selectionName === 'authorize') {
            dispatch(
                markBillAuthorizedForPayment(
                    {
                        billId: bill.damage_bill_id,
                        isMarked: is_damage_closed_by_this_bill,
                        user_notified_contract: 1
                    }
                )
            );
            setShow(false);
            setUserNotifiedContract(true);
        }
        if (selectionName === 'prepareForPaying') {
            if (formRef && formRef.current) {
                dispatch(
                    updateBillForPayment(
                        {
                            billId: bill.damage_bill_id,
                            data: (() => {
                                const data = new FormData(formRef.current);
                                const l = {}
                                for (const pair of data.entries()) {
                                    l[pair[0]] = pair[1];
                                }
                                return l;
                            })(),
                            user_notified_contract: 1
                        }
                    )
                );
            }
            setShow(false);
            setUserNotifiedContract(true);
        }
    }

    const onBack = async () => {
        await sendLog(bill.damage_bill_id);
        setRefreshLogsIndicator(prevState => prevState +1);
        setShow(false);

    };
    const buttonRef = useRef(null);

    useEffect(() => {
        if(show && buttonRef) {
            if (buttonRef.current) {
                buttonRef.current.focus();
            }
        }
    }, [show, buttonRef]);

    return (
        <Modal className={''} show={show} onHide={() => setShow(false)} scrollable>
            <Modal.Header closeButton className={'background'}>
                <Modal.Title className={'modal-title'}>Fehler!</Modal.Title>
            </Modal.Header>
            <Modal.Body className={'background text-center'}>
                <div className={'d-flex flex-column align-items-center px-3 pt-2'}>

                    <p>{message}</p>
                </div>
            </Modal.Body>
                <Modal.Footer className={'background modal-title d-flex justify-content-between'}>
                    <Button variant={"success"}
                            onClick={() => onConfirmAuthorize()}>JA</Button>
                    <button  className={"btn btn-secondary"} ref={buttonRef} onClick={() => onBack()}>ZURÜCK</button>
                </Modal.Footer>
        </Modal>
    );
}
