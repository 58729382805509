import useApi from "./useApi";
import {getCountriesByLocale} from "../services/API/countries.service";
import {useEffect} from "react";

export const useCountries = () => {

    const d = useApi(getCountriesByLocale, 'countries')
    useEffect(() => {
        d.request({locale:'de'})
    },[])
    return d;
}
