import {Header} from "../components/general/Header";
import {List} from "../components/permissions/List";

export function PermissionsPage(props) {
    return(
        <div style={{background: '#e5ebf4'}}  className={"permissions-container"}>
            <Header title={"Berechtigung"}/>
            <List />
        </div>
    );
}