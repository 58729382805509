import {AxiosResponse} from "axios";
import {client, headers} from "../../app/axiosClient";
import {carDealersUrl, damageBillUrl, externalProvidersUrl} from "../ApiService";
import {CarDealersSearchParams} from "../../features/SearchPagination/searchPagination.slice";

export function getCarDealerLogInfo(id: string, cancelToken?: any): Promise<AxiosResponse> {
    return client.get(`${carDealersUrl}/logs/${id}`, {
        headers: {
            ...headers,
        },
        cancelToken: cancelToken
    })
}
export function putUpdateCarDealersEmailAddress(ids: string, all_selected: boolean, default_email_address, searchParams: CarDealersSearchParams ,cancelToken?: any): Promise<AxiosResponse> {
    const d = new FormData();
    d.append('ids', ids);
    all_selected===true && d.append('all_selected', all_selected);
    d.append('default_email_address', default_email_address);
    console.log('searchParams.full_name', searchParams.full_name);


    return client.put(`${carDealersUrl}/emailupdate`, d, {
        headers: {
            ...headers,
        },
        params: {
            // page: pageNumber,
            'filters[0][property]': 'full_name',
            'filters[0][operator]': 'l',
            'filters[0][value]': (searchParams.full_name === undefined || searchParams.full_name === '') ? '' : searchParams.full_name,
            'filters[1][property]': 'hdnr',
            'filters[1][operator]': 'eq',
            'filters[1][value]': (searchParams.hdnr === undefined || searchParams.hdnr === '') ? '' : searchParams.hdnr,
            'filters[2][property]': 'default_email_address',
            'filters[2][operator]':'eq',
            'filters[2][value]': (searchParams.default_email_address === undefined || searchParams.default_email_address==='' ) ? '': searchParams.default_email_address,
            'zip': searchParams.zip,
            'return_type': 'zipCodeIds',
            'filters[3][operator]': 'eq',
            'filters[3][property]': 'filial',
            'filters[3][value]': (searchParams.filial===undefined || searchParams.filial==='' ) ? '' : searchParams.filial ,
        },

        cancelToken
    });
}

export function searchCarDealersNew({ pageNumberCarDealers, hdnr, default_email_address, zip, full_name, filial }: {
    pageNumberCarDealers: number,
    hdnr: number,
    default_email_address: string,
    zip: string,
    full_name: string,
    filial: string,
}, cancelToken?: any): Promise<AxiosResponse> {
    return client.get(carDealersUrl, {
        headers: {
            ...headers
        },
        params: {
            page: pageNumberCarDealers,
            'filters[0][operator]': 'l',
            'filters[0][property]': 'full_name',
            'filters[0][value]': full_name,
            'filters[1][operator]': 'eq',
            'filters[1][property]': 'hdnr',
            'filters[1][value]': hdnr,
            'filters[2][operator]':'eq',
            'filters[2][property]': 'default_email_address',
            'filters[2][value]': default_email_address,
            'zip': zip,
            'return_type': 'zipCodeIds',
            'filters[3][operator]': 'eq',
            'filters[3][property]': 'filial',
            'filters[3][value]': filial ? filial : '',
        },
        cancelToken: cancelToken
    })
}

/**
 * Gets a car dealer
 * @param carDealerId
 * @param cancelToken
 * @returns {Promise<AxiosResponse>}
 */
export function getCarDealer(carDealerId: number, cancelToken?: any): Promise<AxiosResponse> {
    return client.get(`${carDealersUrl}/${carDealerId}`, {
        headers: {
            ...headers
        },
        cancelToken
    })
}


/**
 * Gets a car dealer
 * @param extProviderId
 * @param cancelToken
 * @returns {Promise<AxiosResponse>}
 */
export function getExternalProvider(extProviderId: number, cancelToken?: any): Promise<AxiosResponse> {
    return client.get(`${externalProvidersUrl}/${extProviderId}`, {
        headers: {
            ...headers
        },
        cancelToken
    })
}

/**
 * Returns a list of car dealer that matched with the keyword
 * @param keyword
 * @param pageNumber
 * @param cancelToken
 * @returns {Promise<AxiosResponse>}
 */
export function searchCarDealers(keyword: string, pageNumber: number, cancelToken?: any): Promise<AxiosResponse> {
    return client.get(`${carDealersUrl}?filters[0][property]=name&filters[0][operator]=l&filters[0][value]=${keyword}`, {
        headers: {
            ...headers
        },
        params: {
            page: pageNumber
        },
        cancelToken
    })
}

/**
 * Returns a list of car dealer that matched with the keyword
 * @param keyword
 * @param pageNumber
 * @param cancelToken
 * @returns {Promise<AxiosResponse>}
 */
export function searchExternalProviders(keyword: string, pageNumber: number, cancelToken?: any): Promise<AxiosResponse> {
    return client.get(`${externalProvidersUrl}?filters[0][property]=name&filters[0][operator]=l&filters[0][value]=${keyword}`, {
        headers: {
            ...headers
        },
        params: {
            page: pageNumber,
        },
        cancelToken
    })
}

/**
 * Gets a list of bank account that are associated with a car dealer
 * @param carDealerId
 * @param cancelToken
 * @returns {Promise<AxiosResponse>}
 */
export function getCarDealerBankAccounts(carDealerId: number, cancelToken?: any): Promise<AxiosResponse> {
    return client.get(`${carDealersUrl}/${carDealerId}/bankaccounts`, {
        headers: {
            ...headers
        },
        cancelToken
    })
}

export function getCarDealerContacts(carDealerId: number, cancelToken?: any): Promise<AxiosResponse> {
    return client.get(`${carDealersUrl}/${carDealerId}/contacts`, {
        headers: {
            ...headers
        },
        cancelToken: cancelToken
    })
}


export function getContactReasons(cancelToken?: any): Promise<AxiosResponse> {
    return client.get(`${damageBillUrl}/contactreasons`, {
        headers: {
            ...headers
        },
        cancelToken: cancelToken
    })
}


/**
 * Gets a list of bank account that are associated with a external provider
 * @param extProviderId
 * @param cancelToken
 * @returns {Promise<AxiosResponse>}
 */
export function getExternalProviderAccounts(extProviderId: number, cancelToken?: any): Promise<AxiosResponse> {
    return client.get(`${externalProvidersUrl}/${extProviderId}/bankaccounts`, {
        headers: {
            ...headers
        },
        cancelToken
    })
}